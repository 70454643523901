import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import AdvancedSearchContentV2 from '../components/advancedSearch/AdvancedSearchContentV2';
import AdvancedSearchV2Header from '../components/advancedSearch/AdvancedSearchV2Header';
import { AdvancedSearchV2Provider } from '../components/advancedSearch/AdvancedSearchV2Context';

const Container = styled.div`
  .table-container {
    width: 100%;
    position: relative;

    .column {
      &.col0 {
        width: 16%;
      }
    }
  }
`;

export const parseParams = (location) => {
  const { search } = location;
  const args = search.replace('?', '');

  if (args.length === 0) {
    return {};
  }

  const params = {};
  const argsList = args.split('&');

  for (let i = 0; i < argsList.length; i += 1) {
    const [key, val] = argsList[i].split('=');
    params[key] = val.split(',').map((v) => v.replace('_', ' ').replace(/%20/gi, ' '));
  }

  return params;
};

export default function AdvancedSearch() {
  const location = useLocation();
  const params = parseParams(location);

  return (
    <Container>
      <Helmet>
        <title>Deal Feed - TV CRM</title>
      </Helmet>

      <AdvancedSearchV2Provider params={params}>
        <AdvancedSearchV2Header />
        <AdvancedSearchContentV2 />
      </AdvancedSearchV2Provider>
    </Container>
  );
}

// background: #fff;
//     height: unset;
//     margin: 0;
//     padding: 10px 20px;
//     height: auto;
//     padding: 12px;
//     padding: 10px 20px;
//     border-radius: unset;
//     border-bottom: none;
//     height: 72px;
//     display: flex;
//     align-items: center;

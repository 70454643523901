import React from 'react';

const PrevArrowIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 36 30" version="1.1">
      <g id="New-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="arrow-icons"
          transform="translate(-90.000000, -365.000000)"
          fill="#ED3C64"
          fillRule="nonzero"
        >
          <g id="arrow-icon" transform="translate(90.000000, 365.000000)">
            <path
              d="M22.03425,0.413603203 C21.435,-0.157154452 20.48475,-0.13465415 19.914,0.465353897 C19.3425,1.06536194 19.3665,2.01487468 19.96575,2.58638234 L31.47225,13.4997787 L1.5,13.4997787 C0.672,13.4997787 0,14.1717877 0,14.9997988 C0,15.8278099 0.672,16.4998189 1.5,16.4998189 L31.42425,16.4998189 L19.96575,27.4139653 C19.36575,27.985473 19.3425,28.9349857 19.914,29.5349938 C20.20875,29.8439979 20.604,30 21,30 C21.372,30 21.744,29.8627482 22.03425,29.5859944 L35.121,17.1208273 C35.688,16.5545697 36,15.8015596 36,14.9997988 C36,14.1980381 35.688,13.445028 35.09475,12.85327 L22.03425,0.413603203 Z"
              id="Path-Copy"
              transform="translate(18.000000, 15.000000) scale(-1, 1) translate(-18.000000, -15.000000) "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PrevArrowIcon;

export const VERT_MAP = {
  'Financial Technology': 'FT',
  FinTech: 'FT',
  'Marketing Technology': 'MT',
  MarTech: 'MT',
  Security: 'CS',
  Cybersecurity: 'CS',
  'Cloud Infrastructure': 'CI',
  Cloud: 'CI',
  'AI/ML': 'AI',
  'Real Estate': 'RE',
  'Insurance Technology': 'IT',
  Insurtech: 'IT',
};

export const STAGE_TO_TYPE_MAP = {
  'N/A': null,
  Seed: 1,
  'Series A': 2,
  'Series B': 2,
  'Series C': 2,
  'Series D': 2,
  'Series E': 2,
};

import { getAccessToken, logout } from './utils/Auth';
import captureError from './utils/sentry';
import { removeInvestorDuplicates } from './util';

export const BACKEND_API =
  process.env.REACT_APP_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://api.thomvest.com';

const GET_HEADERS = { Accept: 'application/json', 'x-robots-tag': 'noindex,nofollow' };
const DELETE_HEADERS = { Accept: 'application/json' };
const POST_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-robots-tag': 'noindex,nofollow',
};
const PUT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-robots-tag': 'noindex,nofollow',
};
const PATCH_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-robots-tag': 'noindex,nofollow',
};

export const COMPLETED = 'Completed';

const fetchTemplate = (url, headers, body = {}, method = 'GET') => {
  headers.authorization = getAccessToken();
  const params = method === 'GET' ? { headers } : { headers, method, body };

  return fetch(url, params)
    .then((res) => {
      if (res.status === 401) {
        logout();
        return;
      }
      if (res.status === 204) return;
      if (res.status !== 200 && !res.ok) {
        const error = new Error(
          JSON.stringify({
            status: res.status,
            body: res.statusText,
            ...res,
          }),
        );
        captureError(error);
        throw error;
      } else {
        return res.json().then(({ data }) => data);
      }
    })
    .catch((err) => {
      captureError(err);
      throw new Error(err);
    });
};

export const getUserLists = (userEmail) =>
  fetchTemplate(`${BACKEND_API}/user/lists/${userEmail}`, GET_HEADERS);

export const getAllLists = (userEmail) =>
  fetchTemplate(`${BACKEND_API}/list/all/${userEmail}`, GET_HEADERS);

export const getCompanySummary = (permalink) =>
  fetchTemplate(`${BACKEND_API}/companies/summary/${permalink}`, GET_HEADERS);

export const getInvestorSummary = (permalink) =>
  fetchTemplate(`${BACKEND_API}/investor/summary/${permalink}`, GET_HEADERS);

export const getInvestorPercentile = (permalink, investmentType) =>
  fetchTemplate(`${BACKEND_API}/investor/percentile/${permalink}/${investmentType}`, GET_HEADERS);

export const getTractionPercentile = (permalink, vertical) =>
  fetchTemplate(
    `${BACKEND_API}/company/traction_signals/percentile/${permalink}/${encodeURIComponent(
      vertical,
    )}`,
    GET_HEADERS,
  );

export const search = (query) => fetchTemplate(`${BACKEND_API}/search/${query}`, GET_HEADERS);

export const getFunding = (permalink) =>
  fetchTemplate(`${BACKEND_API}/cb/funding_rounds/${permalink}`, GET_HEADERS);

export const updateStatus = (email, company, newStatus) => {
  const url = `${BACKEND_API}/company/update_status`;
  const headers = POST_HEADERS;
  headers.authorization = getAccessToken();
  const body = JSON.stringify({ email, company, newStatus });
  const params = { headers, method: 'POST', body };
  return fetch(url, params)
    .then((res) => {
      if (res.status === 401) {
        logout();
        return;
      }
      return res.json();
    })
    .catch(captureError);
};

export const getStatusHistory = (permalink) =>
  fetchTemplate(`${BACKEND_API}/company/status_history/${permalink}`, GET_HEADERS);

export const getNews = (permalink) =>
  fetchTemplate(`${BACKEND_API}/news/${permalink}`, GET_HEADERS);

export const getCompanyVerticals = (permalink) =>
  fetchTemplate(`${BACKEND_API}/company/verticals/${permalink}`, GET_HEADERS);

export const getHarmonicVerticals = (permalink) =>
  fetchTemplate(`${BACKEND_API}/company/verticals/harmonic/${permalink}`, GET_HEADERS);

export const addTask = (
  userEmail,
  permalink,
  taskText,
  taskDueDate,
  responsibleEmail,
  flaggingId = null,
  companyName,
) => {
  return fetchTemplate(
    `${BACKEND_API}/tasks/add`,
    POST_HEADERS,
    JSON.stringify({
      userEmail,
      permalink,
      taskText,
      taskDueDate,
      responsibleEmail,
      flaggingId,
      companyName,
    }),
    'POST',
  );
};

export const updateTask = (feedId, text, responsibility, dueDate, status) =>
  fetchTemplate(
    `${BACKEND_API}/tasks/update`,
    POST_HEADERS,
    JSON.stringify({ feedId, text, responsibility, dueDate, status }),
    'POST',
  );

export const addNote = (email, permalink, richText) =>
  fetchTemplate(
    `${BACKEND_API}/add_note`,
    POST_HEADERS,
    JSON.stringify({ email, permalink, richText }),
    'POST',
  );

// params: { noteId, feedTime, header, richText }
export const updateNote = (params) =>
  fetchTemplate(`${BACKEND_API}/update_note`, POST_HEADERS, JSON.stringify(params), 'POST');

export const updateNoteFromFeed = (key, richText) =>
  fetchTemplate(
    `${BACKEND_API}/update_note/feed`,
    POST_HEADERS,
    JSON.stringify({ key, richText }),
    'POST',
  );

export const getNoteOwnerEmail = (noteId) =>
  fetchTemplate(`${BACKEND_API}/notes/owner/${noteId}`, GET_HEADERS);

export const deleteNote = ({ feedId, noteId }) =>
  fetchTemplate(
    `${BACKEND_API}/delete_note`,
    POST_HEADERS,
    JSON.stringify({ feedId, noteId }),
    'POST',
  );

export const getNotes = (permalink) =>
  fetchTemplate(`${BACKEND_API}/notes/${permalink}`, GET_HEADERS);

export const getCompanyScores = (permalink) =>
  fetchTemplate(`${BACKEND_API}/tv_scores/${permalink}`, GET_HEADERS);

export const getInvestorScore = (permalink) =>
  fetchTemplate(`${BACKEND_API}/tv_investor_score/${permalink}`, GET_HEADERS);

export const updateTVScore = (company, scoreKey, scoreValue) =>
  fetchTemplate(
    `${BACKEND_API}/tv_score`,
    POST_HEADERS,
    JSON.stringify({ company, scoreKey, scoreValue }),
    'POST',
  );

export const getCompanyLists = (permalink) =>
  fetchTemplate(`${BACKEND_API}/company_lists/${permalink}`, GET_HEADERS);

export const addNewList = (listName) =>
  fetchTemplate(`${BACKEND_API}/add_list`, POST_HEADERS, JSON.stringify({ listName }), 'POST');

export const addDocument = (company, name, url, document_type) =>
  fetchTemplate(
    `${BACKEND_API}/add_document`,
    POST_HEADERS,
    JSON.stringify({ company, name, url, document_type }),
    'POST',
  );

export const addCompanyToList = (company, listId, email) =>
  fetchTemplate(
    `${BACKEND_API}/list/add_company`,
    POST_HEADERS,
    JSON.stringify({ company, listId, email }),
    'POST',
  );

export const removeCompanyFromList = (company, listId, email) =>
  fetchTemplate(
    `${BACKEND_API}/list/remove_company`,
    POST_HEADERS,
    JSON.stringify({ company, listId, email }),
    'POST',
  );

export const getCompaniesInList = (listId) =>
  fetchTemplate(`${BACKEND_API}/list/companies/${listId}`, GET_HEADERS);

// export const getListSubscribers = (listId) =>
// 	fetchTemplate(`${BACKEND_API}/list/${listId}/subscribers`, GET_HEADERS)

export const getListName = (listId) =>
  fetchTemplate(`${BACKEND_API}/list/name/${listId}`, GET_HEADERS);

export const getList = (listId, email) =>
  fetchTemplate(`${BACKEND_API}/list/summary/${listId}/${email}`, GET_HEADERS);

export const getListStats = (listId) =>
  fetchTemplate(`${BACKEND_API}/list/stats/${listId}`, GET_HEADERS);

export const getProspectListStats = () =>
  fetchTemplate(`${BACKEND_API}/prospect_list/stats/`, GET_HEADERS);

export const uploadFile = (formData, permalink, userEmail) =>
  fetch(`${BACKEND_API}/company/upload_file/${permalink}/${userEmail}`, {
    method: 'POST',
    body: formData,
  });

export const deleteFile = (documentId) =>
  fetchTemplate(`${BACKEND_API}/company/delete_file/${documentId}`, GET_HEADERS);

export const addLink = (company, name, url, document_type, user_email) =>
  fetchTemplate(
    `${BACKEND_API}/company/add_link`,
    POST_HEADERS,
    JSON.stringify({ company, name, url, document_type, user_email }),
    'POST',
  );

export const getDocuments = (company) =>
  fetchTemplate(`${BACKEND_API}/company/documents/${company}`, GET_HEADERS);

export const subscribe = (email, listId) =>
  fetchTemplate(
    `${BACKEND_API}/list/subscribe/${listId}`,
    POST_HEADERS,
    JSON.stringify({ email }),
    'POST',
  );

export const unsubscribe = (email, listId) =>
  fetchTemplate(
    `${BACKEND_API}/list/unsubscribe/${listId}`,
    POST_HEADERS,
    JSON.stringify({ email }),
    'POST',
  );

export const getUsers = () => fetchTemplate(`${BACKEND_API}/users`, GET_HEADERS);

export const getTVProspectInfo = (company) =>
  fetchTemplate(`${BACKEND_API}/company/tv_prospect_info/${company}`, GET_HEADERS);

export const updateTVProspectInfo = (company, field, value) =>
  fetchTemplate(
    `${BACKEND_API}/company/update_tv_prospect_info/`,
    POST_HEADERS,
    JSON.stringify({ company, field, value }),
    'POST',
  );

export const getStatuses = () => fetchTemplate(`${BACKEND_API}/statuses`, GET_HEADERS);

export const addTVCustomCompany = (
  company_name,
  homepage_url,
  short_description,
  last_investment_type,
  location,
  founders_name,
  tv_vertical,
) =>
  fetchTemplate(
    `${BACKEND_API}/tv_custom_company/add`,
    POST_HEADERS,
    JSON.stringify({
      company_name,
      homepage_url,
      short_description,
      last_investment_type,
      location,
      founders_name,
      tv_vertical,
    }),
    'POST',
  );

export const getRegionFilters = async (country) => {
  try {
    const response = await fetchTemplate(`${BACKEND_API}/locations/region/${country}`, GET_HEADERS);
    const regions = response.map((region) => region.region);
    return regions;
  } catch (err) {
    return [];
  }
};

export const getCityFilters = async (country, region) => {
  try {
    const response = await fetchTemplate(
      `${BACKEND_API}/locations/city/${region}/${country}`,
      GET_HEADERS,
    );
    const cities = response.map((city) => city.city);
    return cities;
  } catch (err) {
    return [];
  }
};

export const getCompanyFeed = (company) =>
  fetchTemplate(`${BACKEND_API}/company/feed/${company}`, GET_HEADERS);

export const getListFeed = (listId) =>
  fetchTemplate(`${BACKEND_API}/list/feed/${listId}`, GET_HEADERS);

export const getUserFeed = (userEmail) =>
  fetchTemplate(`${BACKEND_API}/user/feed/${userEmail}`, GET_HEADERS);

export const getInvestorInvestments = (permalink) =>
  fetchTemplate(`${BACKEND_API}/investor/investments/${permalink}`, GET_HEADERS);

export const getInvestorTopVerticals = (permalink) =>
  fetchTemplate(`${BACKEND_API}/investor/top_verticals/${permalink}`, GET_HEADERS);

export const getInvestorTier = (permalink) =>
  fetchTemplate(`${BACKEND_API}/investor/tier/${permalink}`, GET_HEADERS);

export const updateInvestorTier = (permalink, tier) =>
  fetchTemplate(
    `${BACKEND_API}/investor/update_tier`,
    POST_HEADERS,
    JSON.stringify({ permalink, tier }),
    'POST',
  );

export const getInvestorRelationshipScore = (permalink) =>
  fetchTemplate(`${BACKEND_API}/investor/relationship/${permalink}`, GET_HEADERS);

export const updateInvestorRelationshipScore = (permalink, score) =>
  fetchTemplate(
    `${BACKEND_API}/investor/update_relationship`,
    POST_HEADERS,
    JSON.stringify({ permalink, score }),
    'POST',
  );

export const getInvestorUserRelationships = (permalink) =>
  fetchTemplate(`${BACKEND_API}/investor/user_relationships/${permalink}`, GET_HEADERS);

export const addInvestorUserRelationship = (permalink, user_code) =>
  fetchTemplate(
    `${BACKEND_API}/investor/add_user_relationships`,
    POST_HEADERS,
    JSON.stringify({ permalink, user_code }),
    'POST',
  );

export const removeInvestorUserRelationship = (permalink, user_code) =>
  fetchTemplate(
    `${BACKEND_API}/investor/remove_user_relationships`,
    POST_HEADERS,
    JSON.stringify({ permalink, user_code }),
    'POST',
  );

export const getInvestorVerticalExpertise = (permalink) =>
  fetchTemplate(`${BACKEND_API}/investor/vertical_expertise/${permalink}`, GET_HEADERS);

export const addInvestorVerticalExpertise = (permalink, vertical) =>
  fetchTemplate(
    `${BACKEND_API}/investor/add_vertical_expertise`,
    POST_HEADERS,
    JSON.stringify({ permalink, vertical }),
    'POST',
  );

export const removeInvestorVerticalExpertise = (permalink, vertical) =>
  fetchTemplate(
    `${BACKEND_API}/investor/remove_vertical_expertise`,
    POST_HEADERS,
    JSON.stringify({ permalink, vertical }),
    'POST',
  );

export const getVerticals = () => fetchTemplate(`${BACKEND_API}/verticals`, GET_HEADERS);

export const updateVerticals = () =>
  fetchTemplate(`${BACKEND_API}/verticals/update`, POST_HEADERS, JSON.stringify({}), 'POST');

export const addHarmonicVerticals = () =>
  fetchTemplate(`${BACKEND_API}/verticals/harmonic/add`, POST_HEADERS, JSON.stringify({}), 'POST');

export const updateHarmonicVerticals = () =>
  fetchTemplate(
    `${BACKEND_API}/verticals/harmonic/update`,
    POST_HEADERS,
    JSON.stringify({}),
    'POST',
  );

export const addCompanyVertical = (permalink, vertical) =>
  fetchTemplate(
    `${BACKEND_API}/company/add_vertical`,
    POST_HEADERS,
    JSON.stringify({ permalink, vertical }),
    'POST',
  );

export const removeCompanyVertical = (permalink, vertical) =>
  fetchTemplate(
    `${BACKEND_API}/company/remove_vertical`,
    POST_HEADERS,
    JSON.stringify({ permalink, vertical }),
    'POST',
  );

export const getUserTasks = (userEmail) => {
  return fetchTemplate(`${BACKEND_API}/user/tasks/${userEmail}`, GET_HEADERS);
};

export const patchCompany = (permalink, body) =>
  fetchTemplate(
    `${BACKEND_API}/company/${permalink}`,
    PATCH_HEADERS,
    JSON.stringify(body),
    'PATCH',
  );

export const getStageTypeScores = () =>
  fetchTemplate(`${BACKEND_API}/prospects/stage_type_scores`, GET_HEADERS);

export const createNewProspect = (body) =>
  fetchTemplate(`${BACKEND_API}/prospects/new`, POST_HEADERS, JSON.stringify(body), 'POST');

export const getActiveProspects = (permalink) =>
  fetchTemplate(`${BACKEND_API}/prospects/active/${permalink}`, GET_HEADERS);

export const getCompanyProspects = (permalink) =>
  fetchTemplate(`${BACKEND_API}/prospects/company/${permalink}`, GET_HEADERS);

export const patchProspect = (prospectId, body) =>
  fetchTemplate(
    `${BACKEND_API}/prospects/${prospectId}`,
    PATCH_HEADERS,
    JSON.stringify(body),
    'PATCH',
  );

// PROSPECT SCORES V1
export const createNewProspectScores = (body) =>
  fetchTemplate(`${BACKEND_API}/prospect/scores/new`, POST_HEADERS, JSON.stringify(body), 'POST');

export const deleteProspectScores = (prospectId) =>
  fetchTemplate(
    `${BACKEND_API}/prospect/scores/delete/${prospectId}`,
    POST_HEADERS,
    JSON.stringify({ prospectId }),
    'POST',
  );

export const getProspectScores = (prospectId) =>
  fetchTemplate(`${BACKEND_API}/prospect/scores/${prospectId}`, GET_HEADERS);

export const patchProspectScore = (prospectScoreId, body) =>
  fetchTemplate(
    `${BACKEND_API}/prospect/scores/${prospectScoreId}`,
    PATCH_HEADERS,
    JSON.stringify(body),
    'PATCH',
  );

// PROSPECT SCORES V2
export const createNewProspectScoresV2 = (body) =>
  fetchTemplate(
    `${BACKEND_API}/prospect/scores/v2/new`,
    POST_HEADERS,
    JSON.stringify(body),
    'POST',
  );

export const getProspectScoresV2 = (prospectId) =>
  fetchTemplate(`${BACKEND_API}/prospect/scores/v2/${prospectId}`, GET_HEADERS);

export const patchProspectScoreV2 = (prospectId, body) =>
  fetchTemplate(
    `${BACKEND_API}/prospect/scores/v2/${prospectId}`,
    PATCH_HEADERS,
    JSON.stringify(body),
    'PATCH',
  );

export const deleteProspectScoresV2 = (prospectId) =>
  fetchTemplate(
    `${BACKEND_API}/prospect/scores/v2/delete/${prospectId}`,
    POST_HEADERS,
    JSON.stringify({ prospectId }),
    'POST',
  );

//
export const createNewFeedItem = (body) =>
  fetchTemplate(`${BACKEND_API}/feed_item/new`, POST_HEADERS, JSON.stringify(body), 'POST');

export const getProspects = () => fetchTemplate(`${BACKEND_API}/prospects/all`, GET_HEADERS);

export const getProspectData = (startDate, endDate) =>
  fetchTemplate(`${BACKEND_API}/prospects/data/${startDate}/${endDate}`, GET_HEADERS); // dates formatted as YYYY-MM-DD

export const getLinkedinHeadcount = (permalink) =>
  fetchTemplate(`${BACKEND_API}/linkedin/headcount/${permalink}`, GET_HEADERS);

export const getLinkedinEmployeeChanges = (permalink) =>
  fetchTemplate(`${BACKEND_API}/linkedin/employee_changes/${permalink}`, GET_HEADERS);

export const getTrackedCompanies = () =>
  fetchTemplate(`${BACKEND_API}/companies/tracked`, GET_HEADERS);

export const postFlagging = (body) =>
  fetchTemplate(`${BACKEND_API}/flaggings/new`, POST_HEADERS, JSON.stringify(body), 'POST');

export const getCompanyFlaggings = (permalink) =>
  fetchTemplate(`${BACKEND_API}/flaggings/company/${permalink}`, GET_HEADERS);

export const patchFlagging = (flaggingId, body) =>
  fetchTemplate(
    `${BACKEND_API}/flaggings/${flaggingId}`,
    PATCH_HEADERS,
    JSON.stringify(body),
    'PATCH',
  );

export const getFlaggedCompanies = () => fetchTemplate(`${BACKEND_API}/flaggings/all`, GET_HEADERS);

export const getFlaggedFeed = () => fetchTemplate(`${BACKEND_API}/flaggings/feed/`, GET_HEADERS);

export const getFlaggingTasks = (flaggingId) =>
  fetchTemplate(`${BACKEND_API}/flaggings/tasks/${flaggingId}`, GET_HEADERS);

export const patchTask = (taskId, body) =>
  fetchTemplate(`${BACKEND_API}/tasks/${taskId}`, PATCH_HEADERS, JSON.stringify(body), 'PATCH');

export const getTask = (taskId) => fetchTemplate(`${BACKEND_API}/tasks/${taskId}`, GET_HEADERS);

export const getFlagging = (flaggingId) =>
  fetchTemplate(`${BACKEND_API}/flagging/${flaggingId}`, GET_HEADERS);

export const getEngagedCompanies = () =>
  fetchTemplate(`${BACKEND_API}/engaged/companies`, GET_HEADERS);

export const getEngagedCompaniesV2 = (status = null) =>
  fetchTemplate(`${BACKEND_API}/v2/engaged/companies?status=${status}`, GET_HEADERS);

export const getEngagedFeed = () => fetchTemplate(`${BACKEND_API}/engaged/feed`, GET_HEADERS);

export const getAffinity = (permalink) =>
  fetchTemplate(`${BACKEND_API}/affinity/${permalink}`, GET_HEADERS);

export const patchAffinity = (permalink, body) =>
  fetchTemplate(
    `${BACKEND_API}/affinity/${permalink}`,
    PATCH_HEADERS,
    JSON.stringify(body),
    'PATCH',
  );

export const deleteFlagging = (flagging_id) =>
  fetchTemplate(
    `${BACKEND_API}/flaggings/delete`,
    POST_HEADERS,
    JSON.stringify({ flagging_id }),
    'POST',
  );

export const investorInvestorSearch = (searchFilters) =>
  fetchTemplate(
    `${BACKEND_API}/investor_search`,
    POST_HEADERS,
    JSON.stringify(searchFilters),
    'POST',
  );

export const investorCompanySearch = (searchFilters) => {
  if (searchFilters.company.country) delete searchFilters.company.country;
  if (searchFilters.company.region) delete searchFilters.company.region;
  if (searchFilters.company.city) delete searchFilters.company.city;

  return fetchTemplate(
    `${BACKEND_API}/investor_company_search`,
    POST_HEADERS,
    JSON.stringify(searchFilters),
    'POST',
  );
};

export const getDealFeedCompanies = (queryParams) =>
  fetchTemplate(
    `${BACKEND_API}/recently_funded_companies`,
    POST_HEADERS,
    JSON.stringify(queryParams),
    'POST',
  );

export const addUserWatchlist = (userId, permalink) =>
  fetchTemplate(
    `${BACKEND_API}/user_watchlists/add`,
    POST_HEADERS,
    JSON.stringify({ userId, permalink }),
    'POST',
  );

export const removeUserWatchlist = (userId, permalink) =>
  fetchTemplate(
    `${BACKEND_API}/user_watchlists/remove`,
    POST_HEADERS,
    JSON.stringify({ userId, permalink }),
    'POST',
  );

export const getVerticalPriorityCompanies = () =>
  fetchTemplate(`${BACKEND_API}/vertical_priorities/companies`, GET_HEADERS);

export const getVerticalPriorityFeed = () =>
  fetchTemplate(`${BACKEND_API}/vertical_priorities/feed`, GET_HEADERS);

export const getUserWatchlistCompanies = (userId) =>
  fetchTemplate(`${BACKEND_API}/user_watchlists/companies/${userId}`, GET_HEADERS);

export const getUserWatchlistFeed = (userId) =>
  fetchTemplate(`${BACKEND_API}/user_watchlists/${userId}/feed`, GET_HEADERS);

export const getSheetsData = (spreadsheetId, ranges) =>
  fetchTemplate(
    `${BACKEND_API}/sheets`,
    POST_HEADERS,
    JSON.stringify({ spreadsheetId, ranges }),
    'POST',
  );

export const getStats = () => fetchTemplate(`${BACKEND_API}/stats`, GET_HEADERS);

export const getCompanyTractionSignals = (permalink) =>
  fetchTemplate(`${BACKEND_API}/company/traction_signals/${permalink}`, GET_HEADERS);

export const updateInvestorsFromGoogleSheet = () =>
  fetchTemplate(`${BACKEND_API}/investors/update`, POST_HEADERS, JSON.stringify({}), 'POST');

export const getCompanyInvestors = async (permalink) => {
  const data = await fetchTemplate(`${BACKEND_API}/company/investors/${permalink}`, GET_HEADERS);
  return removeInvestorDuplicates(data);
};

export const getCompanyHarmonicInvestors = (permalink) =>
  fetchTemplate(`${BACKEND_API}/company/harmonic_investors/${permalink}`, GET_HEADERS);

export const getSubverticals = () => fetchTemplate(`${BACKEND_API}/subverticals`, GET_HEADERS);

export const createSubvertical = (vertical_id, label) =>
  fetchTemplate(
    `${BACKEND_API}/subverticals`,
    POST_HEADERS,
    JSON.stringify({ vertical_id, label }),
    'POST',
  );

export const addSubverticalToCompany = (permalink, subvertical_id) =>
  fetchTemplate(
    `${BACKEND_API}/subverticals/add`,
    POST_HEADERS,
    JSON.stringify({ permalink, subvertical_id }),
    'POST',
  );

export const removeSubverticalFromCompany = (permalink, subvertical_id) =>
  fetchTemplate(
    `${BACKEND_API}/subverticals/remove`,
    POST_HEADERS,
    JSON.stringify({ permalink, subvertical_id }),
    'POST',
  );

export const getCompanySubverticals = (permalink) =>
  fetchTemplate(`${BACKEND_API}/subverticals/${permalink}`, GET_HEADERS);

export const getCompanySubverticalsGPT = (permalink) =>
  fetchTemplate(`${BACKEND_API}/subverticals/gpt/${permalink}`, GET_HEADERS);

// harmonic
export const getHeadcount = (crunchbaseUrl) =>
  fetchTemplate(
    `${BACKEND_API}/harmonic/headcount/${encodeURIComponent(crunchbaseUrl)}`,
    GET_HEADERS,
  );

export const getHighlights = (crunchbaseUrl) =>
  fetchTemplate(
    `${BACKEND_API}/harmonic/highlights/${encodeURIComponent(crunchbaseUrl)}`,
    GET_HEADERS,
  );

export const getSavedSearches = () =>
  fetchTemplate(`${BACKEND_API}/harmonic/saved_searches/`, GET_HEADERS);

export const getHarmonicFounders = (harmonicId) =>
  fetchTemplate(`${BACKEND_API}/harmonic/founders/${harmonicId}`, GET_HEADERS);

export const getDashboardFeed = (userId) =>
  fetchTemplate(`${BACKEND_API}/dashboard/feed/${userId}`, GET_HEADERS);

export const getDashboardFeedPaginated = ({ userId, page, limit }) =>
  fetchTemplate(
    `${BACKEND_API}/dashboard/feed/paginated/${userId}?page=${page}&limit=${limit}`,
    GET_HEADERS,
  );
// user memos
export const getUserMemos = (userId) =>
  fetchTemplate(`${BACKEND_API}/memos/${userId}`, GET_HEADERS);

export const putUserMemo = (userId, permalink, memo) =>
  fetchTemplate(
    `${BACKEND_API}/memos/${userId}/${permalink}`,
    PUT_HEADERS,
    JSON.stringify({ memo }),
    'PUT',
  );

export const deleteUserMemo = (userId, permalink) =>
  fetchTemplate(`${BACKEND_API}/memos/${userId}/${permalink}`, DELETE_HEADERS, {}, 'DELETE');

export const createChatCompletion = (prompt) =>
  fetchTemplate(`${BACKEND_API}/openai/chat`, POST_HEADERS, JSON.stringify({ prompt }), 'POST');

export const getCrunchbaseRankHistory = (permalink, days) =>
  fetchTemplate(`${BACKEND_API}/cb/rank_history/${permalink}?days=${days}`, GET_HEADERS);

export const getCrunchbaseRankPercentile = (permalink) =>
  fetchTemplate(`${BACKEND_API}/cb/rank_percentile/${permalink}`, GET_HEADERS);

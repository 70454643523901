import React from 'react';

const HelpIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 20" version="1.1">
      <g id="StyleGuide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons-&amp;-Buttons" transform="translate(-833.000000, -328.000000)">
          <g id="baseline-help-24px" transform="translate(832.000000, 327.000000)">
            <polygon id="Shape" points="0 0 22 0 22 22 0 22" />
            <path
              d="M11,1.83333333 C5.94,1.83333333 1.83333333,5.94 1.83333333,11 C1.83333333,16.06 5.94,20.1666667 11,20.1666667 C16.06,20.1666667 20.1666667,16.06 20.1666667,11 C20.1666667,5.94 16.06,1.83333333 11,1.83333333 Z M11.9166667,17.4166667 L10.0833333,17.4166667 L10.0833333,15.5833333 L11.9166667,15.5833333 L11.9166667,17.4166667 Z M13.8141667,10.3125 L12.9891667,11.1558333 C12.3291667,11.825 11.9166667,12.375 11.9166667,13.75 L10.0833333,13.75 L10.0833333,13.2916667 C10.0833333,12.2833333 10.4958333,11.3666667 11.1558333,10.6975 L12.2925,9.5425 C12.6316667,9.2125 12.8333333,8.75416667 12.8333333,8.25 C12.8333333,7.24166667 12.0083333,6.41666667 11,6.41666667 C9.99166667,6.41666667 9.16666667,7.24166667 9.16666667,8.25 L7.33333333,8.25 C7.33333333,6.22416667 8.97416667,4.58333333 11,4.58333333 C13.0258333,4.58333333 14.6666667,6.22416667 14.6666667,8.25 C14.6666667,9.05666667 14.3366667,9.79 13.8141667,10.3125 Z"
              id="Shape"
              fill="#FC4066"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HelpIcon;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo, useCallback, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import { getHarmonicFounders, patchCompany, patchProspect } from '../../BackendAPI';
import CompanyOwnership from './CompanyOwnership';
import { Flex, Button, Select, Input } from '../primitives';
import { getExternalURL, displayMoney } from '../../util';
import { STAGE_TO_TYPE_MAP } from '../../constants';
import { ProspectContext } from '../../contexts/ProspectContext';
import { CompanySocialIcons } from './CompanySocialIcons';
import OutreachTemplate from '../OutreachTemplate';

const stages = ['N/A', 'Seed', 'Series A', 'Series B', 'Series C', 'Series D', 'Series E'];

function getHostname(url) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname.replace(/^(www\.)?/, '');
  } catch (e) {
    return url; // or return a default value or handle the error as needed
  }
}

const EditCompanyModal = ({ company, showModal, closeModal }) => {
  const [prospectState] = useContext(ProspectContext);
  const { prospectId } = prospectState;
  const stageValue = prospectState.stage.options.find((l) => l.active)?.value;

  const permalink = company?.permalink;
  const [companyName, setCompanyName] = useState(company?.company_name ?? '');
  const [homepageUrl, setHomepageUrl] = useState(company?.homepage_url ?? '');
  const [shortDescription, setShortDescription] = useState(company?.short_description ?? '');
  const [stage, setStage] = useState(stageValue ?? company?.last_investment_type ?? '');
  const [location, setLocation] = useState(company?.location ?? '');
  const [foundersName, setFoundersName] = useState(company?.founders_name ?? '');
  const [country, setCountry] = useState(company?.country ?? '');
  const [region, setRegion] = useState(company?.region ?? '');
  const [city, setCity] = useState(company?.city ?? '');

  useEffect(() => {
    if (stage !== stageValue) {
      setStage(stageValue);
    }
  }, [stageValue]);

  const handleStageChange = useCallback((e) => {
    setStage(e.target.value);
    if (permalink && company?.last_investment_type !== e.target.value) {
      const stageTypeId = STAGE_TO_TYPE_MAP[e.target.value];
      if (stageTypeId) {
        patchProspect(prospectId, { stage_type_id: stageTypeId });
      }
      patchProspect(prospectId, { stage: e.target.value });
      patchCompany(permalink, { last_investment_type: e.target.value });
    }
  }, []);

  return (
    <Modal
      backdrop="static"
      show={showModal}
      onHide={closeModal}
      className="tv-modal tv-company-modal"
    >
      <Modal.Header>
        <Flex fill justify="space-between">
          <Modal.Title>Edit {company?.company_name}</Modal.Title>
          <button className="modal-close" type="button" onClick={closeModal}>
            <i className="fa fa-times" />
          </button>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-unstyled list-spaced">
          <li>
            <Input
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              onBlur={() => {
                if (permalink && companyName !== company?.company_name) {
                  patchCompany(permalink, { company_name: companyName });
                }
              }}
            />
          </li>

          <li>
            <Input
              label="Website"
              value={homepageUrl}
              onChange={(e) => setHomepageUrl(e.target.value)}
              onBlur={() => {
                if (permalink && homepageUrl !== company?.homepage_url) {
                  patchCompany(permalink, { homepage_url: homepageUrl });
                }
              }}
            />
          </li>

          <li>
            <label>Description</label>
            <textarea
              className="form-control"
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
              onBlur={() => {
                if (permalink && shortDescription !== company?.short_description) {
                  patchCompany(permalink, { short_description: shortDescription });
                }
              }}
            />
          </li>

          {prospectId && (
            <li>
              <Select
                label="Stage"
                options={stages}
                initialValue={stage}
                onChange={handleStageChange}
              />
            </li>
          )}

          <li>
            <Input
              label="HQ City"
              value={company?.location}
              onChange={(e) => setLocation(e.target.value)}
              onBlur={() => {
                if (permalink && location !== company?.location) {
                  patchCompany(permalink, { location });
                }
              }}
            />
          </li>

          <li>
            <Input
              label="Founders"
              value={foundersName}
              onChange={(e) => setFoundersName(e.target.value)}
              onBlur={() => {
                if (permalink && foundersName !== company?.founders_name) {
                  patchCompany(permalink, { founders_name: foundersName });
                }
              }}
            />
          </li>
          <li>
            <Input
              label="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              onBlur={() => {
                if (permalink && country !== company?.country) {
                  patchCompany(permalink, { country });
                }
              }}
            />
          </li>
          <li>
            <Input
              label="Region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              onBlur={() => {
                if (permalink && region !== company?.region) {
                  patchCompany(permalink, { region });
                }
              }}
            />
          </li>
          <li>
            <Input
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              onBlur={() => {
                if (permalink && city !== company?.city) {
                  patchCompany(permalink, { city });
                }
              }}
            />
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Done</Button>
      </Modal.Footer>
    </Modal>
  );
};

const CompanySummary = ({ summary, refreshCompanySummary }) => {
  const [showModal, setShowModal] = useState(false);
  const [founders, setFounders] = useState(summary?.founders_name?.split(', '));
  const [founderAnnotations, setFounderAnnotations] = useState({});

  const closeModal = useCallback(() => {
    refreshCompanySummary();
    setShowModal(false);
  }, [refreshCompanySummary]);

  useEffect(() => {
    if (summary?.founders_name) {
      const foundersList = summary.founders_name.split(', ');
      setFounders(foundersList);
      if (foundersList.length > 0 && summary.harmonic_id) {
        getHarmonicFounders(summary.harmonic_id).then((data) => {
          const foundersDict = {};
          data.forEach((founder) => {
            const { fullName, email, title } = founder;
            foundersDict[fullName] = { email, title };
          });
          setFounderAnnotations(foundersDict);
        });
      }
    }
  }, [summary?.founders_name]);

  return (
    <div className="content-container card">
      <div className="card-block company-info">
        <h3>Company Details</h3>
        <ul className="list-unstyled list-spaced">
          {summary && (
            <li>
              <CompanySocialIcons summary={summary} />
            </li>
          )}
          <li>
            <label>Description</label>
            <span className="text-muted" />
            {summary.short_description}
          </li>
          <li>
            <label>Website</label>
            <p>
              {summary.homepage_url ? (
                <a href={getExternalURL(summary.homepage_url)} target="_blank" rel="noreferrer">
                  {getHostname(summary.homepage_url)}
                </a>
              ) : (
                '-'
              )}
            </p>
          </li>
          <li>
            <label>Location</label>
            <p>{summary.location ? summary.location : '-'}</p>
          </li>

          <li>
            <label>Founded</label>
            <p>{summary.founded_on ? format(new Date(summary.founded_on), 'MMM Mo, yyyy') : '-'}</p>
          </li>

          <li>
            <label>Founders</label>
            <p>
              {founders ? founders.map((founder, index) => {
                const annotation = founderAnnotations[founder];
                const content = annotation ? (
                  <OutreachTemplate founder={founder} annotation={annotation} summary={summary} />
                ) : (
                  founder
                );

                return (
                  <React.Fragment key={founder}>
                    {content}
                    {index < founders.length - 1 && ', '}
                  </React.Fragment>
                );
              }): '-'}
            </p>
          </li>

          <li>
            <label>Total Funding</label>
            <p>
              {parseInt(summary.funding_total_usd, 10) > 0
                ? displayMoney(summary.funding_total_usd)
                : '-'}
            </p>
          </li>
          {summary.permalink && <CompanyOwnership company={summary.permalink} />}
        </ul>
      </div>
      <div>
        <button type="button" className="tv-button" onClick={() => setShowModal(true)}>
          Edit
        </button>
        <EditCompanyModal company={summary} showModal={showModal} closeModal={closeModal} />
      </div>
    </div>
  );
};

export default memo(CompanySummary);

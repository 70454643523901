import React, { useContext } from 'react';
import styled from 'styled-components';

import { ProspectContext } from '../../contexts/ProspectContext';
import { Button, Icon } from '../primitives';

export const Container = styled.div`
  position: absolute !important;
  right: 0px;
  background: white;
  display: flex !important;
  height: 100%;
  align-items: center;
  justify-content: center;
  button {
    padding: 5px 8px 2px 8px;
    min-width: auto;
    width: 32px;
  }
  .icon-wrapper {
    padding: 0;
    z-index: 0;
  }
`;

export default function ProspectInlineButton({ isHovered, loading }) {
  const [, dispatch] = useContext(ProspectContext);

  return (
    <Container isHovered={isHovered}>
      <Button
        onClick={() => dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: true } })}
      >
        {isHovered ? (
          <Icon name="edit" height="15px" width="15px" color="#6e6e6e" />
        ) : (
          <Icon name="plus" height="15px" width="15px" color="#6e6e6e" />
        )}
      </Button>
    </Container>
  );
}

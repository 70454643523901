import React from 'react';
import styled from 'styled-components';

const Span = styled.div`
  margin-left: 4px;
  margin-top: 3px;
  position: absolute;
`;

const ItalicIcon = () => {
  return (
    <Span>
      <svg
        fill="#000000"
        width="20"
        height="19"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.5 1.25V0H4.71v1.25h3.67l-2.02 13.5H2.5V16h8.79v-1.25H7.62l2.02-13.5h3.86z" />
      </svg>
    </Span>
  );
};

export default ItalicIcon;

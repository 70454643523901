import React, { memo } from 'react';
import styled from 'styled-components';
import harmonicLogo from '../../images/harmonic_logo.png';
import crunchbaseLogo from '../../images/crunchbase_logo.png';
import { getExternalURL } from '../../util';
import { Icon } from '../primitives';

const Logo = styled.img`
  width: 14px;
  height: 14px;
  margin-top: -3.5px;
  margin-right: 5px;
  border-radius: 3px;
`;

export const CompanySocialIcons = memo(({ summary }) => {
  return (
    <div className="social-media-icons">
      {summary?.twitter_url && (
        <a href={summary?.twitter_url} target="_blank" rel="noreferrer">
          <i className="fa fa-twitter-square" />
        </a>
      )}
      {summary?.facebook_url && (
        <a href={summary?.facebook_url} target="_blank" rel="noreferrer">
          <i className="fa fa-facebook-square" />
        </a>
      )}
      {summary?.linkedin_url && (
        <a href={summary?.linkedin_url} target="_blank" rel="noreferrer">
          <i className="fa fa-linkedin-square" />
        </a>
      )}
      {summary?.cb_url && (
        <a href={summary?.cb_url} target="_blank" rel="noreferrer">
          <Logo src={crunchbaseLogo} alt="Crunchbase Logo" />
        </a>
      )}
      {summary?.harmonic_id && (
        <a
          href={`https://console.harmonic.ai/dashboard/company/${summary?.harmonic_id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Logo src={harmonicLogo} alt="Harmonic Logo" />
        </a>
      )}
      {summary.homepage_url && (
        <a href={getExternalURL(summary.homepage_url)} target="_blank" rel="noreferrer">
          <Icon name="link" />
        </a>
      )}
    </div>
  );
});

import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '../primitives';
import { getUser } from '../../utils/Auth2';

import { patchCompany, getUsers } from '../../BackendAPI';

const determineCompanyStatus = (prospectStatus, verticalPriority) => {
  if (prospectStatus?.toLowerCase() === 'portfolio') {
    return 'Portfolio';
  }

  if (prospectStatus?.toLowerCase() === 'passed') {
    return 'Passed';
  }

  if (prospectStatus?.toLowerCase() === 'dormant') {
    return 'Dormant';
  }

  if (prospectStatus?.toLowerCase() === 'active') {
    return 'Active';
  }

  if (verticalPriority) {
    return 'Vertical Priority';
  }

  return null;
};

export default function CompanyVerticalPriority({ company, isVerticalPriority, companyStatus }) {
  const [priority, setPriority] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const user = getUser();

  const fetchUsers = useCallback(async () => {
    const users = await getUsers();
    setActiveUser(users.find((u) => u.email === user?.email));
  }, [user?.email]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    setPriority(isVerticalPriority);
  }, [isVerticalPriority]);

  const updateCompany = useCallback(
    async (bool) => {
      setPriority(bool);
      await patchCompany(company, { vertical_priority: bool });
      await patchCompany(company, {
        major_status: determineCompanyStatus(companyStatus, bool),
      });

      if (bool) {
        const now = new Date().toISOString();
        await patchCompany(company, { vertical_priority_date_added: now });
        await patchCompany(company, { vertical_priority_added_by: activeUser?.code });
      }
    },
    [company, companyStatus, activeUser?.code],
  );

  return (
    <Button active={priority} onClick={() => updateCompany(!priority)}>
      + Vertical Priority
    </Button>
  );
}

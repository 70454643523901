import React from 'react';

const PlusBasicIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 30 30" version="1.1">
      <title>plus-icon</title>
      <g id="plus-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="001-x-mark"
          transform="translate(5.000000, 5.000000)"
          fill="#ED3B64"
          fillRule="nonzero"
        >
          <path
            d="M15.6928336,16.7882524 C15.4031392,16.7808243 15.1106113,16.6628866 14.8839192,16.4359583 L3.27407339,4.826088 C2.82045336,4.37246701 2.80208778,3.65620955 3.23303296,3.22551136 C3.66373634,2.79480697 4.38022781,2.81293691 4.83360604,3.2665517 L16.4434518,14.876422 C16.8970718,15.330043 16.9154374,16.0463004 16.4844922,16.4769986 C16.2691467,16.6925927 15.9825278,16.7956805 15.6928336,16.7882524 L15.6928336,16.7882524 Z"
            id="Path"
            transform="translate(9.858763, 9.851334) rotate(-315.000000) translate(-9.858763, -9.851334) "
          />
          <path
            d="M4.37300102,16.4980028 C4.08330483,16.4905747 3.79101687,16.3726439 3.56432318,16.145717 C3.11070648,15.6923407 3.09233483,14.9758462 3.52328303,14.5451511 L14.5527128,3.51585051 C14.9836548,3.08491356 15.6999154,3.10327921 16.1532965,3.55689112 C16.6066714,4.01026124 16.6252849,4.72676192 16.1943367,5.15745708 L5.16490695,16.1867576 C4.94955998,16.4023501 4.66269728,16.5054309 4.37300102,16.4980028 L4.37300102,16.4980028 Z"
            id="Path"
            transform="translate(9.858766, 9.851334) rotate(-315.000000) translate(-9.858766, -9.851334) "
          />
        </g>
      </g>
    </svg>
  );
};

export default PlusBasicIcon;

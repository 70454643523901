import React from 'react';

const PlusBasicIcon = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill={props.color ?? '#ed3c64'}
      height={props.height || '40'}
      width={props.width || '40'}
    >
      <path
        fillRule="evenodd"
        fill={props.color ?? '#ed3c64'}
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default PlusBasicIcon;

import React, { useEffect, memo, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { LayoutHeader, Flex, Loader, Card } from '../components/primitives';
import {
  getInvestorSummary,
  getInvestorInvestments,
  getInvestorPercentile,
  getCompanyFeed,
} from '../BackendAPI';
import { TableHeader, Table, TableBody } from '../components/primitives/Table';
import ListPanel from '../components/ListPanel';
import useIsMobile from '../hooks/useIsMobile';
import InvestorSummary from '../components/InvestorSummary';
import InvestorVerticals from '../components/InvestorVerticals';
import InvestorSignals from '../components/InvestorSignals';
import FeedFilter from '../components/FeedFilter';
// Vertical Stats
import AddTask from '../components/AddTask';
import AddNote from '../components/AddNote';
import CompanyFeedItem from '../components/company/CompanyFeedItem';
import { compare, removeHyphenAndCapitalize } from '../util';
import { VERTICAL_FILTERS } from './Engaged';

const isEmpty = (obj) => Object.keys(obj).length === 0;

const EmptyContainer = styled(Card)`
  min-height: 500px;
`;

const StyledP = styled.p`
  margin-bottom: 12px !important;
`;

const INVESTOR_COLUMNS = [
  { colClass: 'name', text: 'Name', key: 'company_name', sortable: true },
  { colClass: 'description', text: 'Description', key: 'short_description', sortable: false },
  { colClass: 'vertical', text: 'Vertical', key: 'tv_vertical', sortable: true },
  { colClass: 'status', text: 'Status', key: 'major_status', sortable: false },
  { colClass: 'list-stage-col', text: 'Last Round', key: 'rounds', sortable: true },
  {
    colClass: 'list-date-col',
    text: 'Invest Date',
    key: 'initial_funding_date',
    sortable: true,
  },
  { colClass: 'location', text: 'Location', key: 'location', sortable: true },
  { colClass: 'investors', text: 'Key Investors', key: 'key_investors', sortable: false },
];

const initialFeeds = [
  { label: 'Investments', value: 'investments', active: true },
  { label: 'Notes', value: 'notes', active: false },
];

const Container = styled.div`
  .table-container {
    width: 115%;
    position: relative;

    .column {
      &.description,
      &.investors {
        width: 20%;
        min-width: 160px;
      }
      &.status {
        min-width: 130px;
      }
    }
  }

  @media (max-width: 991px) {
    margin-top: 70px;
  }
`;

const Investor = ({ investor }) => {
  const isMobile = useIsMobile();
  const [sortKey, setSortKey] = useState('initial_funding_date');
  const [isAsc, setIsAsc] = useState(false);
  const [verticalFilters, setVerticalFilters] = useState(VERTICAL_FILTERS);

  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [mobileNav, setMobileNav] = useState('overview');
  const [summary, setSummary] = useState({});
  const [percentileA, setPercentileA] = useState(null);
  const [percentileB, setPercentileB] = useState(null);
  const [investments, setInvestments] = useState(null);
  const [notes, setNotes] = useState([]);
  const [feeds, setFeeds] = useState(initialFeeds);

  const getInvestorNotes = useCallback(
    async (notesActive = false) => {
      const feed = await getCompanyFeed(investor);
      const filteredNotes = feed
        .filter((f) => f.type === 'note')
        .sort((a, b) => new Date(b.feed_time) - new Date(a.feed_time));

      setNotes(filteredNotes);
      setFeeds([
        { label: 'Investments', value: 'investments', active: !notesActive },
        {
          label: notes.length ? `Notes (${notes.length})` : 'Notes',
          value: 'notes',
          active: notesActive,
        },
      ]);
    },
    [investor, notes?.length],
  );

  const fetchAllInvestorData = useCallback(async () => {
    const investorSummary = await getInvestorSummary(investor);
    setSummary(investorSummary);

    const investorInvestments = await getInvestorInvestments(investor);
    setInvestments(investorInvestments);

    const investorPercentileA = await getInvestorPercentile(investor, 'series_a');
    setPercentileA(investorPercentileA);

    const investorPercentileB = await getInvestorPercentile(investor, 'series_b');
    setPercentileB(investorPercentileB);

    await getInvestorNotes();
  }, [getInvestorNotes, investor]);

  useEffect(() => {
    fetchAllInvestorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMobileNav = (item) => {
    setMobileNav(item);
    setMobileNavActive(true);
  };

  const toggleFeed = useCallback(
    (value) => {
      feeds.forEach((f) => (f.active = f.value === value));
      setFeeds([...feeds]);
    },
    [feeds],
  );

  const sort = (key) => {
    if (sortKey === key) {
      setIsAsc(!isAsc);
    } else {
      setIsAsc(true);
      setSortKey(key);
    }
  };

  const feedType = useMemo(() => feeds.find((f) => f.active).value, [feeds]);

  const hasVertical = useCallback(
    (company) => {
      const activeVerticalFilter = verticalFilters.filter((v) => v.active);

      if (activeVerticalFilter.find((a) => a.name === 'All')) return true;

      const { tv_vertical, harmonic_verticals } = company;

      const companyVerticals = tv_vertical?.split(', ') ?? [];
      const harmonicVerticals = harmonic_verticals?.split(',') ?? [];

      const hasActiveVertical = activeVerticalFilter.some((a) => {
        const hasHarmonicVertical = harmonicVerticals.includes(a.longName);
        const hasCompanyVertical = companyVerticals.includes(a.name);
        return hasHarmonicVertical || hasCompanyVertical;
      });

      return hasActiveVertical;
    },
    [verticalFilters],
  );

  const sortedInvestments = useMemo(() => {
    if (!investments) return [];

    const dataSorted = investments.sort((a, b) => compare(a[sortKey], b[sortKey]));

    if (!isAsc) {
      dataSorted.reverse();
    }

    return dataSorted.filter(hasVertical);
  }, [investments, isAsc, sortKey, hasVertical]);

  const changeVertical = (value) => {
    window.scrollTo(0, 0);
    const newVertical = value;
    verticalFilters.forEach((v) => (v.active = newVertical === v.name));
    setVerticalFilters([...verticalFilters]);
  };

  return (
    <div>
      <Helmet>
        <title>{summary?.name ?? removeHyphenAndCapitalize(investor)} - TV CRM</title>
      </Helmet>

      <LayoutHeader className="company-header">
        <div>
          <h2>{summary.name}</h2>
        </div>
        <div className="company-actions">
          <ul>
            <li>
              <AddTask company={investor} />
            </li>
            <li>
              <AddNote permalink={investor} company={summary.name} />
            </li>
          </ul>
        </div>
      </LayoutHeader>

      <Container className="container page-container company-profile investor-profile">
        <div className="mobile-profile-nav investor-page">
          <ul>
            <li className={mobileNav === 'overview' ? 'active' : ''}>
              <button type="button" onClick={() => toggleMobileNav('overview')}>
                Overview
              </button>
            </li>
            <li className={mobileNav === 'feed' ? 'active' : ''}>
              <button type="button" onClick={() => toggleMobileNav('feed')}>
                Feed
              </button>
            </li>
          </ul>
        </div>

        <div className="row">
          <div
            className={`left-sidebar col-md-3${
              mobileNavActive && mobileNav !== 'overview' ? ' hidden' : ''
            }`}
          >
            <div className="sidebar-container">
              {isEmpty(summary) ? null : <InvestorSummary summary={summary} />}
              <InvestorSignals
                investor={investor}
                summary={summary}
                percentileA={percentileA}
                percentileB={percentileB}
              />
              <InvestorVerticals investor={investor} />
            </div>
          </div>
          <div
            className={`col-md-9${mobileNavActive && mobileNav === 'feed' ? '' : ' mobile-feed'}`}
          >
            {isMobile && (
              <Flex
                className="toggle-view-btns btn-group flagged-btns is-mobile"
                role="group"
                aria-label="..."
              >
                {feeds.map((f) => (
                  <button
                    type="button"
                    key={f.value}
                    className={`btn btn-default${f.active ? ' active' : ''}`}
                    onClick={() => toggleFeed(f.value)}
                    value={f.value}
                  >
                    {f.label}
                  </button>
                ))}
              </Flex>
            )}

            <FeedFilter
              withVerticalFilter
              handleOnVerticalChange={changeVertical}
              childFilter={
                <div
                  className="toggle-view-btns btn-group flagged-btns"
                  role="group"
                  aria-label="..."
                >
                  {feeds.map((f) => (
                    <button
                      type="button"
                      key={f.value}
                      className={`btn btn-default${f.active ? ' active' : ''}`}
                      onClick={() => toggleFeed(f.value)}
                      value={f.value}
                    >
                      {f.label}
                    </button>
                  ))}
                </div>
              }
              padding={isMobile ? '10px 24px' : '12px 34px'}
              margin="0"
            />

            {feedType === 'investments' && (
              <div className="company-panel-list investor-list">
                <Table maxHeightMobileDelta={'320px'} maxHeightDelta={'230px'}>
                  <TableHeader
                    headerColumns={INVESTOR_COLUMNS}
                    sort={sort}
                    sortKey={sortKey}
                    isAsc={isAsc}
                  />
                  {!investments || investments.length === 0 ? (
                    <Flex
                      fill
                      align="center"
                      justify="center"
                      width="85%"
                      height={`calc(100vh - ${isMobile ? '300px' : '320px'})`}
                    >
                      {investments?.length === 0 ? <h6>No results found</h6> : <Loader />}
                    </Flex>
                  ) : (
                    <TableBody>
                      {sortedInvestments.map((c) => (
                        <ListPanel company={c} key={c.permalink} columns={INVESTOR_COLUMNS} />
                      ))}
                    </TableBody>
                  )}
                </Table>
              </div>
            )}

            {feedType === 'notes' && (
              <Flex className={mobileNavActive && mobileNav === 'feed' ? '' : ' mobile-feed'} fill>
                <div className="company-panel-list company-panel-feed">
                  {notes?.length > 0 ? (
                    <div className="list-table">
                      {notes.map((f) => (
                        <CompanyFeedItem
                          key={f.key}
                          item={f}
                          isCompany
                          refreshFeed={() => getInvestorNotes(true)}
                        />
                      ))}
                    </div>
                  ) : (
                    <EmptyContainer className="list-table empty" align="center" justify="center">
                      <Flex direction="column" align="center" justify="center">
                        <StyledP>Your investor notes will go here</StyledP>
                        <AddNote permalink={investor} company={summary.name} ctaCopy="Add Note" />
                      </Flex>
                    </EmptyContainer>
                  )}
                </div>
              </Flex>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default memo(Investor);

import React from 'react';

const InfoIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 16 16" version="1.1">
      <g id="StyleGuide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons-&amp;-Buttons" transform="translate(-1095.000000, -330.000000)" fill="#FC4066">
          <path
            d="M1103.8,340.8 L1104.20015,340.8 C1104.6419,340.8 1105,341.155092 1105,341.6 C1105,342.041828 1104.64503,342.4 1104.20015,342.4 L1101.79985,342.4 C1101.3581,342.4 1101,342.044908 1101,341.6 C1101,341.158172 1101.35497,340.8 1101.79985,340.8 L1102.2,340.8 L1102.2,338.4 L1101.80237,338.4 C1101.35923,338.4 1101,338.044908 1101,337.6 C1101,337.158172 1101.35462,336.8 1101.80237,336.8 L1102.99763,336.8 C1103.22052,336.8 1103.42021,336.88939 1103.56492,337.034086 C1103.71051,337.178258 1103.8,337.377942 1103.8,337.6 L1103.8,340.8 Z M1103,346 C1098.58172,346 1095,342.418278 1095,338 C1095,333.581722 1098.58172,330 1103,330 C1107.41828,330 1111,333.581722 1111,338 C1111,342.418278 1107.41828,346 1103,346 Z M1103,344.4 C1106.53462,344.4 1109.4,341.534622 1109.4,338 C1109.4,334.465378 1106.53462,331.6 1103,331.6 C1099.46538,331.6 1096.6,334.465378 1096.6,338 C1096.6,341.534622 1099.46538,344.4 1103,344.4 Z M1103,336 C1102.33726,336 1101.8,335.462742 1101.8,334.8 C1101.8,334.137258 1102.33726,333.6 1103,333.6 C1103.66274,333.6 1104.2,334.137258 1104.2,334.8 C1104.2,335.462742 1103.66274,336 1103,336 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};

export default InfoIcon;

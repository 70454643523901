import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { forgotPassword } from '../utils/Auth';
import captureError from '../utils/sentry';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const res = await forgotPassword(email.toLowerCase());

        if (res.status === 200) {
          setSent(true);
          setEmail('');
        } else {
          setError(true);
          captureError(`Forgot password err: ${JSON.stringify(res)}`);
        }
      } catch (err) {
        setError(true);
        captureError(err);
      }
    },
    [email],
  );

  return (
    <>
      <Helmet>
        <title>Password Reset - TV CRM</title>
      </Helmet>

      <div className="container-fluid container-fill-height">
        <div className="container-content-middle">
          <div role="form" className="mx-auto text-center app-login-form">
            <div className="navbar-brand tv-nav login">
              TV<i>CRM</i>
            </div>
            <div>
              <div className="form-group">
                <input
                  className="form-control"
                  name="email"
                  value={email}
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <button disabled={email === ''} type="submit" onClick={handleSubmit}>
                  Reset Password
                </button>
              </div>
              {sent && (
                <div>
                  <p>Check email for reset instructions!</p>
                </div>
              )}
              {error && (
                <div>
                  <p className="error">There was an issue. Please try again or contact support.</p>
                </div>
              )}
            </div>
            <div className="mb-5">
              <Link to="/login">Back to Login</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;

import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  getInvestorVerticalExpertise,
  addInvestorVerticalExpertise,
  removeInvestorVerticalExpertise,
} from '../BackendAPI';

class InvestorVerticalExpertise extends Component {
  state = {
    showModal: false,
    expertise: [],
    selectedExpertise: [],
  };

  componentDidMount() {
    const { investor } = this.props;

    getInvestorVerticalExpertise(investor).then((expertise) => {
      this.setState({ expertise });
      const selectedExpertise = expertise.filter((e) => e.checked).map((e) => e.code);
      this.setState({ selectedExpertise });
    });
  }

  openModal = () => this.setState({ showModal: true });

  closeModal = () => {
    const { expertise, selectedExpertise } = this.state;

    for (let i = 0; i < expertise.length; i += 1) {
      if (selectedExpertise.includes(expertise[i].code)) {
        expertise[i].checked = true;
      } else {
        expertise[i].checked = false;
      }
    }
    this.setState({ showModal: false, expertise });
  };

  changeBox = (event) => {
    const vertical = event.target.value;
    const { expertise } = this.state;

    for (let i = 0; i < expertise.length; i += 1) {
      if (vertical !== expertise[i].vertical) continue;
      expertise[i].checked = !expertise[i].checked;
    }
    this.setState({ expertise });
  };

  submitModal = () => {
    const { investor } = this.props;
    const { expertise, selectedExpertise } = this.state;

    for (let i = 0; i < expertise.length; i += 1) {
      if (expertise[i].checked === selectedExpertise.includes(expertise[i].code)) continue;
      if (expertise[i].checked) {
        addInvestorVerticalExpertise(investor, expertise[i].vertical);
      } else {
        removeInvestorVerticalExpertise(investor, expertise[i].vertical);
      }
    }

    const newExpertise = expertise.filter((e) => e.checked).map((e) => e.code);
    this.setState({ showModal: false, selectedExpertise: newExpertise });
  };

  displayButton = () => {
    const { selectedExpertise } = this.state;

    if (selectedExpertise.length === 0)
      return (
        <button type="button" onClick={this.openModal}>
          <label>Expertise</label>
          <span className="none">-</span>
        </button>
      );

    return (
      <button type="button" onClick={this.openModal}>
        <label>Expertise</label>
        <span className="vertical-box score">
          <div>
            {selectedExpertise.map((e) => (
              <span className={`vertical-item ${e.toLowerCase()}`} key={e}>
                {e}
              </span>
            ))}
          </div>
        </span>
      </button>
    );
  };

  render() {
    const { expertise } = this.state;

    return (
      <div>
        <div className="company-score">{this.displayButton()}</div>

        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.closeModal}
          className="tv-modal tv-company-modal"
        >
          <Modal.Header>
            <Modal.Title>Expertise</Modal.Title>
            <Button className="modal-close" onClick={this.closeModal}>
              <i className="fa fa-times" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-unstyled list-spaced">
              {expertise.map((e) => (
                <li className="form-checkbox-row" key={e.vertical}>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={e.checked}
                        value={e.vertical}
                        onChange={this.changeBox}
                      />
                      {e.vertical}
                      <i className="fa fa-circle-thin unchecked" aria-hidden="true" />
                      <i className="fa fa-check-circle checked" aria-hidden="true" />
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.submitModal}>Submit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default InvestorVerticalExpertise;

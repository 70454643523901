import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Card, Loader, Button } from '../primitives';
import Boundary from '../Boundary';
import { getSavedSearches } from '../../BackendAPI';

const DISPLAY_INCREMENTS = 3;

const OuterContainer = styled(Flex)`
  padding-bottom: 20px;
  border-top: 1px solid #ebeaea;
`;

const SavedSearchesLoadingFallback = () => {
  return (
    <Card padding="0" borderRadius={false} boxShadow={false} direction="column" fill>
      <Flex justify="center" fill marginTop="60" marginBottom="60">
        <Loader />
      </Flex>
    </Card>
  );
};

function SavedSearchesWidgetContent() {
  const [savedSearches, setSavedSearches] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [numDisplay, setNumDisplay] = useState(DISPLAY_INCREMENTS);

  const increment = () => setNumDisplay((i) => i + DISPLAY_INCREMENTS);
  const decrement = () => setNumDisplay((i) => i - DISPLAY_INCREMENTS);

  const moreSearches = numDisplay < savedSearches?.length;

  useEffect(() => {
    if (!fetched) {
      getSavedSearches().then((searches) => {
        setSavedSearches(searches);
        setFetched(true);
      });
    }
  }, [fetched]);

  if (savedSearches?.length === 0 && fetched) {
    return null;
  }

  return (
    <Flex direction="column" fill>
      <h6 className="section-header widget">Saved Searches</h6>
      <OuterContainer direction="column" fill>
        {savedSearches?.length > 0 ? (
          <Card
            padding="0"
            direction="column"
            borderRadius={false}
            boxShadow={false}
            border={false}
            fill
          >
            {savedSearches.slice(0, numDisplay).map((s) => {
              const { name, entityUrn } = s;
              const listId = entityUrn.split(':')[3];
              const url = `https://console.harmonic.ai/dashboard/companies/${entityUrn}`;

              return (
                <div className="widget-row-container" key={`savedSearch-${listId}`}>
                  <span className="watchlistPanel__name">
                    <a href={url} target="_blank" rel="noreferrer">
                      {name}
                    </a>
                  </span>
                  <div className="widget-row-body">
                    <span className="widget-row-body-bold" />
                  </div>
                </div>
              );
            })}
          </Card>
        ) : (
          <SavedSearchesLoadingFallback />
        )}

        {savedSearches?.length > DISPLAY_INCREMENTS && (
          <Flex fill justify="center">
            <Button rounded onClick={moreSearches ? increment : decrement}>
              {moreSearches ? 'See More' : 'See Less'}
            </Button>
          </Flex>
        )}
      </OuterContainer>
    </Flex>
  );
}

const SavedSearchesWidget = () => {
  return (
    <Boundary fallback={null} loadingFallback={<SavedSearchesLoadingFallback />}>
      <SavedSearchesWidgetContent />
    </Boundary>
  );
};

export default memo(SavedSearchesWidget);

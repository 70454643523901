import React, { memo } from 'react';
import styled from 'styled-components';
import useIsMobile from '../../../hooks/useIsMobile';

const Header = styled.header`
  ${({ isMobile, width = '100%' }) => !isMobile && `width: ${width};`}
  position: sticky;
  z-index: 100;
  background: white;
  top: 0;
  align-items: center;
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #ebeaea;
  height: 44px;
  @media (max-width: 768px) {
    padding: 10px 10px;
  }
  p {
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    color: #807f80;
    position: relative;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;

    &.column {
      width: 10%;
      min-width: 100px;

      &.sortable {
        span {
          cursor: pointer;
        }
      }

      &.sorting::after {
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #323232;

        vertical-align: middle;
        margin-left: 5px;
        top: 6px;
        position: absolute;
        content: '';
      }

      &.sorting.asc::after {
        border-top: none;
        border-bottom: 5px solid #323232;
      }
    }

    &.name {
      width: 12%;
    }

    &.description,
    &.investors {
      width: 20%;
      min-width: 200px;
    }
  }
`;

type Column = {
  text: string;
  key: string;
  colClass: string;
  sortable: boolean;
};

type Props = {
  headerColumns: Column[];
  sort: (a: string) => void;
  sortKey: string;
  isAsc: boolean;
  width: string;
};

const TableHeader = ({ headerColumns, sort, sortKey, isAsc, width }: Props) => {
  const isMobile = useIsMobile();
  return (
    <Header isMobile={isMobile} width={width}>
      {headerColumns.map((c, index) => {
        const sortClass = sortKey !== c.key ? '' : ` sorting ${isAsc ? 'asc' : 'desc'}`;
        const sortable = c.sortable ? 'sortable' : '';
        return (
          <p
            className={`column col${index} ${sortClass} ${sortable} ${c.colClass}`}
            key={c.key}
            onClick={() => c.sortable && sort(c.key)}
          >
            <span>{c.text}</span>
          </p>
        );
      })}
    </Header>
  );
};
export default memo(TableHeader);

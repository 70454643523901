import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.a`
  line-height: 28px;
  min-height: 28px;
  display: inline-block;
  padding: 2px 15px;
  font-size: 12px;
  transition: 300ms all;
  min-width: 70px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: #323232;
  font-weight: 700;
  box-shadow: 0 1px 1px 0 #ddd;
  ${({ fill }) => fill && 'width: 100%;'}
  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  &:hover {
    text-decoration: none;
  }
`;

const LinkButton = ({ children, href, disabled = false, fill }) => {
  return (
    <StyledButton href={href} disabled={disabled} fill={fill}>
      {children}
    </StyledButton>
  );
};

export default LinkButton;

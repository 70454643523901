import React from 'react';

const MinusIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 30 30" version="1.1">
      <g id="New-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="circular-cons"
          transform="translate(-68.000000, -23.000000)"
          fill="#ED3B64"
          fillRule="nonzero"
        >
          <g id="minus-icon" transform="translate(68.000000, 23.000000)">
            <path
              d="M15,0 C6.71529412,0 0,6.71529412 0,15 C0,23.2847059 6.71529412,30 15,30 C23.2847059,30 30,23.2837255 30,15 C30,6.71627451 23.2847059,0 15,0 Z M15,28.125 C7.75122549,28.125 1.875,22.2487745 1.875,15 C1.875,7.75122549 7.75122549,1.875 15,1.875 C22.2487745,1.875 28.125,7.75122549 28.125,15 C28.125,22.2487745 22.2487745,28.125 15,28.125 Z M20.625,14.0625 L15.9375,14.0625 L14.0625,14.0625 L9.375,14.0625 C8.8575,14.0625 8.4375,14.4825 8.4375,15 C8.4375,15.5175 8.8575,15.9375 9.375,15.9375 L14.0625,15.9375 L15.9375,15.9375 L20.625,15.9375 C21.1425,15.9375 21.5625,15.5175 21.5625,15 C21.5625,14.4825 21.1425,14.0625 20.625,14.0625 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MinusIcon;

import React, { useContext } from 'react';
import { ProspectContext } from '../../contexts/ProspectContext';
import { patchProspectScore, patchCompany } from '../../BackendAPI';

const getScore = (event, value) => {
  const rawValue = event.target.value;
  if (rawValue !== 'on') {
    return Number(rawValue);
  }
  return 1 - value;
};

export default function ProspectScore({ score, stageType }) {
  const [state, dispatch] = useContext(ProspectContext);
  const { value, score_text, score_id, max_score, id, stage_type_id } = score;

  const updateScore = async (event, val) => {
    const newScore = getScore(event, val);
    const payload = { stageType: stage_type_id, scoreId: score_id, value: newScore };

    dispatch({ type: 'UPDATE_SCORE', payload });

    if (id) {
      await patchProspectScore(id, { value: newScore });

      const scores = state?.prospectScores.filter((s) => s.stage_type_id === stageType);
      const totalScore = scores?.map((s) => s.value).reduce((a, b) => a + b, 0);
      if (totalScore && state.permalink) {
        await patchCompany(state.permalink, { tv_company_score: totalScore });
      }
    }
  };

  if (max_score === 1) {
    return (
      <li key={`prospect-score-${score_id}`}>
        <div className="form-check form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              checked={value === 1}
              onChange={(e) => updateScore(e, value)}
            />{' '}
            {score_text.includes('Geography') ? 'Geography (SF or Toronto)' : score_text}
          </label>
        </div>
      </li>
    );
  }

  const scores = [...Array(score.max_score + 1).keys()];
  return (
    <li key={`prospect-score-${score_id}`}>
      <label> {score_text.includes('Geography') ? 'Geography (SF or Toronto)' : score_text}</label>
      <div className="tv-select-container">
        <select
          value={value}
          onChange={(e) => {
            updateScore(e, value);
            dispatch({
              type: 'UPDATE_SCORE',
              payload: { stageType, scoreId: score_id, value: getScore(e, value) },
            });
          }}
        >
          {scores.map((s) => (
            <option key={s}>{s}</option>
          ))}
        </select>
        <div className="tv-select-arrow">
          <i className="fa fa-angle-down" />
        </div>
      </div>
    </li>
  );
}

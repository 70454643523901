import React, { useCallback } from 'react';
import ErrorBoundary from '../ErrorBoundary';

function shouldCatch(error) {
  if (error instanceof Error) return true;

  if (!error) return false;

  return Boolean(
    Object.prototype.hasOwnProperty.call(error, 'statusCode') ||
      Object.prototype.hasOwnProperty.call(error, 'code'),
  );
}

// interface Props {
//   boundaryName: string;
//   onCatch?: (error: any, info?: any) => void;
//   children: React.ReactNode;
//   errorClass?: string;
//   skipErrorLogging?: boolean;
//   fallback?: React.ReactNode;
// }

export const BaseNetworkErrorBoundary = ({
  onCatch,
  children,
  boundaryName,
  fallback: Fallback,
  ...props
}) => {
  const handleCatch = useCallback(
    (error) => {
      if (shouldCatch(error)) {
        if (onCatch) onCatch(error);
      } else {
        throw error;
      }
    },
    [onCatch],
  );

  return (
    <ErrorBoundary {...props} skipErrorLogging fallback={Fallback} onCatch={handleCatch}>
      {children}
    </ErrorBoundary>
  );
};

const NetworkErrorBoundary = ({ onCatch, ...props }) => {
  const handleCatch = useCallback(
    (error) => {
      if (onCatch) onCatch(error);
    },
    [onCatch],
  );

  return <BaseNetworkErrorBoundary {...props} onCatch={handleCatch} />;
};

export default NetworkErrorBoundary;

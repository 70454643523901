import React, { useContext, useEffect, useState } from 'react';
import slugify from 'slugify';
import { CompanyVerticalContext } from '../contexts/CompanyVerticalContext';
import { getSubverticals, getCompanySubverticals } from '../BackendAPI';
import SubVerticalModal from './SubVerticalModal';

export default function SubVerticalSection(props) {
  const [state, dispatch] = useContext(CompanyVerticalContext);
  const { subverticals } = state;

  useEffect(() => {
    getSubverticals().then((subVerts) => {
      getCompanySubverticals(props.permalink).then((companySubVerticals) => {
        const companySV = companySubVerticals.filter((s) => !s.is_gpt).map((s) => s.subvertical_id);
        const gptSV = companySubVerticals.filter((s) => s.is_gpt).map((s) => s.subvertical_id);
        subVerts.forEach((sv) => {
          sv.checked = companySV.includes(sv.id);
          sv.gpt = gptSV.includes(sv.id);
        });
        dispatch({ type: 'UPDATE_SUBVERTICALS', value: subVerts });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const checkedVerticals = verticals.filter((v) => v.checked);
  const checkedSubverticals = subverticals.filter((sv) => sv.checked).map((v) => v.label);
  const gptSubverticals = subverticals.filter((sv) => sv.gpt).map((v) => v.label);
  return (
    <div className="card-block">
      <h3>Sub-Verticals</h3>
      {checkedSubverticals.map((a) => (
        <span className={`vertical-item ${slugify(a).toLowerCase()}`} key={a}>
          {a}
        </span>
      ))}
      {gptSubverticals.map((a) => (
        <span className={`vertical-item ${slugify(a).toLowerCase()}`} key={a}>
          {a} (GPT)
        </span>
      ))}
      <div>
        <button
          type="button"
          className="tv-button"
          onClick={() => dispatch({ type: 'TOGGLE_SUBVERTICAL_MODAL', value: true })}
        >
          Edit
        </button>
      </div>
      <SubVerticalModal
        permalink={props.permalink}
        subverticals={[...checkedSubverticals, ...gptSubverticals]}
      />
    </div>
  );
}

import React, { useContext, useState, useMemo, memo, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { getRegionFilters, getCityFilters } from '../BackendAPI';
import { InvestorSearchContext } from '../contexts/InvestorSearchContext';
import { Select, Button, Flex, CloseButton } from './primitives';
import captureError from '../utils/sentry';
import { parseParams } from '../pages/AdvancedSearch';

const InlineLabel = ({ filter, toggle }) => {
  const labelText = filter.label.toLowerCase();
  const isVertical = ['ft', 're', 'mt', 'ci', 'cs'].includes(labelText);

  const labelClasses = useMemo(() => {
    let labelClass = 'cs';
    if (isVertical) {
      labelClass = filter.checked ? labelText : 'empty';
    } else {
      labelClass = filter.checked ? 'ft' : 'empty';
    }
    return labelClass;
  }, [filter?.checked, isVertical, labelText]);

  return (
    <span
      className={`vertical-item ${labelClasses}`}
      key={`tier${filter.label}`}
      onClick={() => toggle(filter.value)}
    >
      {filter.label}
    </span>
  );
};

const FilterItem = ({ filter, toggle }) => {
  return (
    <li key={filter.label} className="form-checkbox-row">
      <div className="form-check form-check-inline">
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="radio"
            name={filter.label}
            checked={filter.checked}
            onChange={() => toggle(filter.label)}
          />
          {filter.label}
          <i className="fa fa-circle-thin unchecked" aria-hidden="true" />
          <i className="fa fa-check-circle checked" aria-hidden="true" />
        </label>
      </div>
    </li>
  );
};

const FilterModal = ({ showModal, setShowModal, filters, toggle, filterLabel }) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="tv-showModal tv-company-showModal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{filterLabel} Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-unstyled list-spaced">
          {filters.map((filter, index) => (
            <FilterItem key={index} filter={filter} toggle={toggle} />
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowModal(false)}>Done</Button>
      </Modal.Footer>
    </Modal>
  );
};

const defaultRegion = 'All Regions';
const defaultCity = 'All Cities';

const LocationFilterModal = ({
  showModal,
  setShowModal,
  filters,
  toggle,
  filterLabel,
  setLocationLabel,
  resetFilters,
}) => {
  const location = useLocation();
  const params = parseParams(location);
  const [clear, setClear] = useState(false);
  const countryQueryParam = params?.country?.[0];
  const regionQueryParam = params?.region?.[0];
  const cityQueryParam = params?.city?.[0];

  const [country, setCountry] = useState(countryQueryParam ?? 'United States');
  const [region, setRegion] = useState(regionQueryParam ?? '');
  const [city, setCity] = useState(cityQueryParam ?? '');

  const [regionFilters, setRegionFilters] = useState([]);
  const [cityFilters, setCityFilters] = useState([]);

  const locationLabel = useMemo(() => {
    if (country.includes('All')) return 'Global (All)';
    let label = country;
    if (region && !region.includes('All')) label = `${label}, ${region}`;
    if (city && !city.includes('All')) label = `${label}, ${city}`;
    return label;
  }, [country, region, city]);

  useEffect(() => {
    setLocationLabel(locationLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetFilters) {
      setLocationLabel('United States');
    }
  }, [resetFilters, setLocationLabel]);

  const fetchRegionFilters = useCallback(
    async (countryFilter) => {
      try {
        const response = await getRegionFilters(countryFilter);
        if (response?.length > 0) {
          setRegionFilters([defaultRegion, ...response.sort()]);
        } else {
          setRegionFilters([defaultRegion]);
        }
      } catch (err) {
        captureError(`Fetching regions failed: ${err}`);
        setRegionFilters([defaultRegion]);
      } finally {
        if (regionQueryParam) setRegion(regionQueryParam);
        setCityFilters([]);
        setCity('');
      }
    },
    [regionQueryParam],
  );

  const fetchCityFilters = useCallback(
    async (countryFilter, regionFilter) => {
      try {
        const response = await getCityFilters(countryFilter, regionFilter);
        if (response?.length > 0) {
          setCityFilters([defaultCity, ...response.sort()]);
        }
      } catch (err) {
        captureError(`Fetching cities failed: ${err}`);
        setCityFilters([defaultCity]);
      } finally {
        if (cityQueryParam) setCity(cityQueryParam);
      }
    },
    [cityQueryParam],
  );

  useEffect(() => {
    if (country.includes('All')) {
      setRegionFilters([defaultRegion]);
      setRegion(defaultRegion);
      setCityFilters([defaultCity]);
      setCity(defaultCity);
    } else if (country) {
      setRegion(defaultRegion);

      fetchRegionFilters(country);
    }
  }, [country, fetchRegionFilters, regionQueryParam]);

  useEffect(() => {
    if (region === defaultRegion && !country.includes('All')) {
      setCityFilters([]);
      setCity('');
      return;
    }

    if (region !== '' && region && !region.includes('All') && country) {
      const countryParam = country;
      const regionParam = region.includes('All') ? 'all' : region ?? 'all';
      setCity(defaultCity);
      fetchCityFilters(countryParam, regionParam);
    }
  }, [region, country, fetchCityFilters]);

  const handleDone = useCallback(() => {
    setShowModal(false);

    if (clear) {
      setLocationLabel('United States');
      toggle({ country: 'United States' });
      setClear(false);
    } else {
      setLocationLabel(locationLabel);

      const cityExists = !city.includes('All') && city !== '';
      const regionExists = !region.includes('All') && region !== '';
      const countryExists = !country.includes('All') && country !== '';

      toggle({
        ...(cityExists && { city }),
        ...(regionExists && { region }),
        ...(countryExists && { country }),
      });
    }
  }, [country, region, city, setShowModal, toggle, locationLabel, setLocationLabel, clear]);

  const handleClear = useCallback(() => {
    setCountry('United States');
    setRegion('');
    setCity('');
    setClear(true);
  }, []);

  useEffect(() => {
    if (resetFilters) handleClear();
  }, [resetFilters, handleClear]);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="tv-showModal tv-modal tv-company-showModal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{filterLabel} Options</Modal.Title>
        <CloseButton className="modal-close" onClick={() => setShowModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Select
          label="Country"
          options={filters}
          initialValue={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <Select
          label="Region"
          options={regionFilters}
          initialValue={region}
          onChange={(e) => setRegion(e.target.value)}
          disabled={
            regionFilters.length === 0 || (region === defaultRegion && country?.includes('All'))
          }
        />
        <Select
          label="City"
          options={cityFilters}
          initialValue={city}
          onChange={(e) => setCity(e.target.value)}
          disabled={cityFilters.length === 0 || (city === defaultCity && country?.includes('All'))}
        />
      </Modal.Body>
      <Modal.Footer>
        <Flex justify="space-between" fill>
          <button type="button" className="btn btn-edit-note" onClick={handleClear}>
            Clear
          </button>
          <Button onClick={handleDone}>Search</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

const Filter = ({ filterList, filterLabel, filterType, hasModal, resetFilters }) => {
  const [state, dispatch] = useContext(InvestorSearchContext);
  const [showModal, setShowModal] = useState(false);

  const toggle = (value) => dispatch({ filterList, filterType, value });
  const filters = state.siteFilters[filterType][filterList];
  const numChecked = filters.filter((f) => f.checked).length;

  const [locationLabel, setLocationLabel] = useState('-');

  return (
    <div className="company-score vertical-expertise">
      {!hasModal && (
        <div>
          <label>{filterLabel}</label>
          <span className="score">
            {filters.map((filter, index) => (
              <InlineLabel key={index} filter={filter} toggle={toggle} />
            ))}
          </span>
        </div>
      )}
      {hasModal && (
        <div>
          <label>{filterLabel}</label>
          {filterLabel === 'Location' ? (
            <div className="selected" onClick={() => setShowModal(!showModal)}>
              <p>{locationLabel}</p>
            </div>
          ) : (
            <div className="selected" onClick={() => setShowModal(!showModal)}>
              <p>
                {numChecked} {filterLabel} Selected
              </p>
            </div>
          )}
          {filterList === 'location' ? (
            <LocationFilterModal
              showModal={showModal}
              setShowModal={setShowModal}
              filters={filters}
              toggle={toggle}
              filterLabel={filterLabel}
              setLocationLabel={setLocationLabel}
              resetFilters={resetFilters}
            />
          ) : (
            <FilterModal
              showModal={showModal}
              setShowModal={setShowModal}
              filters={filters}
              toggle={toggle}
              filterLabel={filterLabel}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Filter);

import React from 'react';
import styled from 'styled-components';
import Card from '../Card';
import useIsMobile from '../../../hooks/useIsMobile';

const Container = styled(Card).attrs({ direction: 'column' })`
  max-height: ${({ isMobile, maxHeightDelta, maxHeightMobileDelta }) =>
    `calc(100vh - ${isMobile ? maxHeightMobileDelta : maxHeightDelta});`}
  overflow: scroll;
  padding: 0 !important;
  ${({ withMargin }) =>
    withMargin &&
    `
  margin-top: 20px;
  margin-bottom: 20px;
  `}
  position: relative;
`;

const Table = ({
  children,
  maxHeightDelta = '230px',
  maxHeightMobileDelta = '280px',
  withMargin = true,
}) => {
  const isMobile = useIsMobile();
  return (
    <Container
      className="table-container"
      isMobile={isMobile}
      maxHeightDelta={maxHeightDelta}
      maxHeightMobileDelta={maxHeightMobileDelta}
      withMargin={withMargin}
    >
      {children}
    </Container>
  );
};

export default Table;

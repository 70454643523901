import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import AdvancedSearchContent from '../components/advancedSearch/AdvancedSearchContent';
import AdvancedSearchHeader from '../components/advancedSearch/AdvancedSearchHeader';
import { InvestorSearchContextProvider } from '../contexts/InvestorSearchContext';
import { getSubverticals } from '../BackendAPI';

const Container = styled.div`
  .table-container {
    width: 100%;
    position: relative;

    .column {
      &.col0 {
        width: 16%;
      }
      ${({ activeFeed }) =>
        activeFeed === 'companies'
          ? `
      width: 13.33%;

      &.col1 {
        width: 20%;
      }
      `
          : `

        width: 16%;
      `}
    }
  }
`;

export const parseParams = (location) => {
  const { search } = location;
  const args = search.replace('?', '');

  if (args.length === 0) {
    return {};
  }

  const params = {};
  const argsList = args.split('&');

  for (let i = 0; i < argsList.length; i += 1) {
    const [key, val] = argsList[i].split('=');
    params[key] = val.split(',').map((v) => v.replace('_', ' ').replace(/%20/gi, ' '));
  }

  return params;
};

export default function AdvancedSearch() {
  const location = useLocation();
  const params = parseParams(location);
  const [subverticals, setSubverticals] = useState(null);
  const [activeFeed, setActiveFeed] = useState('companies');

  useEffect(() => {
    getSubverticals().then(setSubverticals);
  }, []);

  return (
    subverticals !== null && (
      <Container activeFeed={activeFeed}>
        <Helmet>
          <title>Advanced Search - TV CRM</title>
        </Helmet>

        <InvestorSearchContextProvider params={params} subverticals={subverticals}>
          <AdvancedSearchHeader />
          <AdvancedSearchContent setActiveFeed={setActiveFeed} subverticals={subverticals} />
        </InvestorSearchContextProvider>
      </Container>
    )
  );
}

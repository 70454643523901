import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import {
  updateInvestorRelationshipScore,
  getInvestorUserRelationships,
  addInvestorUserRelationship,
  removeInvestorUserRelationship,
} from '../BackendAPI';

const Label = styled.label`
  padding-left: 0;
`;

class InvestorRelationships extends Component {
  state = {
    showModal: false,
    relationshipScore: null,
    selectedScore: null,
    scores: [
      { score: 'High', checked: false },
      { score: 'Medium', checked: false },
      { score: 'Low', checked: false },
    ],
    relationships: [],
    selectedMembers: [],
  };

  componentDidMount() {
    const { investor } = this.props;

    getInvestorUserRelationships(investor).then((relationships) => {
      this.setState({ relationships });
      const selectedMembers = relationships.filter((r) => r.checked).map((r) => r.code);
      this.setState({ selectedMembers });
    });
  }

  componentDidUpdate(prevProps) {
    const { score } = this.props;
    if (score === prevProps.score) return;

    this.setState({ relationshipScore: score, selectedScore: score });
    if (!score) return;

    this.updateScore(score);
  }

  updateScore = (rs) => {
    const { scores } = this.state;
    for (let i = 0; i < scores.length; i += 1) {
      scores[i].checked = false;
      if (scores[i].score === rs) scores[i].checked = true;
    }
    this.setState({ scores });
  };

  closeModal = () => {
    this.updateScore(this.state.relationshipScore);
    const { relationships, selectedMembers } = this.state;

    for (let i = 0; i < relationships.length; i += 1) {
      if (selectedMembers.includes(relationships[i].code)) {
        relationships[i].checked = true;
      } else {
        relationships[i].checked = false;
      }
    }
    this.setState({ showModal: false });
  };

  openModal = () => this.setState({ showModal: true });

  submitModal = () => {
    const { selectedScore, relationships, selectedMembers } = this.state;
    if (selectedScore !== this.state.relationshipScore) {
      updateInvestorRelationshipScore(this.props.investor, selectedScore);
      this.setState({ relationshipScore: selectedScore });
    }

    this.updateScore(selectedScore);
    const { investor } = this.props;

    for (let i = 0; i < relationships.length; i += 1) {
      if (relationships[i].checked === selectedMembers.includes(relationships[i].code)) continue;
      if (relationships[i].checked) {
        addInvestorUserRelationship(investor, relationships[i].code);
      } else {
        removeInvestorUserRelationship(investor, relationships[i].code);
      }
    }

    const newMembers = relationships.filter((r) => r.checked).map((r) => r.code);

    this.setState({ showModal: false, selectedMembers: newMembers });
  };

  changeScore = (event) => {
    const score = event.target.value;
    this.updateScore(score);
    this.setState({ selectedScore: score });
  };

  changeRelationship = (event) => {
    const code = event.target.value;
    const { relationships } = this.state;

    for (let i = 0; i < relationships.length; i += 1) {
      if (code !== relationships[i].code) continue;
      relationships[i].checked = !relationships[i].checked;
    }
    this.setState({ relationships });
  };

  renderRelationship = (r) => {
    if (r === null) return <span className="score none">-</span>;
    return <span className={`score ${r.toLowerCase()}`}>{r}</span>;
  };

  render() {
    const { relationshipScore, scores, relationships } = this.state;

    return (
      <div>
        <div className="company-score">
          <button type="button" onClick={this.openModal}>
            <label>Relationship</label>
            {this.renderRelationship(relationshipScore)}
          </button>
        </div>

        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.closeModal}
          className="tv-modal tv-company-modal"
        >
          <Modal.Header>
            <Modal.Title>Relationship</Modal.Title>
            <Button className="modal-close" onClick={this.closeModal}>
              <i className="fa fa-times" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-unstyled list-spaced">
              {scores.map((s) => (
                <li key={s.score} className="form-checkbox-row">
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={s.checked}
                        value={s.score}
                        onChange={this.changeScore}
                      />
                      {s.score}
                      <i className="fa fa-circle-thin unchecked" aria-hidden="true" />
                      <i className="fa fa-check-circle checked" aria-hidden="true" />
                    </label>
                  </div>
                </li>
              ))}
            </ul>

            <h4 className="modal-title team-members-title">Team Members</h4>
            <ul className="list-unstyled list-spaced">
              {relationships
                .filter((r) => r.code)
                .map((r) => (
                  <li key={r.code} className="team-checkbox-row">
                    <div className="team-checkbox">
                      <Label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={r.checked}
                          value={r.code}
                          onChange={this.changeRelationship}
                        />
                        <span>{r.code}</span>
                      </Label>
                    </div>
                  </li>
                ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.submitModal}>Submit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default InvestorRelationships;

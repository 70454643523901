import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Datetime from 'react-datetime';
import '../styles/react-datetime.css';

import { getUsers, getTask, patchTask } from '../BackendAPI';
import { getToday, displayDate } from '../util';

class TaskDescription extends Component {
  state = {
    showModal: false,
    text: '',
    newText: '',
    feedId: null,
    taskId: null,
    responsibility: '',
    newResponsibility: '',
    dueDate: getToday(),
    newDueDate: getToday(),
    users: [{ user: 'N/A', user_id: -1 }],
    status: 'Incomplete',
    newStatus: 'Incomplete',
    statuses: ['Incomplete', 'Completed'],
    completionTime: null,
  };

  componentDidMount = () => {
    const { feedId, custom } = this.props;
    const { task_id } = custom;
    getTask(task_id).then((task) => {
      const { due_date, assigned_user_email, status, task_text, completion_time } = task;
      const dueDate = due_date.slice(0, 10);
      this.setState({
        text: task_text,
        newText: task_text,
        feedId,
        dueDate,
        newDueDate: dueDate,
        status,
        newStatus: status,
        taskId: task_id,
        completionTime: completion_time,
      });
      getUsers().then((users) => {
        this.setState({ users });
        this.setState({
          responsibility: this.getUserFullName(users, assigned_user_email),
          newResponsibility: this.getUserFullName(users, assigned_user_email),
        });
      });
    });
  };

  getUserFullName = (users, email) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === email) return users[i].user;
    }
  };

  getEmail = (name) => {
    const { users } = this.state;
    for (let i = 0; i < users.length; i++) {
      if (users[i].user === name) return users[i].email;
    }
  };

  open = () => this.setState({ showModal: true });

  close = () =>
    this.setState({
      showModal: false,
      newText: this.state.text,
      newResponsibility: this.state.responsibility,
      newDueDate: this.state.dueDate,
      newStatus: this.state.status,
    });

  handleChange = (value) => this.setState({ newText: value });

  updateTask = () => {
    const { taskId } = this.state;
    if (this.state.newText !== this.state.text) {
      patchTask(taskId, { task_text: this.state.newText });
    }
    if (this.state.responsibility !== this.state.newResponsibility) {
      const email = this.getEmail(this.state.newResponsibility);
      patchTask(taskId, { assigned_user_email: email });
    }
    if (this.state.dueDate !== this.state.newDueDate) {
      patchTask(taskId, { due_date: this.state.newDueDate });
    }
    if (this.state.status !== this.state.newStatus) {
      patchTask(taskId, { status: this.state.newStatus });
      if (this.state.newStatus === 'Completed') {
        patchTask(taskId, { completion_time: getToday() });
      }
    }

    this.close();
  };

  handleDateChange = (event) => {
    // how to check if event properly handled?
    if (event.length <= 10) {
      this.setState({ newDueDate: event });
    } else {
      this.setState({ newDueDate: event.format('YYYY-MM-DD') });
    }
  };

  getActiveUsers = () => {
    const { users, responsibility } = this.state;
    const activeUsers = users.filter(
      (u) => (u.active || u.user === responsibility) && u.user_id !== 16,
    );
    return activeUsers;
  };

  render() {
    const {
      showModal,
      statuses,
      text,
      newText,
      newStatus,
      newDueDate,
      newResponsibility,
      completionTime,
    } = this.state;
    const activeUsers = this.getActiveUsers();

    return (
      <div>
        <div>
          {text}
          <button type="button" className="btn btn-edit-note" onClick={this.open}>
            <i className="fa fa-pencil" aria-hidden="true" />
          </button>
          {!!completionTime && <p>[Completed on {displayDate(completionTime)}]</p>}
        </div>

        <div>
          <Modal
            backdrop="static"
            show={showModal}
            onHide={this.close}
            className="tv-modal tv-company-modal"
          >
            <Modal.Header>
              <Modal.Title>Add Task</Modal.Title>
              <Button className="modal-close" onClick={this.close}>
                <i className="fa fa-times" />
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>Task</p>
                <textarea
                  value={newText}
                  className="form-control"
                  placeholder="Add Task"
                  onChange={(event) => this.setState({ newText: event.target.value })}
                />
              </div>
              <br />
              <div>
                <p>Responsibility</p>
                <select
                  className="input-field"
                  value={newResponsibility}
                  onChange={(event) => this.setState({ newResponsibility: event.target.value })}
                >
                  {activeUsers.map((u) => (
                    <option key={u.user_id}>{u.user}</option>
                  ))}
                </select>
              </div>
              <br />
              <div>
                <p>Due Date</p>
                <Datetime value={newDueDate} onChange={(event) => this.handleDateChange(event)} />
              </div>
              <br />
              <div>
                <p>Status</p>
                <select
                  className="input-field"
                  value={newStatus}
                  onChange={(event) => this.setState({ newStatus: event.target.value })}
                >
                  {statuses.map((s) => (
                    <option key={s}>{s}</option>
                  ))}
                </select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.updateTask}>Submit</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default TaskDescription;

import React, { Component } from 'react';
import slugify from 'slugify';
import { getInvestorTopVerticals } from '../BackendAPI';

class InvestorVerticals extends Component {
  state = {
    verticals: [],
  };

  componentDidMount() {
    getInvestorTopVerticals(this.props.investor).then((verticals) => {
      this.setState({ verticals });
    });
  }

  renderVertical = (v) => {
    const { name, num, is_tv } = v;

    if (!is_tv)
      return (
        <span className={'vertical-item'} key={name + num}>
          {name} ({num})
        </span>
      );
    if (!name) return null;

    return (
      <span className={`vertical-item ${slugify(name).toLowerCase()}`} key={name + num}>
        {name} ({num})
      </span>
    );
  };

  render() {
    const { verticals } = this.state;

    return (
      <div className="content-container card investor-verticals">
        <div className="card-block">
          <h3>Top Verticals</h3>
          <ul className="list-unstyled list-spaced">
            {verticals.map((v) => this.renderVertical(v))}
          </ul>
        </div>
      </div>
    );
  }
}

export default InvestorVerticals;

import React, { useCallback, memo } from 'react';
import { Modal } from 'react-bootstrap';

import { Button, CloseButton, Flex } from '../primitives';
import AdvancedSearchV2FiltersBar from './AdvancedSearchV2FiltersBar';
import AdvancedSearchV2Reset from './AdvancedSearchV2Reset';

const AdvancedSearchV2FilterModal = ({ toggleModal, showModal }) => {
  const closeModal = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  return (
    <Modal
      backdrop="static"
      show={showModal}
      onHide={() => toggleModal(false)}
      className="tv-modal advanced-search"
    >
      <Modal.Header>
        <Modal.Title>Deal Feed Filter</Modal.Title>
        <CloseButton className="modal-close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <div className="search-filters" style={{ padding: '0' }}>
          <AdvancedSearchV2FiltersBar isModal />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flex fill justify="space-between">
          <AdvancedSearchV2Reset useButton />
          <Button onClick={closeModal}>Done</Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(AdvancedSearchV2FilterModal);

import history from './history';

const AUTH_URL =
  process.env.REACT_APP_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://api.thomvest.com';
export const AUTH_TOKEN_KEY = 'crmAuth';

export const login = (username, password) =>
  fetch(`${AUTH_URL}/login`, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  }).then((res) => res.json());

export const register = (email, firstName, lastName, password) =>
  fetch(`${AUTH_URL}/register`, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, firstName, lastName, password }),
  }).then((res) => res.json());

export const setLocalValue = (key, value) => localStorage.setItem(key, value);

export const getLocalValue = (key) => localStorage.getItem(key);

export const setAuthToken = (token) => {
  setLocalValue(AUTH_TOKEN_KEY, JSON.stringify(token));
};

export const getAccessToken = () => {
  const userJson = getLocalValue(AUTH_TOKEN_KEY);
  if (!userJson) return null;
  if (JSON.parse(userJson).hash === undefined) return null;
  return `Bearer ${JSON.parse(userJson).hash}`;
};

export const getUserEmail = () => {
  const userJson = getLocalValue(AUTH_TOKEN_KEY);
  if (!userJson) return null;
  if (JSON.parse(userJson).hash === undefined) return null;
  return JSON.parse(userJson).email;
};

export const getUserFirstName = () => {
  const userJson = getLocalValue(AUTH_TOKEN_KEY);
  if (!userJson) return null;
  if (JSON.parse(userJson).hash === undefined) return null;
  return JSON.parse(userJson).first_name;
};

export const isAuthenticated = () => {
  const token = getLocalValue(AUTH_TOKEN_KEY);
  if (!token) return false; // token doesn't exist
  const expireTime = JSON.parse(token).expire_time;
  const now = Date.now();
  return now < 1000 * expireTime;
};

export const logout = () => {
  delete localStorage[AUTH_TOKEN_KEY];
  history.push('/login');
};

export const forgotPassword = (email) =>
  fetch(`${AUTH_URL}/forgot_password`, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  });

export const changePassword = (token, password) =>
  fetch(`${AUTH_URL}/change_password`, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ token, password }),
  }).then((res) => res.json());

/*
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
*/

import React, { memo } from 'react';
import styled from 'styled-components';
import useIsMobile from '../../hooks/useIsMobile';
import { Flex } from '../primitives';
import WatchlistWidget from './WatchlistWidget';
import RemindersWidget from './RemindersWidget';
import SavedSearchesWidget from './SavedSearchesWidget';

const Container = styled(Flex)`
  ${({ isMobile }) =>
    isMobile &&
    `
      width: 100%;
      padding-bottom: 50px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-bottom:120px;
  `}
`;

function DashboardSidebar() {
  const isMobile = useIsMobile();
  return (
    <Container direction="column" fill isMobile={isMobile}>
      <RemindersWidget />
      <WatchlistWidget />
      <SavedSearchesWidget />
    </Container>
  );
}

export default memo(DashboardSidebar);

/* eslint-disable max-classes-per-file */
import React, { memo, Component } from 'react';
import classnames from 'classnames';
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createLinkPlugin from '@draft-js-plugins/anchor';
import { Flex } from '../primitives';
import { BoldIcon, ItalicIcon, ULIcon, OLIcon, UnderlineIcon } from './icons';
import '@draft-js-plugins/linkify/lib/plugin.css';
import '@draft-js-plugins/anchor/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import './styles.css';

const linkifyPlugin = createLinkifyPlugin({
  target: '_blank',
});
const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: { toolbarStyles: { toolbar: 'inline-toolbar' } },
});
const linkPlugin = createLinkPlugin({
  theme: {
    input: 'link-input card',
    inputInvalid: 'link-input-error',
  },
  placeholder: 'https://',
  linkTarget: '_blank',
});
const plugins = [inlineToolbarPlugin, linkPlugin, linkifyPlugin];
const { InlineToolbar } = inlineToolbarPlugin;

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    const editorState = this.props.richText
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.richText)))
      : EditorState.createEmpty();

    this.state = {
      editorState,
    };

    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.richText !== this.props.richText) {
      const editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(this.props.richText)),
      );

      this.setState({ editorState });
    }
  }

  onChange = (editorState) => {
    const newContentState = editorState.getCurrentContent();
    const plainText = newContentState.getPlainText();

    if (
      this.props.externalOnChange &&
      plainText?.length > 3 &&
      newContentState !== this.state.editorState.getCurrentContent()
    ) {
      const stringifiedContentState = JSON.stringify(convertToRaw(newContentState));
      this.props.externalOnChange(stringifiedContentState);
    }

    // if (newContentState !== this.state.editorState.getCurrentContent()) {
    this.setState({ editorState });
    // }
  };

  _handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  }

  classNames() {
    const { editorState } = this.state;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    // console.log('content state', convertToRaw(contentState));
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
    return className;
  }

  render() {
    return (
      <div className={classnames('RichEditor-root', { 'read-only': this.props.readOnly })}>
        {!this.props.readOnly && (
          <Flex fill>
            <InlineStyleControls
              editorState={this.state.editorState}
              onToggle={this.toggleInlineStyle}
            />
            <BlockStyleControls
              editorState={this.state.editorState}
              onToggle={this.toggleBlockType}
            />
          </Flex>
        )}
        <div className={this.classNames()} onClick={this.focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            placeholder="Start a note..."
            ref={(element) => (this.editor = element)}
            spellCheck
            autoCorrect
            readOnly={this.props.readOnly}
            plugins={plugins}
          />
          {!this.props.readOnly && (
            <InlineToolbar>
              {(externalProps) => (
                <linkPlugin.LinkButton
                  {...externalProps}
                  theme={{
                    buttonWrapper: 'button-wrapper',
                    button: 'link-button',
                  }}
                  ownTheme={{
                    input: 'link-input card',
                    inputInvalid: 'link-input-error',
                  }}
                />
              )}
            </InlineToolbar>
          )}
        </div>
      </div>
    );
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  getIcon = (label) => {
    switch (label) {
      case 'OL':
        return <ULIcon />;
      case 'UL':
        return <OLIcon />;
      case 'Underline':
        return <UnderlineIcon />;
      case 'Italic':
        return <ItalicIcon />;
      case 'Bold':
        return <BoldIcon />;
      default:
        return null;
    }
  };

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.getIcon(this.props.label)}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  // { label: 'H1', style: 'header-one' },
  // { label: 'H2', style: 'header-two' },
  // { label: 'H3', style: 'header-three' },
  // { label: 'H4', style: 'header-four' },
  // { label: 'H5', style: 'header-five' },
  // { label: 'H6', style: 'header-six' },
  // { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  // { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  // { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

// function findLinkEntities(contentBlock, callback) {
//   contentBlock.findEntityRanges((character) => {
//     const entityKey = character.getEntity();
//     return entityKey !== null && Entity.get(entityKey).getType() === 'LINK';
//   }, callback);
// }

// const Link = (props) => {
//   const { url } = Entity.get(props.entityKey).getData();
//   return <a href={url}>{props.children}</a>;
// };

export default memo(RichTextEditor);

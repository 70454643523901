import React from 'react';
import styled from 'styled-components';
import ul from './ul.png';

const Span = styled.div`
  margin-top: 2px;
  margin-left: 2px;
  position: absolute;
`;

const Icon = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${ul});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ULIcon = () => {
  return (
    <Span>
      <Icon />
    </Span>
  );
};

export default ULIcon;

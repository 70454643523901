import React, { useEffect } from 'react';
import { putUserMemo, deleteUserMemo } from '../BackendAPI';

export default function UserMemo({ userMemos, permalink, userId }) {
  const [memo, setMemo] = React.useState('');

  useEffect(() => {
    if (!memo) {
      setMemo(userMemos.find((entry) => entry.permalink === permalink)?.memo);
    }
  }, [userMemos, memo, permalink]);

  function handleChange(event) {
    setMemo(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!userId) {
      return;
    }
    if (!memo) {
      deleteUserMemo(userId, permalink);
      return;
    }
    putUserMemo(userId, permalink, memo);
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        style={{ border: 'none' }}
        type="text"
        value={memo || ''}
        placeholder="Add Memo..."
        onChange={handleChange}
      />
    </form>
  );
}

/* eslint-disable no-lonely-if */
import React, { useMemo, memo, useState, useCallback } from 'react';
import FeedFilter from '../FeedFilter';
import CompanyFeedItem from './CompanyFeedItem';
import { isRecent } from '../../util';
import { Card, Flex, Loader, Button } from '../primitives';

const DISPLAY_INCREMENTS = 25;

export function CompanyFeedLoadingFallback() {
  return (
    <Card justify="center" align="center" fill className="page-loader">
      <Loader />
    </Card>
  );
}

const CompanyFeed = ({ feed, refreshFeed, company, feedError }) => {
  const [numDisplay, setNumDisplay] = useState(DISPLAY_INCREMENTS);
  const [activeFilters, setActiveFilters] = useState(['All']);

  const increment = () => setNumDisplay((i) => i + DISPLAY_INCREMENTS);
  const sanitizedFeed = useMemo(() => {
    if (!feed) return null;

    const companyFeed = feed
      .sort((a, b) => new Date(b.feed_time).getTime() - new Date(a.feed_time).getTime())
      .filter((f) => f.type !== 'list');

    companyFeed.forEach((v) => {
      v.isRecent = isRecent(v.feed_time);
    });

    if (activeFilters === 'All') return companyFeed.slice(0, numDisplay);

    const lowerCaseFilters = activeFilters.map((a) => a.toLowerCase());

    return companyFeed
      .filter((f) => {
        if (activeFilters.includes('All')) return true;
        return lowerCaseFilters.includes(f.type.toLowerCase()); // Adjusted to handle array
      })
      .slice(0, numDisplay);
  }, [activeFilters, feed, numDisplay]);

  const handleFeedTypeChange = useCallback(
    (filter) => {
      if (filter === 'All') {
        setActiveFilters(['All']);
      } else {
        let newFilters = activeFilters;

        if (activeFilters.includes(filter)) {
          newFilters = activeFilters.filter((item) => item !== filter);
        } else {
          const filtersSet = new Set([...activeFilters, filter].filter((a) => a !== 'All'));
          newFilters = Array.from(filtersSet);
        }

        setActiveFilters(newFilters.length === 0 ? ['All'] : newFilters);
      }
    },
    [activeFilters],
  );

  if (feed === null || !company) {
    return <CompanyFeedLoadingFallback />;
  }

  if (feedError) {
    return (
      <Card width="100%" align="center" justify="center" direction="column">
        <p>Unable to fetch company feed</p>
        <Flex marginTop="20">
          <Button onClick={refreshFeed}>Try again</Button>
        </Flex>
      </Card>
    );
  }

  if (feed && feed?.length === 0) {
    return (
      <Card width="100%" align="center" justify="center" direction="column">
        <p>Add company activity to see the company feed</p>
      </Card>
    );
  }

  return (
    <div className="company-feed">
      <FeedFilter
        withFeedTypeFilter
        handleFeedTypeChange={handleFeedTypeChange}
        borderRadius={false}
        boxShadow={false}
        padding="12px 24px"
        margin="0"
      />

      <div className="company-panel-feed">
        <div className="list-table">
          <div className="list-header">
            <span className="list-type-col list-col-header">Type</span>
            <span className="list-update-col list-col-header">Update</span>
            <span className="list-date-col list-col-header">Date</span>
          </div>
          {sanitizedFeed?.length === 0 && !activeFilters.includes('All') ? (
            <Flex width="100%" align="center" justify="center" direction="column" marginTop="18">
              <p>{`There are no ${activeFilters.join(', ')} feed items`}</p>
            </Flex>
          ) : (
            sanitizedFeed?.map((f) => (
              <CompanyFeedItem key={f.key} item={f} isCompany refreshFeed={refreshFeed} />
            ))
          )}
        </div>
      </div>

      {numDisplay < sanitizedFeed?.length && (
        <Flex fill justify="center" marginTop="20">
          <Button onClick={increment} rounded>
            See More
          </Button>
        </Flex>
      )}
    </div>
  );
};

export default memo(CompanyFeed);

import React, { useEffect, memo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getCompanyInvestors, getCompanyHarmonicInvestors } from '../../BackendAPI';
import { Flex, Loader } from '../primitives';
import { removeInvestorDuplicates } from '../../util';

function TierText({ investors }) {
  return (
    <p>
      {investors.map((i, index) => {
        if (i.investor_permalink) {
          return (
            <span key={i.investor_permalink}>
              <Link to={`/investor/${i.investor_permalink}`}>{i.investor_name}</Link>
              {index < investors.length - 1 ? `, ` : ''}
            </span>
          );
        }

        return index < investors.length - 1 ? `${i.investor_name}, ` : i.investor_name;
      })}
    </p>
  );
}

export function combineAndRemoveDuplicates(arr1 = [], arr2 = []) {
  const combinedArray = [...(arr1 ?? []), ...(arr2 ?? [])];
  return removeInvestorDuplicates(combinedArray);
}

function CompanyKeyInvestors({ company, investorScore }) {
  const [investors, setInvestors] = useState([]);
  const [error, setError] = useState(false);

  const fetchInvestors = useCallback(async () => {
    try {
      const data = await getCompanyInvestors(company);
      const harmonicInvestors = await getCompanyHarmonicInvestors(company);
      const keyInvestors = combineAndRemoveDuplicates(data, harmonicInvestors);

      if (keyInvestors) {
        setInvestors(keyInvestors);
      } else {
        setInvestors(null);
        setError(true);
      }
    } catch (error) {
      setInvestors(null);
      setError(true);
    }
  }, [company]);

  useEffect(() => {
    fetchInvestors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error || !investors) {
    return (
      <Flex className="content-container card" fill>
        <div className="card-block">
          <h3>Key Investors</h3>
          <Flex direction="column">
            <label>No Data</label>
          </Flex>
        </div>
      </Flex>
    );
  }

  if (investors.length === 0) {
    return (
      <Flex align="center" justify="center" fill height="300px">
        <Loader />
      </Flex>
    );
  }

  const tier1 = investors.filter((i) => i.tier === 1);
  const tier2 = investors.filter((i) => i.tier === 2);
  const tier3 = investors.filter((i) => i.tier === 3);
  const tierNA = investors.filter((i) => i.tier === null);

  return (
    <Flex className="content-container card" fill>
      <div className="card-block">
        <h3>Key Investors</h3>
        <Flex className="company-score" justify="space-between">
          <label>Investor Score</label>
          <span className="score">{!investorScore ? '-' : `${Number(investorScore)}/5`}</span>
        </Flex>
        {tier1?.length > 0 && (
          <Flex direction="column">
            <label>Tier 1</label>
            <div>
              <TierText investors={tier1} />
            </div>
          </Flex>
        )}
        {tier2?.length > 0 && (
          <Flex direction="column" marginTop="20">
            <label>Tier 2</label>
            <div>
              <TierText investors={tier2} />
            </div>
          </Flex>
        )}
        {tier3?.length > 0 && (
          <Flex direction="column" marginTop="20">
            <label>Tier 3</label>
            <div>
              <TierText investors={tier3} />
            </div>
          </Flex>
        )}
        {tierNA?.length > 0 && (
          <Flex direction="column" marginTop="20">
            <label>Unrated</label>
            <div>
              <TierText investors={tierNA} />
            </div>
          </Flex>
        )}
      </div>
    </Flex>
  );
}

export default memo(CompanyKeyInvestors);

import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { updateInvestorTier } from '../BackendAPI';

class InvestorTier extends Component {
  state = {
    showModal: false,
    investorTier: null,
    selectedTier: null,
    tiers: [
      { tier: 1, checked: false },
      { tier: 2, checked: false },
      { tier: 3, checked: false },
    ],
  };

  componentDidUpdate(prevProps) {
    const { tier } = this.props;
    if (tier === prevProps.tier) return;

    this.setState({ investorTier: tier, selectedTier: tier });
    if (!tier) return;

    const { tiers } = this.state;
    tiers[tier - 1].checked = true;
    this.setState({ tiers });
  }

  updateTiers = (t) => {
    const { tiers } = this.state;
    for (let i = 0; i < tiers.length; i++) tiers[i].checked = false;
    if (t !== null) tiers[t - 1].checked = true;
    this.setState({ tiers });
  };

  closeTierModal = () => {
    this.updateTiers(this.state.investorTier);
    this.setState({ showModal: false });
  };

  openTierModal = () => this.setState({ showModal: true });

  submitTierModal = () => {
    const { selectedTier } = this.state;
    if (selectedTier !== this.state.investorTier) {
      updateInvestorTier(this.props.investor, selectedTier);
      this.setState({ investorTier: selectedTier });
    }

    this.updateTiers(selectedTier);
    this.setState({ showModal: false });
  };

  tierChange = (event) => {
    const tier = parseInt(event.target.value, 10);
    this.updateTiers(tier);
    this.setState({ selectedTier: tier });
  };

  renderTier = (t) => {
    if (t === null) return <span className="score none">-</span>;
    return <span className={`score tier${t}`}>T{t}</span>;
  };

  render() {
    const { investorTier, tiers } = this.state;

    return (
      <div>
        <div className="company-score">
          <button type="button" onClick={this.openTierModal}>
            <label>Investor Score</label>
            {this.renderTier(investorTier)}
          </button>
        </div>

        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.closeTierModal}
          className="tv-modal tv-company-modal"
        >
          <Modal.Header>
            <Modal.Title>Investor Score</Modal.Title>
            <Button className="modal-close" onClick={this.closeTierModal}>
              <i className="fa fa-times" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-unstyled list-spaced">
              {tiers.map((t) => (
                <li key={t.tier} className="form-checkbox-row">
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={t.checked}
                        value={t.tier}
                        onChange={this.tierChange}
                      />{' '}
                      Tier {t.tier}
                      <i className="fa fa-circle-thin unchecked" aria-hidden="true" />
                      <i className="fa fa-check-circle checked" aria-hidden="true" />
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.submitTierModal}>Submit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default InvestorTier;

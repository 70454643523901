import React from 'react';

// export interface ErrorBoundaryProps {
//   children: ReactNode;
//   errorClass?: string;
//   onCatch?: (error: any, info?: any) => void;
//   skipErrorLogging?: boolean;
//   fallback?: ReactNode;
// }

// interface State {
//   error: any | null;
// }

// export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
export default class ErrorBoundary extends React.Component {
  static defaultProps = {
    onCatch: () => {},
  };

  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  componentDidCatch(error, info) {
    const { onCatch } = this.props;
    if (onCatch) onCatch(error, info);
  }

  onReset = () => {
    this.setState({
      error: null,
    });
  };

  render() {
    const { error } = this.state;
    const { children, fallback: Fallback } = this.props;

    if (!error) return children;

    if (error && !!Fallback) {
      // @ts-expect-error
      return <Fallback error={error} onReset={this.onReset} />;
    }

    return null;
  }
}

import { useState, useEffect } from 'react';
import useOnce from '../useOnce';
import getMatchMedia from './getMatchMedia';
import registerMediaWatchers from './registerMediaWatchers';

const registerWatcher = registerMediaWatchers();

interface Params {
  query: string;
  initialValue: boolean | undefined;
}

const useIsMatchingMediaQuery = ({ query, initialValue }: Params) => {
  const initialMatch = useOnce(() => getMatchMedia(query)?.matches || false);
  const initialState = useOnce(() => (initialValue === undefined ? initialMatch : initialValue));

  const [isMatching, setIsMatching] = useState(initialState);

  useEffect(() => {
    /*
     * We update here from the initialValue so we can gracefully
     * hydrate from SSR and then switch to desktop or mobile
     * if needed without breaking the entire DOM tree.
     */
    setIsMatching(initialMatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return registerWatcher(query, setIsMatching);
  }, [query]);

  return isMatching;
};

export default useIsMatchingMediaQuery;

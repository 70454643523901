import React, { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { roundArrow, sticky } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';

import CompanyHovercard from './company/CompanyHovercard';
import '../styles/components/HoverLink.css';

const StyledLink = styled(Link)`
  font-weight: 700;
`;

const LazyTippy = React.forwardRef((props, ref) => {
  const [mounted, setMounted] = useState(false);

  const lazyPlugin = {
    fn: () => ({
      onTrigger: () => setMounted(true),
    }),
  };

  const computedProps = { ...props };

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

  if (props.render) {
    computedProps.render = (...args) => (mounted ? props.render(...args) : '');
  } else {
    computedProps.content = mounted ? props.content : '';
  }

  return <Tippy {...computedProps} ref={ref} />;
});

function HoverLink(props) {
  const { orgType, permalink, companyName, target = '_blank' } = props;

  if (orgType !== 'company') {
    return (
      <StyledLink target={target} to={`/${orgType}/${permalink}`}>
        {companyName}
      </StyledLink>
    );
  }

  return (
    <LazyTippy
      appendTo={() => document.body}
      arrow={roundArrow + roundArrow}
      content={<CompanyHovercard permalink={permalink} name={companyName} />}
      delay={[200, 300]}
      duration={0}
      interactive
      placement="right"
      plugins={[sticky]}
      sticky
      theme="basic"
    >
      <StyledLink target={target} to={`/${orgType}/${permalink}`}>
        {companyName}
      </StyledLink>
    </LazyTippy>
  );
}

export default memo(HoverLink);

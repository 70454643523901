import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CompanyVerticalContext } from '../contexts/CompanyVerticalContext';
import { addCompanyVertical, removeCompanyVertical } from '../BackendAPI';

export default function VerticalModal(props) {
  const [state, dispatch] = useContext(CompanyVerticalContext);
  const { verticals, permalink } = props;

  const updateVerticals = (verticals, verticalId) => {
    for (let i = 0; i < verticals.length; i += 1) {
      if (verticals[i].id === verticalId) {
        verticals[i].checked = !verticals[i].checked;
        if (verticals[i].checked) {
          addCompanyVertical(permalink, verticals[i]);
        } else {
          removeCompanyVertical(permalink, verticals[i]);
        }
        dispatch({ type: 'UPDATE_VERTICALS', value: verticals });
      }
    }
  };

  const goToSubverticals = () => {
    dispatch({ type: 'TOGGLE_VERTICAL_MODAL', value: false });
    dispatch({ type: 'TOGGLE_SUBVERTICAL_MODAL', value: true });
  };

  const totalChecked = verticals?.filter((a) => a.checked);

  return (
    <div>
      <Modal backdrop="static" show={state.verticalModal} className="tv-modal tv-company-modal">
        <Modal.Header>
          <Modal.Title>Verticals</Modal.Title>
          <Button
            className="modal-close"
            onClick={() => dispatch({ type: 'TOGGLE_VERTICAL_MODAL', value: false })}
          >
            <i className="fa fa-times" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled list-spaced">
            {verticals.map((v) => (
              <li key={v.vertical}>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={() => updateVerticals(verticals, v.id)}
                      checked={v.checked}
                      value={v.id}
                    />
                    {v.vertical}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => dispatch({ type: 'TOGGLE_VERTICAL_MODAL', value: false })}>
            Close
          </Button>
          {totalChecked.length > 0 && (
            <Button onClick={() => goToSubverticals()}>Update Sub-Vertical</Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import React, { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { InvestorSearchContext } from '../../contexts/InvestorSearchContext';
import PageHeader from '../PageHeader';
import { Button } from '../primitives';

const locationParams = ['city', 'region', 'country'];

const buildRouteQueryParams = (state) => {
  const { searchFilters } = state;
  const { company, investor } = searchFilters;
  const params = [];

  for (const [key, value] of Object.entries(investor)) {
    if (value.length === 0) continue;
    const valStr = value?.map((x) => String(x).replace(' ', '_'))?.join(',') ?? '';
    params.push(`${key}=${valStr}`);
  }

  for (const [key, value] of Object.entries(company)) {
    if (key === 'location') {
      for (const [locationKey, locationValue] of Object.entries(value)) {
        if (locationValue && locationValue !== '' && !locationValue.includes('All')) {
          params.push(`${locationKey}=${locationValue}`);
        }
      }
      continue;
    }

    if (value.length === 0 || locationParams.includes(key)) continue;

    const valStr = value?.map((x) => String(x).replace(' ', '_'))?.join(',') ?? '';
    params.push(`${key}=${valStr}`);
  }

  return params.join('&');
};

export default function AdvancedSearchHeader() {
  // const [state] = useContext(InvestorSearchContext);
  // const params = buildRouteQueryParams(state);
  // window.history.replaceState({}, '', `advanced_search?${params}`);

  return (
    <PageHeader
      title="Advanced Search"
      button={
        <CopyToClipboard text={`${window.location.origin}/advanced_search`}>
          <Button className="prospect-stats-btn">Share Search Link</Button>
        </CopyToClipboard>
      }
    />
  );
}

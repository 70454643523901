import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import '../../styles/react-datetime.css';
import styled from 'styled-components';
import { capitalize } from '../../util';
import {
  ProspectScoreV2Context,
  hasActiveProspectScoreV2,
  featureAccessEmails,
} from '../../contexts/ProspectScoreV2Context';
import { ProspectContext, getActiveValue } from '../../contexts/ProspectContext';
import { Card, Flex } from '../primitives';
import useIsMobile from '../../hooks/useIsMobile';
import { getUserEmail } from '../../utils/Auth';

const formatDateStr = (dt) => {
  if (!dt) return 'N/A';
  const monthList = dt.slice(0, 10).split('-');
  return `${monthList[1]}/${monthList[2]}/${monthList[0].slice(2, 4)}`;
};

const StyledFlex = styled(Flex)`
  .prospect-data-container {
    &:first-child {
      width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
      ${({ isMobile }) => isMobile && `margin-bottom: 20px;`}
    }
    &:last-child {
      width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
    }
  }
  ul {
    margin-bottom: 0;
    li {
      label {
        margin: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ${({ isMobile }) =>
    isMobile &&
    `
    label {
      white-space: nowrap;
    }
  `}
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  background: #fff;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .grid {
    border-radius: 4px;
    display: grid;
    border: 1px solid #e0e0e0;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    background-color: #e0e0e0;
  }
  .grid.left-section .item,
  .grid.right-section .item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px 20px;
    justify-content: center;
  }
  .grid .item:first-child {
    border-top-left-radius: 4px;
  }
  .grid .item:nth-child(2) {
    border-top-right-radius: 4px;
  }
  .grid .item:nth-last-child(2) {
    border-bottom-left-radius: 4px;
  }
  .grid .item:last-child {
    border-bottom-right-radius: 4px;
  }
  .grid.right-section .score-header {
    grid-column: span 2;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 1.1em;
    background: white;
    border-radius: 4px 4px 0 0;
    height: 74px;
  }
  .label {
    color: #898989;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    text-align: justify;
    padding: 0 0 6px 0;
  }
  span.value {
    font-size: 11px;
    margin-top: -4px;
  }
`;

const getQuartile = (rating) => {
  switch (rating) {
    case 1:
      return '1st Quartile';
    case 2:
      return '2nd Quartile';
    case 3:
      return '3rd Quartile';
    case 4:
      return '4th Quartile';
    default:
      return '-';
  }
};

const getQuartileLabel = (rating) => {
  switch (rating) {
    case 1:
      return '(Bottom 25%)';
    case 2:
      return '(25-50%)';
    case 3:
      return '(50-75%)';
    case 4:
      return '(Top 25%)';
    default:
      return '-';
  }
};

const formatCompanyStatus = (coStatus, onWatchlist, verticalPriority) => {
  if (!coStatus) return '-';

  if (coStatus) {
    if (coStatus === 'Active') return 'Prospect';
    return capitalize(coStatus);
  }

  if (verticalPriority) return 'Vertical Priority';

  if (onWatchlist) {
    return 'Watchlist';
  }

  return '-';
};

export default function CompanyProspect({
  prospectScoreV1,
  prospectScoreV2,
  onWatchlist,
  isVerticalPriority,
}) {
  const email = getUserEmail();
  const [prospectContextState, dispatch] = useContext(ProspectContext);
  const [scoresV2State] = useContext(ProspectScoreV2Context);
  const isMobile = useIsMobile();
  const { owner, stage, source, status, initialContactDate, prospectId, sourceType, awareTv } =
    prospectContextState;
  const activeStatus = getActiveValue(status.options);
  const isActive = useMemo(() => !!prospectId, [prospectId]);
  const stageValue = getActiveValue(stage.options);

  const prospectScoresV2State =
    stageValue === 'Seed'
      ? scoresV2State.filter((a) => a.category !== 'Financial Metrics')
      : scoresV2State;

  const hasProspectScoresV2Access = useMemo(() => featureAccessEmails.includes(email), [email]);
  const hasProspectScoresV2 = hasActiveProspectScoreV2(prospectScoresV2State);

  const teamScore = scoresV2State.find((a) => a.category === 'Team');
  const techScore = scoresV2State.find((a) => a.category === 'Product or Tech Differentiation');
  const compScore = scoresV2State.find((a) => a.category === 'Competition');
  const finScore = scoresV2State.find((a) => a.category === 'Financial Metrics');
  const marketScore = scoresV2State.find((a) => a.category === 'Market Size Potential');
  const investorScore = scoresV2State.find((a) => a.category === 'Investor Syndicate');

  if (isActive) {
    return (
      <Card className="card prospects-block" marginBottom="20" fill>
        <div className="card-block">
          <h3>Prospect Details</h3>

          {hasProspectScoresV2 && hasProspectScoresV2Access ? (
            <StyledFlex>
              <GridContainer>
                <div className="grid left-section">
                  <div className="item">
                    <p className="label">Owner</p>
                    <p className="value">{getActiveValue(owner.options)}</p>
                  </div>
                  <div className="item">
                    <p className="label">Contact Date</p>
                    <p className="value">{formatDateStr(initialContactDate.value)}</p>
                  </div>
                  <div className="item">
                    <p className="label">Stage</p>
                    <p className="value">{getActiveValue(stage.options)}</p>
                  </div>
                  <div className="item">
                    <p className="label">Source</p>
                    <p className="value">{getActiveValue(source.options)}</p>
                  </div>
                  <div className="item">
                    <p className="label">Source Type</p>
                    <p className="value">{getActiveValue(sourceType.options)}</p>
                  </div>
                  <div className="item">
                    <p className="label">Status</p>
                    <p
                      className={classnames({
                        green: activeStatus === 'Active' || activeStatus === 'Prospect',
                        orange: activeStatus === 'Dormant',
                        red: activeStatus === 'Passed',
                        blue: activeStatus === 'Portfolio',
                      })}
                    >
                      {formatCompanyStatus(activeStatus, onWatchlist, isVerticalPriority)}
                    </p>
                  </div>
                  <div className="item">
                    <p className="label">TV Awareness</p>
                    <p className="value">{getActiveValue(awareTv.options)}</p>
                  </div>
                  <div className="item">
                    <p className="label">Score (Old)</p>
                    {Number(prospectScoreV1) > 0 ? (
                      <p
                        className={classnames({
                          orange: Number(prospectScoreV1) > 4 && Number(prospectScoreV1) < 7,
                          red: Number(prospectScoreV1) <= 4,
                          green: Number(prospectScoreV1) >= 7,
                        })}
                      >
                        {`${prospectScoreV1}/10`}
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>

                <div className="grid right-section">
                  <div className="score-header">
                    <span>Prospect Score (New)</span>
                    {Number(prospectScoreV2) > 0 ? (
                      <p
                        className={classnames({
                          orange: Number(prospectScoreV2) > 4 && Number(prospectScoreV2) < 7,
                          red: Number(prospectScoreV2) <= 4,
                          green: Number(prospectScoreV2) >= 7,
                        })}
                      >
                        {`${prospectScoreV2}`}
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>

                  <div className="item">
                    <span className="label">Team</span>
                    <Flex direction="column">
                      <p className="value">{getQuartile(teamScore.rating)}</p>
                      <span className="value">{getQuartileLabel(teamScore.rating)}</span>
                    </Flex>
                  </div>
                  <div className="item">
                    <span className="label">Competition</span>
                    <Flex direction="column">
                      <p className="value">{getQuartile(compScore.rating)}</p>
                      <span className="value">{getQuartileLabel(compScore.rating)}</span>
                    </Flex>
                  </div>
                  <div className="item">
                    <span className="label">Technology</span>
                    <Flex direction="column">
                      <p className="value">{getQuartile(techScore.rating)}</p>
                      <span className="value">{getQuartileLabel(techScore.rating)}</span>
                    </Flex>
                  </div>
                  <div className="item">
                    <span className="label">Market Size</span>
                    <Flex direction="column">
                      <p className="value">{getQuartile(marketScore.rating)}</p>
                      <span className="value">{getQuartileLabel(marketScore.rating)}</span>
                    </Flex>
                  </div>
                  <div className="item">
                    <span className="label">Syndicate</span>
                    <Flex direction="column">
                      <p className="value">{getQuartile(investorScore.rating)}</p>
                      <span className="value">{getQuartileLabel(investorScore.rating)}</span>
                    </Flex>
                  </div>
                  <div className="item">
                    <span className="label">Financials</span>
                    <Flex direction="column">
                      <p className="value">{getQuartile(finScore.rating)}</p>
                      <span className="value">{getQuartileLabel(finScore.rating)}</span>
                    </Flex>
                  </div>
                </div>
              </GridContainer>
            </StyledFlex>
          ) : (
            <StyledFlex isMobile={isMobile} direction={isMobile ? 'column' : 'row'}>
              <Flex className="prospect-data-container">
                <Flex direction="column" width="50%">
                  <ul className="list-unstyled list-spaced">
                    <li>
                      <label>Owner</label>
                      <p>{getActiveValue(owner.options)}</p>
                    </li>
                    <li>
                      <label>Stage</label>
                      <p>{getActiveValue(stage.options)}</p>
                    </li>
                  </ul>
                </Flex>
                <Flex direction="column">
                  <ul className="list-unstyled list-spaced">
                    <li>
                      <label>Contact Date</label>
                      <p>{formatDateStr(initialContactDate.value)}</p>
                    </li>
                    <li>
                      <label>Source</label>
                      <p>{getActiveValue(source.options)}</p>
                    </li>
                  </ul>
                </Flex>
              </Flex>

              <Flex className="prospect-data-container">
                <Flex direction="column" width="50%">
                  <ul className="list-unstyled list-spaced">
                    <li>
                      <label>{isMobile ? 'Type' : 'Source Type'}</label>
                      <p>{getActiveValue(sourceType.options)}</p>
                    </li>
                    <li>
                      <label>TV Awareness</label>
                      <p>{getActiveValue(awareTv.options)}</p>
                    </li>
                  </ul>
                </Flex>
                <Flex direction="column">
                  <ul className="list-unstyled list-spaced">
                    <li>
                      <label>Status</label>
                      <p
                        className={classnames({
                          green: activeStatus === 'Active' || activeStatus === 'Prospect',
                          orange: activeStatus === 'Dormant',
                          red: activeStatus === 'Passed',
                          blue: activeStatus === 'Portfolio',
                        })}
                      >
                        {formatCompanyStatus(activeStatus, onWatchlist, isVerticalPriority)}
                      </p>
                    </li>
                    <li>
                      <label>Company Score</label>
                      {Number(prospectScoreV1) > 0 ? (
                        <p
                          className={classnames({
                            orange: Number(prospectScoreV1) >= 5 && Number(prospectScoreV1) <= 6,
                            red: Number(prospectScoreV1) <= 4,
                            green: Number(prospectScoreV1) >= 7,
                          })}
                        >
                          {`${prospectScoreV1}/10`}
                        </p>
                      ) : (
                        <p>-</p>
                      )}
                    </li>
                  </ul>
                </Flex>
              </Flex>
            </StyledFlex>
          )}

          <button
            type="button"
            className="tv-button"
            onClick={() => {
              dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: true } });
            }}
          >
            Edit
          </button>
        </div>
      </Card>
    );
  }

  return null;
}

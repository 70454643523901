/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useCallback, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ProspectContext } from '../../contexts/ProspectContext';
import { CompanyVerticalContext } from '../../contexts/CompanyVerticalContext';
import ProspectScore from './ProspectScore';
import { ProspectScoreV2Context, featureAccessEmails } from '../../contexts/ProspectScoreV2Context';
import { STAGE_TO_TYPE_MAP } from '../../constants';
import {
  createNewProspect,
  createNewProspectScores,
  patchCompany,
  createNewFeedItem,
  createNewProspectScoresV2,
} from '../../BackendAPI';
import { getUserFirstName, getUserEmail } from '../../utils/Auth';

const getActiveValue = (list) => {
  return list.find((l) => l.active)?.value;
};

export default function ProspectScoresModal({ refreshFeed, withVerticals }) {
  const email = getUserEmail();
  const [state, dispatch] = useContext(ProspectContext);
  const [scoresV2State] = useContext(ProspectScoreV2Context);
  const [, verticalDispatch] = useContext(CompanyVerticalContext);
  const stageValue = getActiveValue(state.stage.options);
  const stageType = STAGE_TO_TYPE_MAP[stageValue];
  const prospectScoresV1 = state.prospectScores.filter((s) => s.stage_type_id === stageType);
  const active = !!state.prospectId;

  const prospectScoresV2State =
    stageValue === 'Seed'
      ? scoresV2State.filter((a) => a.category !== 'Financial Metrics')
      : scoresV2State;

  const createProspect = useCallback(async () => {
    const { permalink, owner, stage, source, sourceType, awareTv, initialContactDate } = state;

    const newProspect = await createNewProspect({
      permalink,
      owner: getActiveValue(owner.options),
      initial_contact_date: initialContactDate.value,
      stage: getActiveValue(stage.options),
      stage_type_id: stageType,
      source: getActiveValue(source.options),
      source_type: getActiveValue(sourceType.options),
      aware_tv: getActiveValue(awareTv.options),
    });

    const prospectId = newProspect.id;
    dispatch({ type: 'ADD_PROSPECT_ID', payload: { prospectId } });

    // PROSPECT SCORE v1
    await createNewProspectScores({ prospect_id: prospectId, prospect_scores: prospectScoresV1 });

    const totalScoreV1 = prospectScoresV1.map((s) => s.value).reduce((a, b) => a + b, 0);
    await patchCompany(permalink, { tv_company_score: totalScoreV1 });

    // PROSPECT SCORE v2
    await createNewProspectScoresV2({
      prospect_id: prospectId,
      prospect_scores: scoresV2State,
    });
    const totalScoreV2 = scoresV2State.map((s) => s.score).reduce((a, b) => a + b, 0);
    await patchCompany(state?.permalink, { prospect_score_v2: totalScoreV2 });

    // Other
    await patchCompany(permalink, { major_status: 'Active' });

    const firstName = getUserFirstName();
    const header = `updated to Prospect by ${firstName}`;

    await createNewFeedItem({
      permalink,
      type: 'status',
      header,
      custom: `{"status": "Prospect", "user": "${firstName}"}`,
    });

    if (refreshFeed) {
      await refreshFeed();
    }
  }, [state, stageType, dispatch, prospectScoresV1, scoresV2State, refreshFeed]);

  const openVerticalModal = useCallback(async () => {
    if (active) {
      const totalScoreV1 = prospectScoresV1.map((s) => s.value).reduce((a, b) => a + b, 0);
      await patchCompany(state.permalink, { tv_company_score: totalScoreV1 });

      const totalScoreV2 = prospectScoresV2State.map((s) => s.score).reduce((a, b) => a + b, 0);
      await patchCompany(state.permalink, { prospect_score_v2: totalScoreV2 });
    } else {
      await createProspect();
    }

    dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } });
    if (withVerticals) {
      verticalDispatch({ type: 'TOGGLE_VERTICAL_MODAL', value: true });
    }
  }, [
    active,
    dispatch,
    verticalDispatch,
    prospectScoresV1,
    state.permalink,
    prospectScoresV2State,
    createProspect,
    withVerticals,
  ]);

  const verticalButtonCopy = useMemo(() => {
    if (!withVerticals) {
      return 'Submit';
    }
    if (active) {
      return state.isEditing ? 'Edit Verticals' : 'Update Verticals';
    }
    return 'Create Prospect';
  }, [state.isEditing, active, withVerticals]);

  const hasProspectScoresV2Access = useMemo(() => featureAccessEmails.includes(email), [email]);

  return (
    <div>
      <Modal
        backdrop="static"
        show={state.modal === 2}
        onHide={() => dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })}
        className="tv-modal tv-company-modal"
      >
        <Modal.Header>
          <Modal.Title>Scoring: {stageType === 1 ? 'Early' : 'Late'} Stage</Modal.Title>
          <Button
            className="modal-close"
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })
            }
          >
            <i className="fa fa-times" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled list-spaced">
            {prospectScoresV1.map((s) => (
              <ProspectScore score={s} key={s.score_id} stageType={stageType} />
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: state.isEditing } })
            }
          >
            Go Back
          </Button>
          {hasProspectScoresV2Access ? (
            <Button
              onClick={() =>
                dispatch({ type: 'SET_MODAL', payload: { modal: 3, isEditing: state.isEditing } })
              }
            >
              Next: {active ? 'Update' : 'Add'} Scores (New)
            </Button>
          ) : (
            <>
              {state?.isEditing ? (
                <Button
                  onClick={() =>
                    dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })
                  }
                >
                  Submit
                </Button>
              ) : (
                <Button onClick={() => openVerticalModal()}>{verticalButtonCopy}</Button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import React from 'react';

const ExpandIcon = (props) => {
  return (
    <svg
      {...props}
      viewBox={props.viewBox ?? '0 0 24 24'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={1.5}
      stroke={props.color || '#b2b2b2'}
      fill="#ffffff"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
      />
    </svg>
  );
};

export default ExpandIcon;

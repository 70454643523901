import React, { useContext, useMemo, memo } from 'react';
import styled from 'styled-components';
import { AdvancedSearchV2Context } from './AdvancedSearchV2Context';

const Label = styled.div`
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  column-gap: 20px; /* This adds a gap between the columns */
  row-gap: 10px;
`;

const InlineLabel = ({ filter, toggle }) => {
  const labelText = filter.label.toLowerCase();
  const isVertical = ['ft', 're', 'mt', 'ci', 'cs'].includes(labelText);

  const labelClasses = useMemo(() => {
    let labelClass = 'cs';
    if (isVertical) {
      labelClass = filter.checked ? labelText : 'empty';
    } else {
      labelClass = filter.checked ? 'ft' : 'empty';
    }
    return labelClass;
  }, [filter?.checked, isVertical, labelText]);

  return (
    <Label
      className={`vertical-item ${labelClasses}`}
      key={`tier${filter.label}`}
      onClick={() => toggle(filter.value)}
    >
      {filter.label}
    </Label>
  );
};

const AdvancedSearchV2VerticalFilter = () => {
  const [state, dispatch] = useContext(AdvancedSearchV2Context);
  const toggle = (value) => dispatch({ filterList: 'verticals', value });

  return (
    <div className="company-score vertical-expertise">
      <Grid>
        {state.verticals.map((filter, index) => (
          <InlineLabel key={index} filter={filter} toggle={toggle} />
        ))}
      </Grid>
    </div>
  );
};

export default memo(AdvancedSearchV2VerticalFilter);

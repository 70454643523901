/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  capitalize,
  displayInvestmentType,
  sanitizeAndCombineVerticals,
  removeDuplicatesFromString,
} from '../../util';
import {
  getCompanySummary,
  getUserWatchlistCompanies,
  getHarmonicVerticals,
} from '../../BackendAPI';
import { ScrollContainer } from '../ListPanel';
import '../../styles/components/CompanyHovercard.css';
import CompanyVerticalPriority from './CompanyVerticalPriority';
import CompanyUserWatchlist from './CompanyUserWatchlist';
import captureError from '../../utils/sentry';
import { Pill, Flex, Loader } from '../primitives';
import { getUser } from '../../utils/Auth2';
import { CompanySocialIcons } from './CompanySocialIcons';

// CompanyName
// Website(Hyperlink) - LinkedIn(Hyperlink) - Crunchbase(Hyperlink)
// Description
// Location // Employees
// Last Round // VC Score
// Key Investors
// Primary Verticals
// Status
// Actions

const StyledP = styled.p`
  margin-top: 12px;
`;

const StyledUl = styled.ul`
  &.list-spaced {
    li {
      margin-bottom: 10px;
    }
  }
  .hovercard-grid {
    li {
      margin-bottom: 0;
    }
  }
`;

const formatVertical = (vertical) => vertical.toLowerCase().replace(/ /gi, '-').replace(/\//gi, '');

const CompanyHovercard = ({ permalink, name }) => {
  const [summary, setSummary] = useState(null);
  const [verticals, setVerticals] = useState(null);
  const [onWatchlist, setOnWatchlist] = useState(false);
  const [isVerticalPriority, setIsVerticalPriority] = useState(false);
  const user = getUser();

  useEffect(() => {
    const initiateData = async () => {
      try {
        const companySummary = await getCompanySummary(permalink);
        if (companySummary) {
          setSummary(companySummary);
          setIsVerticalPriority(companySummary.vertical_priority === true);

          const harmonicVerticals = await getHarmonicVerticals(permalink);
          const harmonicVerts =
            harmonicVerticals?.length > 0
              ? harmonicVerticals.map((v) => v.tv_vertical).join(',')
              : '';

          const sanitizedVerticals = sanitizeAndCombineVerticals({
            harmonic_verticals: harmonicVerts,
            tv_vertical: companySummary.tv_vertical,
          });

          if (sanitizedVerticals?.length > 0) {
            setVerticals(sanitizedVerticals);
          } else {
            setVerticals(false);
          }
        }
      } catch (err) {
        captureError(err);
      }

      try {
        const watchlistData = await getUserWatchlistCompanies(user.user_id);
        const isOnWatchlist = watchlistData?.find((w) => w.permalink === permalink);
        setOnWatchlist(Boolean(isOnWatchlist));
      } catch (err) {
        captureError(err);
      }
    };

    initiateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyStatus = useMemo(() => {
    const status = summary?.major_status;
    if (status) {
      if (status === 'Active') return 'Prospect';
      return capitalize(status);
    }
    if (isVerticalPriority) return 'Vertical Priority';
    if (onWatchlist) return 'Watchlist';
    return undefined;
  }, [summary, onWatchlist, isVerticalPriority]);

  if (summary) {
    return (
      <div className="content-container card companyHovercard__container">
        <div className="card-block company-info">
          <h2>{name}</h2>
          <StyledUl className="list-unstyled list-spaced">
            {summary && (
              <li>
                <CompanySocialIcons summary={summary} />
              </li>
            )}

            <li>
              <label>Description</label>
              <p>{summary.short_description}</p>
            </li>

            <ul className="list-unstyled list-spaced hovercard-grid">
              <li>
                <label>Location</label>
                <p>{summary.location && summary.location !== 'N/A' ? summary.location : '-'}</p>
              </li>
              <li>
                <label>Last Round</label>
                <p>
                  {summary.last_investment_type
                    ? displayInvestmentType(summary.last_investment_type)
                    : '-'}
                </p>
              </li>
              <li>
                <label>VC Score</label>
                <p>{summary.tv_company_investor_score ? summary.tv_company_investor_score : '-'}</p>
              </li>
              <li>
                {companyStatus ? (
                  <Flex direction="column">
                    <label>Status</label>
                    <Pill value={companyStatus} marginTop="5" />
                  </Flex>
                ) : (
                  <>
                    <label>Status</label>
                    <p>-</p>
                  </>
                )}
              </li>
            </ul>

            <li>
              <label>Key Investors</label>
              {summary?.key_investors?.length ? (
                <ScrollContainer>
                  <p>
                    {removeDuplicatesFromString(
                      Array.isArray(summary.key_investors)
                        ? summary.key_investors.join(', ')
                        : summary.key_investors,
                    )}
                  </p>
                </ScrollContainer>
              ) : (
                <p>-</p>
              )}
            </li>

            <li>
              <label>Primary Verticals</label>
              {verticals?.length > 0 ? (
                <p>
                  {verticals?.map((vertical) => (
                    <span className={`vertical-item ${formatVertical(vertical)}`} key={vertical}>
                      {vertical}
                    </span>
                  ))}
                </p>
              ) : (
                <>
                  {verticals === null ? (
                    <StyledP>
                      <Loader height="10" width="30" />
                    </StyledP>
                  ) : (
                    <p>-</p>
                  )}
                </>
              )}
            </li>

            <li>
              <label>Subverticals</label>

              {summary?.subverticals?.length > 0 ? (
                <p>
                  {summary.subverticals?.map((sv) => (
                    <span className={`vertical-item ${formatVertical(sv)}`} key={sv}>
                      {sv}
                    </span>
                  ))}
                </p>
              ) : (
                <p>-</p>
              )}
            </li>

            <li>
              <label>Actions</label>
              <ul className="list-unstyled list-unspaced hovercard-actions">
                <li>
                  <CompanyUserWatchlist company={permalink} onWatchlist={onWatchlist} />
                </li>
                <li>
                  <CompanyVerticalPriority
                    company={permalink}
                    companyStatus={summary?.major_status}
                    isVerticalPriority={isVerticalPriority}
                  />
                </li>
              </ul>
            </li>
          </StyledUl>
        </div>
      </div>
    );
  }

  return null;
};

export default CompanyHovercard;

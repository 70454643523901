import getMatchMedia from './getMatchMedia';

type Listener = (x: boolean) => void;

/*
 * This allows to attach multiple listeners to a single
 * window.matchMedia instance so we do not need the browser
 * to track several different global CSS events listeners
 */
// @ts-expect-error
const matchMediaWatcher = (query) => {
  let listeners = [] as Listener[];

  const watcher = getMatchMedia(query);

  // @ts-expect-error
  const watcherListener = ({ matches }) => {
    listeners.forEach((listener) => listener(matches));
  };

  // @ts-expect-error
  const addListener = (listener) => {
    if (!watcher) return;
    listeners.push(listener);
    if (listeners.length === 1) {
      watcher.addListener(watcherListener);
    }
  };

  // @ts-expect-error
  const removeListener = (listener) => {
    if (!watcher) return;
    listeners = listeners.filter((x) => x !== listener);
    if (listeners.length === 0) {
      watcher.removeListener(watcherListener);
    }
  };

  const hasListeners = () => listeners.length > 0;

  return {
    addListener,
    removeListener,
    hasListeners,
  };
};

export default matchMediaWatcher;

import React, { useContext, useState, useMemo } from 'react';
import Datetime from 'react-datetime';
import { ProspectContext } from '../../contexts/ProspectContext';
import { patchProspect } from '../../BackendAPI';

export default function ProspectDate() {
  const [state, dispatch] = useContext(ProspectContext);
  const [hasChanged, setChanged] = useState(false);

  const updateDate = (event) => {
    const value = event.format('YYYY-MM-DD');
    const payload = { value };

    setChanged(true);
    dispatch({ type: 'UPDATE_DATE', payload });

    const { prospectId } = state;

    if (prospectId) {
      patchProspect(prospectId, { initial_contact_date: value });
    }
  };

  const initialValue = useMemo(() => {
    if (!state.isEditing && !hasChanged) return '-';
    return state.initialContactDate.value;
  }, [state?.isEditing, hasChanged, state.initialContactDate.value]);

  return (
    <div className="modal-row">
      <label>Initial Contact Date</label>
      <Datetime
        value={initialValue}
        onChange={(event) => updateDate(event)}
        timeFormat={false}
        closeOnSelect
      />
    </div>
  );
}

export const prospectScoresV2 = [
  {
    category: 'Team',
    description:
      "Consider the founder/team's prior exits, industry experience, fundraising ability, and reputation.",
    scores: [
      {
        rating: 4,
        seedScore: 4,
        lateScore: 3.5,
      },
      {
        rating: 3,
        seedScore: 2.7,
        lateScore: 2.3,
      },
      {
        rating: 2,
        seedScore: 1.3,
        lateScore: 1.2,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Market Size Potential',
    description: 'Consider the total market size, sales addressable market and growth rate.',
    scores: [
      {
        rating: 4,
        seedScore: 2.5,
        lateScore: 2,
      },
      {
        rating: 3,
        seedScore: 1.7,
        lateScore: 1.3,
      },
      {
        rating: 2,
        seedScore: 0.8,
        lateScore: 0.7,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Product or Tech Differentiation',
    description:
      'Consider the long-term defensibility of the product that provides a clear, accumulating advantage.',
    scores: [
      {
        rating: 4,
        seedScore: 1.5,
        lateScore: 1.5,
      },
      {
        rating: 3,
        seedScore: 1,
        lateScore: 1,
      },
      {
        rating: 2,
        seedScore: 0.5,
        lateScore: 0.5,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Competition',
    description:
      "Is the competitive environment conducive for startup(s) to enter and succeed? Consider barriers to entry, customer satisfaction, incumbents' ability to innovate, and cost of capital.",
    scores: [
      {
        rating: 4,
        seedScore: 1,
        lateScore: 1,
      },
      {
        rating: 3,
        seedScore: 0.7,
        lateScore: 0.7,
      },
      {
        rating: 2,
        seedScore: 0.3,
        lateScore: 0.3,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Investor Syndicate',
    description:
      'Assess the quality of investors (ideally board members), focusing on their expertise and influence on the company’s success.',
    scores: [
      {
        rating: 4,
        seedScore: 1,
        lateScore: 1,
      },
      {
        rating: 3,
        seedScore: 0.7,
        lateScore: 0.7,
      },
      {
        rating: 2,
        seedScore: 0.3,
        lateScore: 0.3,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
  {
    category: 'Financial Metrics',
    description:
      'Compare the company’s revenue and growth rate against industry benchmarks. Also consider gross margin, CAC, and burn.',
    scores: [
      {
        rating: 4,
        seedScore: 0,
        lateScore: 1,
      },
      {
        rating: 3,
        seedScore: 0,
        lateScore: 0.7,
      },
      {
        rating: 2,
        seedScore: 0,
        lateScore: 0.3,
      },
      {
        rating: 1,
        seedScore: 0,
        lateScore: 0,
      },
    ],
  },
];

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRoute({ component: Component, hash, path }) {
  // if auth response is empty, no user session exists, redirect to login screen
  if (!hash) {
    return <Route render={() => <Redirect to="/login" />} />;
  }

  if (path === '/') {
    return <Route path="/" exact component={Component} />;
  }

  if (path === '/company/:permalink') {
    return (
      <Route path={path} render={(props) => <Component company={props.match.params.permalink} />} />
    );
  }

  if (path === '/list/:listId') {
    return (
      <Route path={path} render={(props) => <Component listId={props.match.params.listId} />} />
    );
  }

  if (path === '/investor/:permalink') {
    return (
      <Route
        path={path}
        render={(props) => <Component investor={props.match.params.permalink} />}
      />
    );
  }

  return <Route path={path} component={Component} />;
}

export default ProtectedRoute;

import React, { useState, useEffect, memo, useCallback, useMemo, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { CompanyVerticalContextProvider } from '../contexts/CompanyVerticalContext';
import { ProspectContextProvider } from '../contexts/ProspectContext';
import {
  ProspectScoreV2ContextProvider,
  featureAccessEmails,
} from '../contexts/ProspectScoreV2Context';
import {
  getCompanySummary,
  getCompanyFeed,
  getCompanyProspects,
  getUsers,
  getUserWatchlistCompanies,
  getProspectScores,
  getProspectScoresV2,
} from '../BackendAPI';
import ProspectModals from '../components/prospect/ProspectModals';
import CompanySummary from '../components/company/CompanySummary';
import CompanyProspect from '../components/company/CompanyProspect';
import CompanyDocuments from '../components/company/CompanyDocuments';
import CompanyVerticals from '../components/company/CompanyVerticals';
import CompanyKeyInvestors from '../components/company/CompanyKeyInvestors';
import CompanyFeed, { CompanyFeedLoadingFallback } from '../components/company/CompanyFeed';
import CompanyFinancials from '../components/company/CompanyFinancials';
import AddTask from '../components/AddTask';
import AddNote from '../components/AddNote';
import ProspectButton from '../components/prospect/ProspectButton';
import CompanyVerticalPriority from '../components/company/CompanyVerticalPriority';
import CompanyUserWatchlist from '../components/company/CompanyUserWatchlist';
import CompanyHighlights from '../components/company/CompanyHighlights';
import { getUserId } from '../utils/Auth2';
import { getUserEmail } from '../utils/Auth';
import { Flex, LayoutHeader, Loader, Button, Card } from '../components/primitives';
import Boundary from '../components/Boundary';
import '../styles/components/Company.css';
import captureError from '../utils/sentry';
import useIsMobile from '../hooks/useIsMobile';
import CompanyTractionSignals from '../components/company/CompanyTractionSignals';
import { removeHyphenAndCapitalize } from '../util';

const isEmpty = (obj) => Object.keys(obj).length === 0;

const CompanyErrorFallback = ({ fetchAllCompanyData }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <Helmet>
        <title>Company - TV CRM</title>
      </Helmet>
      <LayoutHeader className="company-header">
        <h2>Company</h2>
      </LayoutHeader>
      {isMobile ? (
        <div className="company-error-fallback-mobile">
          <Card
            width="100%"
            align="center"
            className="sidebar-container"
            justify="center"
            direction="column"
          >
            <h3>There was an issue loading this company</h3>
            {!!fetchAllCompanyData && (
              <Flex marginTop="20">
                <Button onClick={fetchAllCompanyData}>Try again</Button>
              </Flex>
            )}
            <Flex marginTop="20">
              <p>If the issue persists please reach out to IT</p>
            </Flex>
          </Card>
        </div>
      ) : (
        <div className="container company-profile">
          <div className="row scroll-container">
            <div className="left-sidebar col-md-3 overview">
              <Card className="sidebar-container loading" align="center" justify="center" />
            </div>
            <div className="col-md-6 feed">
              <Card
                width="100%"
                align="center"
                className="sidebar-container"
                justify="center"
                direction="column"
              >
                <h3>There was an issue loading this company</h3>
                {!!fetchAllCompanyData && (
                  <Flex marginTop="20">
                    <Button onClick={fetchAllCompanyData}>Try again</Button>
                  </Flex>
                )}
                <Flex marginTop="20">
                  <p>If the issue persists please reach out to IT</p>
                </Flex>
              </Card>
            </div>
            <div className="right-sidebar col-md-3">
              <Card className="sidebar-container loading" align="center" justify="center" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function CompanyContent({ company }) {
  const isMobile = useIsMobile();
  const [summary, setSummary] = useState(null);
  const [companySummaryError, setCompanySummaryError] = useState(false);
  const [isProspect, setIsProspect] = useState(false);
  const [prospectInfo, setProspectInfo] = useState(null);
  const [prospectScores, setProspectScores] = useState(null);
  const [prospectScoresV2, setProspectScoresV2] = useState(null);
  const [isVerticalPriority, setIsVerticalPriority] = useState(false);
  const [onWatchlist, setOnWatchlist] = useState(false);
  const [users, setUsers] = useState([]);
  const [feed, setFeed] = useState(null);
  const [feedError, setFeedError] = useState(false);

  const [mobileNav, setMobileNav] = useState('overview');
  const [mobileNavActive, setMobileNavActive] = useState(false);

  const email = getUserEmail();
  const hasProspectScoresV2Access = useMemo(() => featureAccessEmails.includes(email), [email]);

  const updateSummary = useCallback(async () => {
    setCompanySummaryError(false);
    try {
      const companySummary = await getCompanySummary(company);
      if (!companySummary || isEmpty(companySummary)) {
        setCompanySummaryError(true);
      } else {
        setSummary(companySummary);
        setIsVerticalPriority(companySummary.vertical_priority === true);
      }
    } catch (err) {
      setCompanySummaryError(true);
      captureError(err);
    }
  }, [company]);

  const updateProspects = useCallback(async () => {
    try {
      const data = await getCompanyProspects(company);
      const prospectData = data[0];
      setIsProspect(prospectData?.status === 'Active');

      if (prospectData) {
        setProspectInfo(prospectData);
        const prospectScoresData = await getProspectScores(prospectData.id);
        setProspectScores(prospectScoresData);
        if (hasProspectScoresV2Access) {
          const prospectScoresV2Data = await getProspectScoresV2(prospectData.id);
          setProspectScoresV2(prospectScoresV2Data);
        }
      }
    } catch (err) {
      captureError(err);
    }
  }, [company, hasProspectScoresV2Access]);

  const updateOnWatchlist = useCallback(async () => {
    try {
      const userId = await getUserId();

      const data = await getUserWatchlistCompanies(userId);

      if (data) {
        const isOnWatchlist = data.find((w) => w.permalink === company) !== undefined;
        setOnWatchlist(isOnWatchlist);
      }
    } catch (err) {
      captureError(err);
    }
  }, [company]);

  const getAndSetCompanyFeed = useCallback(async () => {
    setFeedError(false);
    try {
      const companyFeed = await getCompanyFeed(company);
      setFeed(companyFeed ?? []);
    } catch (err) {
      setFeedError(true);
    }
  }, [company]);

  const fetchAllCompanyData = useCallback(async () => {
    if (users.length === 0) {
      const allUsers = await getUsers();
      setUsers(allUsers);
    }

    updateSummary();
    updateProspects();
    getAndSetCompanyFeed();
    updateOnWatchlist();
  }, [getAndSetCompanyFeed, updateOnWatchlist, updateProspects, updateSummary, users.length]);

  useEffect(() => {
    fetchAllCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyHeader = useMemo(() => {
    if (isMobile) {
      return (
        <Flex direction="column" fill>
          <Flex justify="space-between" align="center" marginBottom="12" fill>
            <h2>{summary?.company_name ?? 'Company'}</h2>
            {summary && (
              <Flex>
                <AddNote
                  permalink={company}
                  company={summary.company_name}
                  onSuccess={getAndSetCompanyFeed}
                />

                <Flex marginLeft="12">
                  <AddTask company={summary} />
                </Flex>
              </Flex>
            )}
          </Flex>
          <Flex justify={isMobile ? 'space-between' : 'flex-end'} fill>
            <ProspectButton />

            {isVerticalPriority !== undefined && (
              <CompanyVerticalPriority
                company={company}
                companyStatus={summary?.major_status}
                isVerticalPriority={isVerticalPriority}
              />
            )}
            {onWatchlist !== undefined && (
              <CompanyUserWatchlist company={company} onWatchlist={onWatchlist} />
            )}
          </Flex>
        </Flex>
      );
    }

    return (
      <>
        <h2>{summary?.company_name ?? 'Company'}</h2>
        {summary && (
          <div className="company-actions">
            <ul>
              <li>
                <AddNote
                  permalink={company}
                  company={summary.company_name}
                  onSuccess={getAndSetCompanyFeed}
                />
              </li>
              <li>
                <AddTask company={summary} />
              </li>
              <li>
                <ProspectButton />
              </li>

              {isVerticalPriority !== undefined && (
                <li>
                  <CompanyVerticalPriority
                    company={company}
                    companyStatus={summary?.major_status}
                    isVerticalPriority={isVerticalPriority}
                  />
                </li>
              )}
              {onWatchlist !== undefined && (
                <li>
                  <CompanyUserWatchlist company={company} onWatchlist={onWatchlist} />
                </li>
              )}
            </ul>
          </div>
        )}
      </>
    );
  }, [company, getAndSetCompanyFeed, isMobile, isVerticalPriority, onWatchlist, summary]);

  if (companySummaryError) {
    return <CompanyErrorFallback fetchAllCompanyData={fetchAllCompanyData} />;
  }

  return (
    <ProspectContextProvider
      permalink={company}
      companySummary={summary}
      users={users}
      prospectInfo={prospectInfo}
      prospectScores={prospectScores}
    >
      <ProspectScoreV2ContextProvider prospectScores={prospectScoresV2}>
        <Helmet>
          <title>{summary?.company_name ?? removeHyphenAndCapitalize(company)} - TV CRM</title>
        </Helmet>

        <LayoutHeader className="company-header">{companyHeader}</LayoutHeader>

        <div className="container company-profile">
          <div className="mobile-profile-nav">
            <ul>
              <li className={mobileNav === 'overview' ? 'active' : ''}>
                <button
                  type="button"
                  onClick={() => {
                    setMobileNav('overview');
                    setMobileNavActive(true);
                  }}
                >
                  Overview
                </button>
              </li>
              <li className={mobileNav === 'feed' ? 'active' : ''}>
                <button
                  type="button"
                  onClick={() => {
                    setMobileNav('feed');
                    setMobileNavActive(true);
                  }}
                >
                  Feed
                </button>
              </li>
              <li className={mobileNav === 'signals' ? 'active' : ''}>
                <button
                  type="button"
                  onClick={() => {
                    setMobileNav('signals');
                    setMobileNavActive(true);
                  }}
                >
                  Signals
                </button>
              </li>
            </ul>
          </div>
          <div className="row scroll-container">
            <div
              className={`left-sidebar col-md-3${
                mobileNavActive && mobileNav !== 'overview' ? ' hidden' : ''
              }`}
            >
              {summary && company ? (
                <Card className="sidebar-container" direction="column" padding="0">
                  <CompanySummary
                    summary={summary}
                    refreshCompanySummary={() => {
                      updateSummary();
                      updateProspects();
                    }}
                  />
                  <CompanyVerticals permalink={company} />
                  <CompanyDocuments company={company} />
                </Card>
              ) : (
                <Card className="sidebar-container loading" align="center" justify="center">
                  <Loader />
                </Card>
              )}
            </div>
            <div
              className={`col-md-6${mobileNavActive && mobileNav === 'feed' ? '' : ' mobile-feed'}`}
            >
              <CompanyProspect
                prospectScoreV1={summary?.tv_company_score}
                prospectScoreV2={parseFloat(summary?.prospect_score_v2 ?? '0')}
                onWatchlist={onWatchlist}
                isVerticalPriority={isVerticalPriority}
              />

              {summary?.major_status?.toLowerCase() === 'portfolio' && (
                <CompanyFinancials company={company} />
              )}

              <div>
                <CompanyFeed
                  company={company}
                  feed={feed}
                  isActiveProspect={isProspect}
                  refreshFeed={getAndSetCompanyFeed}
                  feedError={feedError}
                />
              </div>
            </div>

            <div
              className={`right-sidebar col-md-3${mobileNav !== 'signals' ? ' hidden-mobile' : ''}`}
            >
              {summary && company ? (
                <Card className="sidebar-container" direction="column" padding="0" fill>
                  <CompanyKeyInvestors
                    company={company}
                    investorScore={summary?.tv_company_investor_score}
                  />
                  {/* <CompanySignals company={company} /> */}
                  <CompanyTractionSignals permalink={company} />
                  {summary?.cb_url && <CompanyHighlights crunchbaseUrl={summary.cb_url} />}

                  {/* name && <CompanyLists company={company} companyName={name} /> */}
                </Card>
              ) : (
                <Card className="sidebar-container loading" align="center" justify="center">
                  <Loader />
                </Card>
              )}
            </div>
          </div>
        </div>
        <ProspectModals refreshFeed={fetchAllCompanyData} />
      </ProspectScoreV2ContextProvider>
    </ProspectContextProvider>
  );
}

const Company = (props) => {
  return (
    <div>
      <CompanyVerticalContextProvider>
        <Boundary
          loadingFallback={<CompanyFeedLoadingFallback />}
          fallback={<CompanyErrorFallback />}
        >
          <CompanyContent {...props} />
        </Boundary>
      </CompanyVerticalContextProvider>
    </div>
  );
};

export default memo(Company);

import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  align-items: ${({ align = 'flex-start' }) => align};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  ${({ fill }) => fill && 'width: 100%;'}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px;`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px;`}
`;

export default Flex;

import React, { useState, memo, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { CloseButton, Button, Flex } from './primitives';
import { createChatCompletion } from '../BackendAPI';

function OutreachTemplate({ founder, annotation, summary }) {
  const [showModal, setShowModal] = useState(false);
  const [template, setTemplate] = useState('');

  function handleOpen() {
    setShowModal(true);
  }

  function handleClose() {
    setShowModal(false);
  }

  useEffect(() => {
    if (showModal && founder && summary.company_name && summary.short_description) {
      const prompt = `* You are an investor at Thomvest Ventures, a top venture capital firm focused on Fintech, Proptech, Cybersecurity, and Cloud Infrastructure
* You identified ${summary.company_name} as a promising potential investment and you’d like to reach out to the founder of the company for a conversation
* The company’s description is {${summary.short_description}}
* The founder’s name is ${founder}
* Please draft a brief email to the founder introducing yourself and your firm. Describe why you are interested in connecting with the founder, specifically how the company fits within your firm’s strategy.
* Be detailed and specific when describing your thesis in the space.
* Don't use unnecessary words.
* The call to action should be around scheduling a call or meeting.
* Limit your email to 80 words or less.
`;
      createChatCompletion(prompt).then((response) => {
        setTemplate(response);
      });
    }
  }, [showModal, founder, summary]);

  return (
    <>
      <a
        onClick={annotation.email ? handleOpen : null}
        style={{ cursor: annotation.email ? 'pointer' : 'default' }}
        href={annotation.email ? `mailto:${annotation.email}` : null}
      >
        {founder}
        {annotation ? ` (${annotation.title})` : null}
      </a>
      <Modal backdrop="static" show={showModal} onHide={handleClose} className="tv-modal">
        <Modal.Header>
          <Modal.Title>Outreach Template for {summary?.company_name}</Modal.Title>
          <CloseButton className="modal-close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div style={{ whiteSpace: 'pre-wrap' }}>{template}</div>
        </Modal.Body>
        <Modal.Footer>
          <Flex align="center" justify="center" fill>
            <Button onClick={handleClose}>{'Done'}</Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(OutreachTemplate);

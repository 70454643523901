import React, { createContext, useReducer, useEffect } from 'react';

import { prospectScores } from '../utils/prospectScores';

export const ProspectContext = createContext();

export const getActiveValue = (list) => {
  const item = list.find((l) => l.active);
  if (!item) return '-';
  if (item.value === 'Active') return 'Prospect';
  return item.value;
};

prospectScores.forEach((p) => {
  p.value = 0;
});

const createInitialState = () => ({
  modal: null,
  isEditing: false,
  prospectId: null,
  owner: {
    label: 'Owner',
    dbColumn: 'owner',
    options: [{ value: '-', active: true, user_id: 0 }],
  },
  stage: {
    label: 'Stage',
    dbColumn: 'stage',
    options: [
      { value: '-', active: true },
      { value: 'Seed', active: false },
      { value: 'Series A', active: false },
      { value: 'Series B', active: false },
      { value: 'Series C', active: false },
      { value: 'Series D', active: false },
      { value: 'Series E', active: false },
    ],
  },
  source: {
    label: 'Source',
    dbColumn: 'source',
    options: [
      { value: '-', active: true },
      { value: 'VC', active: false },
      { value: 'Entrepreneur (External to Prospect)', active: false },
      { value: 'Industry Contact/Banker', active: false },
      { value: 'Conference/Research', active: false },
      { value: 'Direct', active: false },
      { value: 'Other', active: false },
    ],
  },
  sourceType: {
    label: 'Source Type',
    dbColumn: 'source_type',
    options: [
      { value: '-', active: true },
      { value: 'Inbound', active: false },
      { value: 'Outbound', active: false },
    ],
  },
  awareTv: {
    dbColumn: 'aware_tv',
    label: 'Thomvest Awareness',
    subLabel: 'Was the company aware of Thomvest prior to meeting with you',
    options: [
      { value: '-', active: true },
      { value: 'Yes', active: false },
      { value: 'No', active: false },
    ],
  },
  status: {
    label: 'Status',
    dbColumn: 'status',
    options: [
      { value: '-', active: true },
      { value: 'Active', active: false },
      { value: 'Dormant', active: false },
      { value: 'Passed', active: false },
      { value: 'Portfolio', active: false },
    ],
  },
  initialContactDate: {
    dbColumn: 'initial_contact_date',
    label: 'Initial Contact Date',
    value: null,
  },
  prospectScores: [...prospectScores], // Ensure deep copy of prospectScores
});

const updateProspect = (state, payload) => {
  const { id, owner, initial_contact_date, stage, source, source_type, aware_tv, status } = payload;
  state.prospectId = id;
  if (initial_contact_date) state.initialContactDate.value = initial_contact_date?.slice(0, 10);
  updateOptions(state, 'owner', owner);
  updateOptions(state, 'stage', stage);
  updateOptions(state, 'source', source);
  updateOptions(state, 'sourceType', source_type);
  updateOptions(state, 'awareTv', aware_tv);
  updateOptions(state, 'status', status);
  return state;
};

const updateOptions = (state, key, value) => {
  const values = state[key].options;
  values.forEach((o) => (o.active = o.value === value));
};

const makeOwnerOptions = (users, prospectInfo) => {
  const noneOption = [{ user_id: 0, value: '-', active: false }];
  const userOptions = users
    .map((u) => {
      return { user_id: u.user_id, value: u.user, active: false };
    })
    .sort((a, b) => a.value?.localeCompare(b.value));

  const owner = prospectInfo ? prospectInfo.owner : '-';
  const allOptions = noneOption.concat(userOptions);
  allOptions.forEach((o) => (o.active = o.value === owner));
  return allOptions;
};

const reducer = (state, action) => {
  const newState = { ...state };

  switch (action.type) {
    case 'UPDATE_PROPERTY':
      const { key, value } = action.payload;
      const values = newState[key].options;
      values.forEach((o) => (o.active = o.value === value));
      newState[key].options = values;
      return newState;
    case 'UPDATE_DATE':
      newState.initialContactDate.value = action.payload.value;
      return newState;
    case 'SET_MODAL':
      newState.modal = action.payload.modal;
      newState.isEditing = action.payload.isEditing;
      return newState;
    case 'UPDATE_SCORE':
      const scores = newState.prospectScores;
      const { scoreId, stageType } = action.payload;
      const scoreToUpdate = scores.find(
        (s) => s.score_id === scoreId && s.stage_type_id === stageType,
      );
      scoreToUpdate.value = action.payload.value;
      newState.prospectScores = scores;
      return newState;
    case 'ADD_PROSPECT_ID':
      newState.prospectId = action.payload.prospectId;
      return newState;
    case 'UPDATE_PROSPECT_INFO':
      const { payload } = action;
      if (!payload) return newState;
      return updateProspect(newState, action.payload);
    case 'UPDATE_PROSPECT_SCORES':
      const newScores = action.payload.prospectScores;
      newScores.forEach((s) => (s.stage_type_id = action.payload.stageTypeId));

      newState.prospectScores = newScores;
      return newState;
    case 'UPDATE_STATUS':
      if (['Active', 'Dormant', 'Passed', 'Portfolio'].includes(action.payload)) {
        const newStatuses = newState.status.options.map((option) => ({
          ...option,
          active: Boolean(option.value === action.payload),
        }));

        newState.status.options = newStatuses;
      }

      return newState;
    case 'UPDATE_OWNER_OPTIONS':
      newState.owner.options = action.payload;
      return newState;
    case 'RESET_PROSPECT_CONTEXT':
      return createInitialState();
    default:
      return newState;
  }
};

export const ProspectContextProvider = ({
  prospectInfo,
  prospectScores,
  permalink,
  companySummary,
  users,
  children,
}) => {
  useEffect(() => {
    if (prospectInfo) dispatch({ type: 'UPDATE_PROSPECT_INFO', payload: prospectInfo });
    if (users) {
      dispatch({
        type: 'UPDATE_OWNER_OPTIONS',
        payload: makeOwnerOptions(users, prospectInfo),
      });
    }
    if (prospectScores !== null && prospectInfo !== null) {
      dispatch({
        type: 'UPDATE_PROSPECT_SCORES',
        payload: { prospectScores, stageTypeId: prospectInfo.stage_type_id },
      });
    }
  }, [users, prospectInfo, prospectScores, companySummary?.permalink]);

  const initialState = createInitialState();
  initialState.permalink = permalink;
  initialState.companySummary = companySummary;
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ProspectContext.Provider value={[state, dispatch]}>{children}</ProspectContext.Provider>;
};

import matchMediaWatcher from './matchMediaWatcher';

/*
 * This allows to attach one mediaWatcher instance per CSS
 * query, so we can have a single event listener for each.
 */

const registerMediaWatchers = () => {
  const watchers = {};

  // @ts-expect-error
  const register = (query, listener) => {
    // @ts-expect-error
    if (!watchers[query]) {
      // @ts-expect-error
      watchers[query] = matchMediaWatcher(query);
    }

    // @ts-expect-error
    const watcher = watchers[query];
    watcher.addListener(listener);

    const unregister = () => {
      watcher.removeListener(listener);
      if (!watcher.hasListeners()) {
        // @ts-expect-error
        delete watchers[query];
      }
    };

    return unregister;
  };

  return register;
};

export default registerMediaWatchers;

import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  Line,
  Bar,
} from 'recharts';
import { getSheetsData } from '../../BackendAPI';
import '../../styles/components/CompanyFinancials.css';
import captureError from '../../utils/sentry';

export const COMPANY_FINANCIALS_SPREADSHEET_ID = '13o_KTqMn6jScfgufByBf2RVF1YBOufQzHaprr5XWZDY';
export const COMPANY_FINANCIALS_RANGES = [
  'crmActualData',
  'crmActualMetric',
  'crmActualPermalink',
  'crmActualQuarter',
  'crmActualYear',
  'crmProjectionData',
  'crmProjectionDate',
  'crmProjectionMetric',
  'crmProjectionPermalink',
  'crmProjectionQuarter',
  'crmProjectionYear',
];
const PRIMARY_COLOR = '#FF7700';
const SECONDARY_COLOR = '#81B29A';
const BRUSH_COLOR = '#3581B8';

function cleanColumn(column) {
  return column
    .map((row) => (row.length ? row : '')) // replace empty rows
    .flat() // flatten column
    .map((s) => s.trim()); // remove whitespace
}

function cleanTimeRow(row) {
  return row.flat().map((s) => parseInt(s.trim()));
}

function cleanRevenueRow(row) {
  return row.map((_) => parseFloat(_.trim().replace('$', '').replaceAll(',', '')));
}

function formatDate(date) {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

export function parseRevenueData(permalink, rawData) {
  const actualData = rawData.crmActualData;
  const projectionData = rawData.crmProjectionData;
  const actualMetric = cleanColumn(rawData.crmActualMetric);
  const actualPermalink = cleanColumn(rawData.crmActualPermalink);
  const projectionDate = cleanColumn(rawData.crmProjectionDate);
  const projectionMetric = cleanColumn(rawData.crmProjectionMetric);
  const projectionPermalink = cleanColumn(rawData.crmProjectionPermalink);
  const actualQuarter = cleanTimeRow(rawData.crmActualQuarter);
  const actualYear = cleanTimeRow(rawData.crmActualYear);
  const projectionQuarter = cleanTimeRow(rawData.crmProjectionQuarter);
  const projectionYear = cleanTimeRow(rawData.crmProjectionYear);

  let actualRevenueData;
  for (let i = 0; i < actualPermalink.length; i++) {
    if (actualPermalink[i] === permalink && actualMetric[i] === 'Revenue') {
      actualRevenueData = cleanRevenueRow(actualData[i]);
      break;
    }
  }

  const projectionRevenueData = [];
  for (let i = 0; i < projectionPermalink.length; i++) {
    if (projectionPermalink[i] === permalink && projectionMetric[i] === 'Revenue') {
      projectionRevenueData.push({
        pDate: new Date(projectionDate[i]),
        pData: cleanRevenueRow(projectionData[i]),
      });
    }
  }
  projectionRevenueData.sort((a, b) => a.pDate - b.pDate);

  const parsedData = {};
  for (let i = 0; i < actualYear.length; i++) {
    const year = actualYear[i];
    const quarter = actualQuarter[i];
    const period = `${year}${quarter}`;
    const entry = parsedData.hasOwnProperty(period)
      ? parsedData[period]
      : { period, year, quarter };
    const revenueValue = actualRevenueData[i];
    if (!Number.isNaN(revenueValue)) {
      entry.hasData = true;
      entry.actualRevenue = revenueValue;
    }
    parsedData[period] = entry;
  }

  const planNames = [];
  for (let i = 0; i < projectionRevenueData.length; i++) {
    for (let j = 0; j < projectionYear.length; j++) {
      const year = projectionYear[j];
      const quarter = projectionQuarter[j];
      const period = `${year}${quarter}`;
      const entry = parsedData.hasOwnProperty(period)
        ? parsedData[period]
        : { period, year, quarter };
      const revenueValue = projectionRevenueData[i].pData[j];
      if (!Number.isNaN(revenueValue)) {
        entry.hasData = true;
        entry[`p${i}Revenue`] = revenueValue;
      }
      parsedData[period] = entry;
    }
    planNames.push(formatDate(projectionRevenueData[i].pDate));
  }

  const parsedValues = Object.values(parsedData)
    .filter((entry) => entry.hasData)
    .sort((entryA, entryB) => entryA.period.localeCompare(entryB.period));

  return {
    values: parsedValues,
    planNames,
  };
}

export function annualizeActualData(quarterlyData) {
  let annualData = {};
  for (const entry of quarterlyData) {
    if (!entry.hasOwnProperty('actualRevenue')) {
      continue;
    }
    const { year } = entry;

    if (!annualData.hasOwnProperty(year)) {
      annualData[year] = {
        period: year,
        actualRevenue: 0,
        includedQuarters: 0,
      };
    }
    annualData[year].actualRevenue += entry.actualRevenue;
    annualData[year].includedQuarters += 1;
  }

  annualData = Object.values(annualData);
  annualData = annualData.filter((year) => year.includedQuarters === 4);

  for (let i = 1; i < annualData.length; i += 1) {
    const prevYear = annualData[i - 1];
    const currYear = annualData[i];
    currYear.growth =
      prevYear.actualRevenue === 0
        ? NaN
        : ((currYear.actualRevenue - prevYear.actualRevenue) / prevYear.actualRevenue) * 100;
  }
  return annualData;
}

function createPlanLines(planNames) {
  const maxPlanNum = Object.keys(planNames).length;
  const lines = [];
  const colors = [
    '#dd8f0f',
    '#66cdaa',
    '#bdb76b',
    '#c71585',
    '#4169e1',
    '#ffa07a',
    '#c2c2c2',
    '#ffd700',
    '#00fa9a',
    '#00bfff',
    '#dda0dd',
    '#9acd32',
    '#800000',
    '#2f4f4f',
    '#0000ff',
    '#006400',
    '#ff00ff',
    '#000080',
    '#006400',
    '#ff0000',
  ];
  for (let i = Math.max(0, maxPlanNum - 4); i < maxPlanNum; i++) {
    lines.push(
      <Line
        name={`[Plan ${i + 1}] ${planNames[i]}`}
        key={`Plan ${i}`}
        dataKey={`p${i}Revenue`}
        formatter={(number) => `$${number.toLocaleString()}k`}
        stroke={colors[i - 1]}
      />,
    );
  }
  return lines;
}

const periodFormatter = (period) => `Q${period[4]} ${period.substring(0, 4)}`;

function QuarterlyRevenue(props) {
  const data = props.data.values.filter((entry) => entry.hasOwnProperty('actualRevenue'));
  return (
    <ResponsiveContainer width="100%" aspect={1.5}>
      <LineChart data={data} margin={{ left: 20, right: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="period"
          allowDuplicatedCategory={false}
          tickFormatter={periodFormatter}
          tick={{ fontSize: 12 }}
          minTickGap={-15}
        />
        <YAxis tickFormatter={(number) => `$${number.toLocaleString()}k`} />
        <Tooltip labelFormatter={periodFormatter} />
        <Legend />
        <Brush
          dataKey="period"
          startIndex={Math.max(0, data.length - 20)}
          height={25}
          tickFormatter={periodFormatter}
          stroke={BRUSH_COLOR}
        />
        <Line
          name="Revenue"
          dataKey="actualRevenue"
          formatter={(number) => `$${number.toLocaleString()}k`}
          stroke={PRIMARY_COLOR}
          strokeWidth={3}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

function AnnualRevenue(props) {
  const data = annualizeActualData(props.data.values);
  return (
    <ResponsiveContainer width="100%" aspect={1.5}>
      <ComposedChart data={data} margin={{ left: 25 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="period" />
        <YAxis yAxisId="left" tickFormatter={(number) => `$${number.toLocaleString()}k`} />
        <YAxis yAxisId="right" orientation="right" unit="%" />
        <Tooltip />
        <Legend />
        <Brush
          dataKey="period"
          startIndex={Math.max(0, data.length - 5)}
          height={25}
          stroke={BRUSH_COLOR}
        />
        <Bar
          yAxisId="left"
          name="Revenue"
          dataKey="actualRevenue"
          formatter={(number) => `$${number.toLocaleString()}k`}
          fill={PRIMARY_COLOR}
          barSize={20}
        />
        <Line
          yAxisId="right"
          name="Growth"
          dataKey="growth"
          formatter={(value) => `${value.toFixed(1)}%`}
          stroke={SECONDARY_COLOR}
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function PlannedVActual(props) {
  const data = props.data.values;
  const { planNames } = props.data;
  return (
    <ResponsiveContainer width="100%" aspect={1.5}>
      <LineChart data={data} margin={{ left: 20, right: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="period"
          allowDuplicatedCategory={false}
          tickFormatter={periodFormatter}
          tick={{ fontSize: 10 }}
          minTickGap={-15}
        />
        <YAxis tickFormatter={(number) => `$${number.toLocaleString()}k`} />
        <Tooltip labelFormatter={periodFormatter} />
        <Legend />
        <Brush
          dataKey="period"
          startIndex={Math.max(0, data.length - 20)}
          height={25}
          tickFormatter={periodFormatter}
          stroke={BRUSH_COLOR}
        />
        <Line
          name="Actual Revenue"
          dataKey="actualRevenue"
          formatter={(number) => `$${number.toLocaleString()}k`}
          stroke={PRIMARY_COLOR}
          strokeWidth={4}
        />
        {createPlanLines(planNames)}
      </LineChart>
    </ResponsiveContainer>
  );
}

function CompanyFinancials(props) {
  const [view, setView] = useState('quarterly');
  const [companyData, setCompanyData] = useState(null);
  const permalink = props.company;

  useEffect(() => {
    getSheetsData(COMPANY_FINANCIALS_SPREADSHEET_ID, COMPANY_FINANCIALS_RANGES)
      .then((data) => setCompanyData(parseRevenueData(permalink, data)))
      .catch(() => captureError(`Error getting financial data for ${permalink}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!companyData) return null;

  return (
    <div className="company-financials">
      <h3>Financials</h3>

      {view === 'quarterly' && <QuarterlyRevenue data={companyData} />}
      {view === 'annual' && <AnnualRevenue data={companyData} />}
      {view === 'pva' && <PlannedVActual data={companyData} />}

      <div className="btn-group financials-btns" role="group">
        <button
          type="button"
          className={`btn btn-default ${view === 'quarterly' ? 'active' : ''}`}
          onClick={() => setView('quarterly')}
        >
          Quarterly Revenue
        </button>
        <button
          type="button"
          className={`btn btn-default ${view === 'annual' ? 'active' : ''}`}
          onClick={() => setView('annual')}
        >
          Annual Revenue
        </button>
        <button
          type="button"
          className={`btn btn-default ${view === 'pva' ? 'active' : ''}`}
          onClick={() => setView('pva')}
        >
          Planned v. Actual
        </button>
      </div>
    </div>
  );
}

export default CompanyFinancials;

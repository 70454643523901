import React, { memo } from 'react';
import classnames from 'classnames';
import Flex from './Flex';

const LayoutHeader = ({ children, className }) => {
  return (
    <Flex className={classnames('container-page-header', className)} align="center">
      {children}
    </Flex>
  );
};

export default memo(LayoutHeader);

import React from 'react';

const DeleteIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 30 30" version="1.1">
      <title>delete-icon</title>
      <g id="delete-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="001-x-mark" fill="#ED3B64" fillRule="nonzero">
          <path
            d="M15,30 C6.72889711,30 0,23.2711029 0,15 C0,6.72889711 6.72889711,0 15,0 C23.2711029,0 30,6.72889711 30,15 C30,23.2711029 23.2711029,30 15,30 Z M15,2.14279172 C7.91061398,2.14279172 2.14279172,7.91061398 2.14279172,15 C2.14279172,22.089386 7.91061398,27.8572083 15,27.8572083 C22.089386,27.8572083 27.8572083,22.089386 27.8572083,15 C27.8572083,7.91061398 22.089386,2.14279172 15,2.14279172 Z"
            id="Shape"
          />
          <path
            d="M20.0736178,22.0588235 C19.7913515,22.0588235 19.5090853,21.9511475 19.2937336,21.7355596 L8.26438072,10.7061822 C7.83344172,10.2752423 7.83344172,9.5773504 8.26438072,9.14664604 C8.69508413,8.71594168 9.39321006,8.71570608 9.82391347,9.14664604 L20.8532663,20.1760234 C21.2842053,20.6069634 21.2842053,21.3048553 20.8532663,21.7355596 C20.6379146,21.9511475 20.355884,22.0588235 20.0736178,22.0588235 L20.0736178,22.0588235 Z"
            id="Path"
          />
          <path
            d="M9.04403663,22.0588235 C8.76176854,22.0588235 8.47973605,21.9511482 8.26438288,21.7355618 C7.833441,21.3048603 7.833441,20.6067375 8.26438288,20.176036 L19.2938094,9.14673223 C19.7247512,8.71579514 20.4226462,8.71579514 20.8533525,9.14673223 C21.2840588,9.57743371 21.2842944,10.2755565 20.8533525,10.706258 L9.82392603,21.7355618 C9.60857292,21.9511482 9.32630478,22.0588235 9.04403663,22.0588235 L9.04403663,22.0588235 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
};

export default DeleteIcon;

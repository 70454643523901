import React, { memo } from 'react';
import styled from 'styled-components';
import Flex from './Flex';

const StyledFlex = styled(Flex)`
  padding: 8px 12px;
  border-radius: 12px;
  background: #f7f7f7;
  height: 22px;
  box-shadow: 0 1px 0 0 #ddd;
  -webkit-box-shadow: 0 1px 0 0 #ddd;
  border: 0.5px solid #ddd
  max-width: 100px;
  min-width: 60px;
  p {
    font-size: 12.5px;
    line-height: 12.4px;
    margin: 0;
    white-space: nowrap;
  }
`;

const Pill = ({ value, marginLeft, marginTop }) => {
  return (
    <StyledFlex
      className="pill"
      justify="center"
      align="center"
      marginLeft={marginLeft}
      marginTop={marginTop}
    >
      <p>{value === 'Active' ? 'Prospect' : value}</p>
    </StyledFlex>
  );
};

export default memo(Pill);

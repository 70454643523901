import React from 'react';
import styled from 'styled-components';
import Flex from '../Flex';

const Container = styled(Flex).attrs({
  align: 'center',
  direction: 'column',
})`
  ${({ width = '100%' }) => `width: ${width};`}
  height: inherit;
  &.table-body {
    .vertical-item {
      font-size: 9px;
      padding: 3px;
      line-height: 12px !important;
    }
  }
`;

const TableBody = ({ children, width }) => {
  return (
    <Container className="table-body" width={width}>
      {children}
    </Container>
  );
};

export default TableBody;

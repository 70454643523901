import React, { Component } from 'react';
import { displayDate } from '../util';
import { getUsers, patchAffinity, getAffinity } from '../BackendAPI';

class InvestorSummary extends Component {
  state = {
    summary: {},
    responsibility: 'N/A',
    users: [],
    last_interaction_date: null,
  };

  componentDidMount() {
    const { summary } = this.props;
    this.setState({ summary });
    //   getUsers().then((users) => {
    //     getAffinity(summary.permalink).then((affinity) => {
    //       if (affinity) {
    //         const user = affinity ? users.find((u) => u.email === affinity.responsibility) : 'N/A';
    //         const responsibility = !user ? 'N/A' : user.user;
    //         this.setState({
    //           responsibility,
    //           last_interaction_date: affinity.last_interaction_date,
    //         });
    //       }
    //       this.setState({ users });
    //     });
    //   });
  }

  updateResponsible = (e) => {
    e.preventDefault();
    const { permalink } = this.state.summary;
    const { users } = this.state;
    const { value } = e.target;
    const user = users.find((u) => u.user === value);
    const responsibility = !user ? 'N/A' : user.email;
    patchAffinity(permalink, { responsibility }).then((a) => {
      const newResponsibility = !user ? 'N/A' : user.user;
      this.setState({ responsibility: newResponsibility });
    });
  };

  getStyle = () => {
    const lastDate = this.state.last_interaction_date;
    if (!lastDate) return null;
    const today = new Date();
    const lastDateDt = new Date(lastDate.slice(0, 10));
    const dO = Math.floor((today - lastDateDt) / (1000 * 60 * 60 * 24));
    const color = dO >= 60 ? 'red' : dO >= 30 ? 'orange' : 'green';
    return { color };
  };

  getActiveUsers = () => {
    const { users, responsibility } = this.state;
    const activeUsers = users.filter(
      (u) => (u.active || u.user === responsibility) && u.user_id !== 16,
    );
    return activeUsers;
  };

  render() {
    const { summary, responsibility, last_interaction_date } = this.state;
    const activeUsers = this.getActiveUsers();

    return (
      <div className="content-container card">
        <div className="card-block company-info">
          <h3>Investor Details</h3>
          <ul className="list-unstyled list-spaced">
            <li>
              <label>Description</label>
              <span className="text-muted" />
              {summary.short_description}
            </li>
            <li>
              <label>Website</label>
              <p>
                {summary.homepage_url ? (
                  <a target="_blank" href={`https://www.${summary.homepage_url}`} rel="noreferrer">
                    {summary.homepage_url}
                  </a>
                ) : (
                  'N/A'
                )}
              </p>
            </li>
            <li>
              <label>Location</label>
              <p>{summary.location ? summary.location : 'N/A'}</p>
            </li>
            <li>
              <label>Founded</label>
              <p>{summary.founded_on ? displayDate(summary.founded_on) : 'N/A'}</p>
            </li>
            {last_interaction_date && (
              <div>
                <li>
                  <label>Last Interaction</label>
                  <p style={this.getStyle()}>
                    {`${displayDate(last_interaction_date)} `}
                    <a
                      target="_blank"
                      href={`https://thomvest.affinity.co/companies/${summary.affinity_id}`}
                      rel="noreferrer"
                    >
                      (Affinity)
                    </a>
                  </p>
                </li>
                <li>
                  <label>Relationship Owner</label>
                  <div className="tv-select-container">
                    <select
                      className=""
                      value={responsibility}
                      onChange={(event) => this.updateResponsible(event)}
                    >
                      <option key={'N/A'}>N/A</option>
                      {activeUsers.map((u) => (
                        <option key={u.user_id}>{u.user}</option>
                      ))}
                    </select>
                    <div className="tv-select-arrow">
                      <i className="fa fa-angle-down" />
                    </div>
                  </div>
                </li>
              </div>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default InvestorSummary;

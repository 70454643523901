import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FundingDescription extends Component {
  render() {
    let { investors } = this.props;
    if (!investors) {
      investors = [];
    }
    const links = investors.map((investor, i) => {
      let permalink = Object.keys(investor)[0];
      const vals = Object.values(investor)[0];
      const { investor_name, is_lead_investor } = vals;

      if (permalink && permalink === 'pretium-partners-6859') {
        permalink = 'pretium';
      }

      if (is_lead_investor) {
        return (
          <span key={`investor-${investor_name}-${i}`}>
            {i > 0 ? ', ' : ''}
            <Link to={`/investor/${permalink}`} key={`feed${investor_name}`}>
              <b>{investor_name}</b>
            </Link>
          </span>
        );
      }

      return (
        <span key={`investor-${investor_name}-${i}`}>
          {i > 0 ? ', ' : ''}
          <Link to={`/investor/${permalink}`} key={`feed${investor_name}`}>
            {investor_name}
          </Link>
        </span>
      );
    });

    return <div>{links}</div>;
  }
}

export default FundingDescription;

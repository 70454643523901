import React, { useState, memo, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import debounce from 'lodash.debounce';

import { addNote, updateNote } from '../BackendAPI';
import { getUserEmail, getUserFirstName } from '../utils/Auth';
import RichTextEditor from './TextEditor';

import { CloseButton, Button, Flex } from './primitives';
import captureError from '../utils/sentry';

const Container = styled(Flex)`
  min-height: 24px;
  p {
    margin-bottom: 0;
  }
`;

function AddNote({ company, permalink, onSuccess, ctaCopy }) {
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [savedNote, setSavedNote] = useState(null);
  const [saving, setSaving] = useState(false);

  function handleClose() {
    if (onSuccess) onSuccess();
    setSavedNote(null);
    setSaving(false);
    setShowModal(false);
    setSuccess(false);
    setError(false);
  }

  const debouncedSaveRichText = debounce(async (stringifiedRichText) => {
    // create new note
    if (!saving) {
      if (!savedNote) {
        const userEmail = getUserEmail();

        try {
          setSaving(true);

          const note = await addNote(userEmail, permalink, stringifiedRichText);

          if (note) {
            setSuccess(true);
            setSavedNote(note);
            if (onSuccess) onSuccess();
          }
        } catch (err) {
          captureError(err);
          setError(true);
        } finally {
          setSaving(false);
        }
        // update created note
      } else {
        const firstName = getUserFirstName();

        try {
          setSaving(true);

          const updatedNote = await updateNote({
            noteId: savedNote.note_id,
            feedTime: savedNote.feed_time,
            header: `${firstName} added a note`,
            richText: stringifiedRichText,
          });

          if (updatedNote) {
            setSuccess(true);
            if (onSuccess) onSuccess();
          }
        } catch (err) {
          captureError(err);
          setError(true);
        } finally {
          setSaving(false);
        }
      }
    }
  }, 500);

  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 2000);
    }
  }, [success]);

  return (
    <div>
      {ctaCopy ? (
        <Button onClick={() => setShowModal(true)}>{ctaCopy}</Button>
      ) : (
        <Button onClick={() => setShowModal(true)}>✏️</Button>
      )}
      <Modal
        backdrop="static"
        show={showModal}
        onHide={handleClose}
        className="tv-modal tv-text-editor-modal"
      >
        <Modal.Header>
          <Modal.Title>New Note for {company}</Modal.Title>
          <CloseButton className="modal-close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <RichTextEditor externalOnChange={debouncedSaveRichText} />
          <Container direction="column">
            {saving && !success && <p className="green-score">..saving</p>}
            {success && !saving && <p className="green-score">note saved</p>}
            {error && (
              <p className="red-outstanding">
                There was an issue saving your note, please refresh and try again.
                <br />
                If the issues persits please reach out to IT.
              </p>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Flex align="center" justify="center" fill>
            <Button onClick={handleClose} disabled={!savedNote || saving}>
              {savedNote ? 'Done' : 'Save Note'}
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default memo(AddNote);

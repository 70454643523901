import styled from 'styled-components';
import Flex from './Flex';

const Card = styled(Flex)`
  padding: ${({ padding }) => (padding ? `${padding}` : `24px`)};
  background: #fff;
  ${({ borderRadius = true }) => borderRadius && `border-radius: 4px;`}
  ${({ boxShadow = true }) => boxShadow && `box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);`}

  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '10px')};
  position: relative;
  font-weight: 500;
  ${({ border = true }) => border && `border: 1px solid #ebeaea;`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  p {
    margin: 0;
  }
`;

export default Card;

import React from 'react';

const PercentageIcon = (props) => {
  const color = props.color !== '' ? props.color : '#A2AAB2';
  return (
    <svg {...props} viewBox="0 0 16 14" version="1.1">
      <g
        id="StyleGuide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fontFamily="Helvetica"
        fontSize="18"
        fontWeight="normal"
        letterSpacing="-0.2499999"
      >
        <g id="Icons-&amp;-Buttons" transform="translate(-200.000000, -435.000000)" fill={color}>
          <text id="%-copy">
            <tspan x="200" y="448">
              %
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export default PercentageIcon;

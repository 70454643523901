import React from 'react';

const DescriptionIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 23 28" version="1.1">
      <g id="New-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="details" transform="translate(-170.000000, -550.000000)" fillRule="nonzero">
          <g id="description-icon" transform="translate(170.000000, 550.000000)">
            <path
              d="M4.30041529,0 L0,4.79441787 L0,28 L22.5030675,28 L22.5030675,0 L4.30041529,0 Z M4.05055215,1.62849671 L4.05055215,4.51612903 L1.46050185,4.51612903 L4.05055215,1.62849671 Z M21.6029448,27.0967742 L0.900122699,27.0967742 L0.900122699,5.41935484 L4.95067485,5.41935484 L4.95067485,0.903225806 L21.6029448,0.903225806 L21.6029448,27.0967742 Z"
              id="Shape"
              fill="#000000"
            />
            <polygon
              id="Path"
              fill="#000000"
              points="16.3190184 21.398773 6.35582822 21.398773 6.35582822 14.6993865 7.26157278 14.6993865 7.26157278 20.4890264 15.4132738 20.4890264 15.4132738 14.6993865 16.3190184 14.6993865"
            />
            <polygon
              id="Path"
              fill="#FC4066"
              points="9.10429448 16.7607362 13.5705521 16.7607362 13.5705521 20.3680982 9.10429448 20.3680982"
            />
            <path
              d="M11.3374233,15.9018405 C10.5798504,15.9018405 9.96319018,15.2851802 9.96319018,14.5276074 C9.96319018,13.7700345 10.5798504,13.1533742 11.3374233,13.1533742 C12.0949962,13.1533742 12.7116564,13.7700345 12.7116564,14.5276074 C12.7116564,15.2851802 12.0949962,15.9018405 11.3374233,15.9018405 Z M11.3374233,14.0695297 C11.0846754,14.0695297 10.8793456,14.2748594 10.8793456,14.5276074 C10.8793456,14.7803553 11.0846754,14.9856851 11.3374233,14.9856851 C11.5901712,14.9856851 11.795501,14.7803553 11.795501,14.5276074 C11.795501,14.2748594 11.5901712,14.0695297 11.3374233,14.0695297 Z"
              id="Shape"
              fill="#000000"
            />
            <path
              d="M16.5602613,15.3865031 C16.2468399,15.3865031 15.94645,15.2882742 15.692223,15.1029282 L11.2515337,11.8527068 L6.81128617,15.1024837 C6.55661746,15.2882742 6.25622763,15.3865031 5.94280618,15.3865031 C5.12844056,15.3865031 4.46625767,14.7202354 4.46625767,13.9008463 L4.46625767,13.6410508 C4.46625767,13.1621294 4.69751367,12.7107653 5.08470731,12.4325241 L11.2515337,8 L17.4183602,12.4320796 C17.8055538,12.7107653 18.0368098,13.1621294 18.0368098,13.6410508 L18.0368098,13.9008463 C18.0368098,14.7202354 17.3746269,15.3865031 16.5602613,15.3865031 L16.5602613,15.3865031 Z"
              id="Path"
              fill="#FC4066"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DescriptionIcon;

import React, { useState, useCallback, memo } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import FilterContainer from './FilterContainer';
import { Flex, Select, Card, Input } from './primitives';
import useIsMobile from '../hooks/useIsMobile';
import { VERTICAL_FILTERS } from '../pages/Engaged';

const initFeedFilters = [
  { label: 'All', name: 'All', active: true },
  { label: 'Status', name: 'Status', active: false },
  { label: 'Notes', name: 'Note', active: false },
  { label: 'Funding', name: 'Funding', active: false },
  { label: 'News', name: 'News', active: false },
];

export const OWNER_FILTERS = [
  { label: 'All', name: 'All', active: true },
  { label: 'AK', name: 'AK', active: false },
  { label: 'DB', name: 'DB', active: false },
  { label: 'NW', name: 'NW', active: false },
  { label: 'UP', name: 'UP', active: false },
  { label: 'AR', name: 'AR', active: false },
];

function isOdd(number) {
  return number % 2 !== 0;
}
const SelectContainer = styled.div`
  ${({ isMobile }) => (isMobile ? `width: 100px;` : 'width: 140px;')}
  ${({ first }) => first && `margin-right: 34px;`}
`;

const Filter = ({ label, filters, onChange }) => {
  const isMobile = useIsMobile();

  return (
    <Flex
      align={isMobile ? 'center' : 'flex-start'}
      direction={isMobile ? 'row' : 'column'}
      marginRight="20"
    >
      <h6 className="filter-label">{label}</h6>
      <Flex>
        {isMobile ? (
          <SelectContainer first isMobile={isMobile}>
            <Select options={filters} onChange={onChange} compact />
          </SelectContainer>
        ) : (
          filters.map((v) => (
            <button
              key={`vert-filter-${v.label}`}
              className={classnames('filter-btn', { active: v.active })}
              type="button"
              onClick={onChange}
              value={v.name}
            >
              {v.label}
            </button>
          ))
        )}
      </Flex>
    </Flex>
  );
};

const FeedFilter = ({
  withVerticalFilter,
  handleOnVerticalChange,
  padding,
  margin,
  isDashboard,
  withOwnerFilter,
  handleOwnerChange,
  withScoreFilter,
  handleScoreChange,
  withFeedTypeFilter,
  handleFeedTypeChange,
  withSearchInputFilter,
  handleSearchInputChange,
  borderRadius,
  boxShadow,
  childFilter,
}) => {
  const [feedFilters, setFeedFilters] = useState(initFeedFilters);
  const [verticalFilters, setVerticalFilters] = useState(VERTICAL_FILTERS);
  const [ownerFilters, setOwnerFilters] = useState(OWNER_FILTERS);
  const [scoreFilter, setScoreFilter] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const isMobile = useIsMobile();

  const changeFeed = useCallback(
    (e) => {
      e.preventDefault();
      const newFeed = e.target.value;

      const updatedFeedFilters = feedFilters.map((v) => {
        if (newFeed === 'All') {
          v.active = v.name === 'All';
        } else if (v.name === newFeed) {
          v.active = !v.active;
        } else if (newFeed !== 'All' && v.name === 'All') {
          v.active = false;
        }

        return v;
      });

      if (newFeed !== 'All') {
        let totalInactive = 0;

        feedFilters.forEach((v) => {
          if (!v.active) totalInactive += 1;
        });

        updatedFeedFilters.map((v) => {
          if (v.name === 'All') {
            v.active = totalInactive === feedFilters.length;
          }
          return v;
        });
      }

      setFeedFilters([...updatedFeedFilters]);
      handleFeedTypeChange(newFeed);
    },
    [feedFilters, handleFeedTypeChange],
  );

  function changeOwner(e) {
    window.scrollTo(0, 0);
    const newOwner = e.target.value;
    ownerFilters.forEach((v) => (v.active = newOwner === v.name));
    setOwnerFilters([...ownerFilters]);
    handleOwnerChange(newOwner);
  }

  function handleSearchInput(e) {
    window.scrollTo(0, 0);
    const search = e.target.value;
    setSearchInput(search);
    handleSearchInputChange(search);
  }

  const changeVertical = useCallback(
    (e) => {
      e.preventDefault();
      const verticalFilter = e.target.value;

      const updatedVerticalFilters = verticalFilters.map((v) => {
        if (verticalFilter === 'All') {
          v.active = v.name === 'All';
        } else if (v.name === verticalFilter) {
          v.active = !v.active;
        } else if (verticalFilter !== 'All' && v.name === 'All') {
          v.active = false;
        }

        return v;
      });

      if (verticalFilter !== 'All') {
        let totalInactive = 0;

        verticalFilters.forEach((v) => {
          if (!v.active) totalInactive += 1;
        });

        if (totalInactive === verticalFilters.length) {
          updatedVerticalFilters.map((v) => {
            if (v.name === 'All') {
              v.active = true;
            }
            return v;
          });
        }
      }

      setVerticalFilters([...updatedVerticalFilters]);
      handleOnVerticalChange(verticalFilter);
    },
    [verticalFilters, handleOnVerticalChange],
  );

  const totalTrue =
    Number(withFeedTypeFilter) +
    Number(withVerticalFilter) +
    Number(withOwnerFilter) +
    Number(withSearchInputFilter);

  if (isMobile) {
    return (
      <FilterContainer
        margin={margin}
        padding={padding}
        isDashboard={isDashboard}
        isMobile={isMobile}
        justify="space-between"
        align="flex-end"
        fill
        borderRadius={borderRadius}
        boxShadow={boxShadow}
      >
        {withFeedTypeFilter && handleFeedTypeChange && (
          <Select label="Type" options={feedFilters} onChange={changeFeed} compact />
        )}

        {withVerticalFilter && handleOnVerticalChange && (
          <Select label="Vertical" options={verticalFilters} onChange={changeVertical} compact />
        )}

        {withOwnerFilter && handleOwnerChange && (
          <Select label="Owner" options={ownerFilters} onChange={changeOwner} compact />
        )}

        {withSearchInputFilter && handleSearchInputChange && (
          <Input
            onChange={handleSearchInput}
            value={searchInput}
            placeholder="Filter by name"
            marginTop="8px"
          />
        )}

        {isOdd(totalTrue) && <Flex justify="center" className="modal-row" />}
      </FilterContainer>
    );
  }

  return (
    <FilterContainer
      margin={margin}
      padding={padding}
      isDashboard={isDashboard}
      isMobile={isMobile}
      justify="space-between"
      fill
      borderRadius={borderRadius}
      boxShadow={boxShadow}
    >
      <Flex>
        {withFeedTypeFilter && handleFeedTypeChange && (
          <Filter label="Type" filters={feedFilters} onChange={changeFeed} />
        )}

        {withVerticalFilter && handleOnVerticalChange && (
          <Filter label="Vertical" filters={verticalFilters} onChange={changeVertical} />
        )}

        {withOwnerFilter && handleOwnerChange && (
          <Filter label="Owner" filters={ownerFilters} onChange={changeOwner} />
        )}

        {withScoreFilter && handleScoreChange && (
          <Flex
            align={isMobile ? 'center' : 'flex-start'}
            direction={isMobile ? 'row' : 'column'}
            marginRight="20"
          >
            <h6 className="title">TV Score</h6>
            <button
              className={classnames(`filter-btn${scoreFilter ? ' active' : ''}`)}
              type="button"
              onClick={() => {
                setScoreFilter(!scoreFilter);
                handleScoreChange();
              }}
              value={7}
            >
              7+
            </button>
          </Flex>
        )}
      </Flex>

      {withSearchInputFilter && handleSearchInputChange && (
        <Flex
          align={isMobile ? 'center' : 'flex-start'}
          justify={!isMobile ? 'center' : 'flex-start'}
          direction={isMobile ? 'row' : 'column'}
        >
          <Input onChange={handleSearchInput} value={searchInput} placeholder="Filter by name" />
        </Flex>
      )}

      {childFilter}
    </FilterContainer>
  );
};

export default memo(FeedFilter);

import React, { useCallback, useState, memo, useEffect } from 'react';
import { differenceInDays, addDays } from 'date-fns';
import classnames from 'classnames';
import styled from 'styled-components';
import { patchTask, getUserTasks } from '../../BackendAPI';
import captureError from '../../utils/sentry';
import { Flex, Card, Button } from '../primitives';
import Boundary from '../Boundary';
import HoverLink from '../HoverLink';
import { capitalize } from '../../util';
import { getUser } from '../../utils/Auth2';

const P = styled.p`
  padding-left: 4px;
`;

const Container = styled(Flex)`
  margin-top: 15px;
  button.margin {
    margin-left: 4px;
  }
`;

const OuterContainer = styled(Flex)`
  padding-bottom: 20px;
  border-top: 1px solid #ebeaea;
`;

const formatPermalink = (permalink = '') => {
  return capitalize(permalink.replace('tv-', ''));
};

const DISPLAY_INCREMENTS = 3;

function RemindersWidgetContent() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dueTasks, setDueTasks] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [numDisplay, setNumDisplay] = useState(DISPLAY_INCREMENTS);
  const user = getUser();

  const increment = () => setNumDisplay((i) => i + DISPLAY_INCREMENTS);
  const decrement = () => setNumDisplay((i) => i - DISPLAY_INCREMENTS);

  const stillTasksToSee = numDisplay < dueTasks?.length;

  useEffect(() => {
    if (user && !fetched) {
      getUserTasks(user.email).then((tasks) => {
        const filteredTasks = tasks
          .filter((t) => t.company_name && t.permalink)
          .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
        setDueTasks(filteredTasks);
        setFetched(true);
      });
    }
  }, [user, fetched]);

  const completeTask = useCallback(
    async (task_id) => {
      setLoading(true);
      try {
        await patchTask(task_id, { status: 'Complete' });
        setFetched(false);
      } catch (err) {
        captureError(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [setFetched],
  );

  const extendTask = useCallback(
    async (task) => {
      setLoading(true);
      try {
        const extendedDueDate = addDays(new Date(task.due_date), 30);
        await patchTask(task.task_id, { due_date: new Date(extendedDueDate).toISOString() });
        setFetched(false);
      } catch (err) {
        captureError(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [setFetched],
  );

  if (dueTasks?.length > 0) {
    return (
      <Flex direction="column" fill>
        <h6 className="section-header widget">Reminders</h6>

        <OuterContainer direction="column" fill>
          <Card padding="0" direction="column" boxShadow={false} border={false} fill>
            {dueTasks.slice(0, numDisplay).map((task) => {
              const daysOut = differenceInDays(new Date(task.due_date), new Date()) - 1;
              return (
                <div className="widget-row-container" key={task.due_date}>
                  <Flex className="watchlistPanel__name" fill>
                    <HoverLink
                      orgType="company"
                      permalink={task.permalink}
                      companyName={task.company_name ?? formatPermalink(task.permalink)}
                    />{' '}
                    <P>Follow-up</P>
                  </Flex>

                  <Flex>
                    {daysOut <= 1 ? (
                      <p className="widget-row-body-bold red">Due now</p>
                    ) : (
                      <p
                        className={classnames('widget-row-body', {
                          'red-outstanding': daysOut <= 7,
                        })}
                      >
                        Due in{' '}
                        <span
                          className={classnames('widget-row-body-bold', {
                            'red-outstanding': daysOut <= 7,
                          })}
                        >
                          {daysOut} days
                        </span>
                      </p>
                    )}
                  </Flex>

                  <Container>
                    <Button onClick={() => completeTask(task.task_id)} disabled={loading}>
                      Mark Complete
                    </Button>
                    {daysOut <= 7 && (
                      <Button
                        onClick={() => extendTask(task)}
                        disabled={loading}
                        variant="secondary"
                        className="margin"
                      >
                        Extend 30 Days
                      </Button>
                    )}
                  </Container>
                  {error && <p className="red-outstanding">There was an issue</p>}
                </div>
              );
            })}
          </Card>

          {dueTasks?.length > DISPLAY_INCREMENTS && (
            <Flex fill justify="center" marginTop="8">
              <Button rounded onClick={stillTasksToSee ? increment : decrement}>
                {stillTasksToSee ? 'See More' : 'See Less'}
              </Button>
            </Flex>
          )}
        </OuterContainer>
      </Flex>
    );
  }

  return null;
}

const RemindersWidget = () => {
  return (
    <Boundary fallback={null} loadingFallback={null}>
      <RemindersWidgetContent />
    </Boundary>
  );
};

export default memo(RemindersWidget);

import React from 'react';

const NextArrowIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 36 30" version="1.1">
      <g id="New-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="arrow-icons"
          transform="translate(-1239.000000, -365.000000)"
          fill="#ED3C64"
          fillRule="nonzero"
        >
          <g id="arrow-icon" transform="translate(90.000000, 365.000000)">
            <path
              d="M1171.03425,0.413603203 C1170.435,-0.157154452 1169.48475,-0.13465415 1168.914,0.465353897 C1168.3425,1.06536194 1168.3665,2.01487468 1168.96575,2.58638234 L1180.47225,13.4997787 L1150.5,13.4997787 C1149.672,13.4997787 1149,14.1717877 1149,14.9997988 C1149,15.8278099 1149.672,16.4998189 1150.5,16.4998189 L1180.42425,16.4998189 L1168.96575,27.4139653 C1168.36575,27.985473 1168.3425,28.9349857 1168.914,29.5349938 C1169.20875,29.8439979 1169.604,30 1170,30 C1170.372,30 1170.744,29.8627482 1171.03425,29.5859944 L1184.121,17.1208273 C1184.688,16.5545697 1185,15.8015596 1185,14.9997988 C1185,14.1980381 1184.688,13.445028 1184.09475,12.85327 L1171.03425,0.413603203 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NextArrowIcon;

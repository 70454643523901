import React from 'react';
import styled from 'styled-components';

const Span = styled.div`
  margin-left: 3px;
  margin-top: 1px;
  position: absolute;
`;

const BoldIcon = () => {
  return (
    <Span>
      <svg width="20" height="20" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.457 11.868A4.47 4.47 0 0 0 18 8.5 5.506 5.506 0 0 0 12.5 3H5v19h8.5a5.497 5.497 0 0 0 2.957-10.132zM8 6h4.5a2.5 2.5 0 0 1 0 5H8zm5.5 13H8v-5h5.5a2.5 2.5 0 0 1 0 5z" />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    </Span>
  );
};

export default BoldIcon;

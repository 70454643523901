import { VERTICAL_FILTERS } from './pages/Engaged';

export const maxDescriptionCharLimit = 90;

export const sanitizeAndCombineVerticals = (company) => {
  if (!company) return [];

  const companyVertical = company.vertical ?? company.tv_vertical;
  const companyVerticals = companyVertical?.split(', ') ?? [];

  const harmonicVerticals = company.harmonic_verticals?.split(',') ?? [];

  const hv = [...new Set(harmonicVerticals)].map((a) => a?.trim());
  const cv = [...new Set(companyVerticals)].map((a) => a?.trim());

  if (hv?.length > 0) {
    hv.forEach((c) => {
      const verticalFilter = VERTICAL_FILTERS.find((a) => a.longName === c);
      if (verticalFilter && !cv.includes(verticalFilter.name)) {
        cv.push(verticalFilter.name);
      }
    });
  }

  return cv;
};

export const removeHyphenAndCapitalize = (inputString) => {
  if (!inputString) return '';
  // Split the string by hyphens to get an array of words
  const words = inputString.replace('tv-', '').split('-');

  // Capitalize the first letter of each word and join them back together
  const formattedString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedString;
};

export function camelCaseToSentenceCase(camelCaseString) {
  // Add space before capital letters and replace with space
  const sentenceCaseString = camelCaseString.replace(/([A-Z])/g, ' $1');

  if (sentenceCaseString.toLowerCase() === 'linkedin follower count') return 'LinkedIn Followers';
  if (sentenceCaseString.toLowerCase() === 'headcount engineering') return 'Headcount Eng';

  // Capitalize the first letter and remove leading space
  return sentenceCaseString.charAt(0).toUpperCase() + sentenceCaseString.slice(1).trim();
}

export const truncateNumber = (number) => {
  if (number < 1000) {
    return number.toString();
  }
  if (number >= 1000 && number < 1000000) {
    return `${(number / 1000).toFixed(1)}k`;
  }
  if (number >= 1000000 && number < 1000000000) {
    return `${(number / 1000000).toFixed(1)}M`;
  }
  if (number >= 1000000000 && number < 1000000000000) {
    return `${(number / 1000000000).toFixed(1)}B`;
  }
  return `${(number / 1000000000000).toFixed(1)}T`;
};

export const getFormattedDate = (date, withoutDay) => {
  const newDate = new Date(date);
  const year = newDate.getUTCFullYear();
  const month = newDate.getUTCMonth() + 1;
  const day = newDate.getUTCDate();
  if (withoutDay) return `${month}/${year}`;
  return `${month}/${day}/${year}`;
};

export const calculatePercentageDifference = (lowerNumber, higherNumber) => {
  if (lowerNumber === 0) {
    return `${Math.round(higherNumber - lowerNumber) * 100}%`;
  }

  const difference = higherNumber - lowerNumber;
  const percentageDifference = (difference / lowerNumber) * 100;

  return `${Math.round(percentageDifference)}%`;
};

export const displayMoney = (amount) => {
  let amt_string = '';
  if (typeof amount === 'undefined' || amount === null) return amt_string;
  let amt = amount;

  if (amount >= 1000000) {
    amt = amount / 1000000.0;
    amt_string = amt.toFixed(1);

    if (amt.toFixed(0) === amt.toString()) {
      amt_string = amt.toFixed(0);
    }
    return `$${amt_string}M`;
  }
  if (amount >= 1000) {
    amt = amount / 1000.0;
    amt_string = amt.toFixed(1);

    if (amt.toFixed(0) === amt.toString()) {
      amt_string = amt.toFixed(0);
    }

    return `$${amt_string}K`;
  }
};

export const displayDate = (dateString) => {
  if (!dateString) {
    return '-';
  }
  const parts = dateString.slice(0, 10).split('-');
  const d = new Date(parts[0], parts[1] - 1, parts[2]);
  const dString = d.toDateString();
  return `${dString.slice(4, 10)}, ${dString.slice(11, 15)}`;
};

export const capitalize = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isRecent = (dt) => {
  if (!dt) return false;
  return new Date() - new Date(dt) < 1000 * 60 * 60 * 24 * 7;
};

export const displayInvestmentType = (s) => {
  if (s === null) return '-';
  const stringList = s.split('_').map((s) => s[0].toUpperCase() + s.slice(1));
  return stringList.join(' ');
};

export const getToday = () => {
  const today = new Date();
  return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
    today.getDate(),
  ).padStart(2, '0')}`;
};

export const truncate = (s, len = maxDescriptionCharLimit) => {
  if (s.length <= len) {
    return s;
  }

  return `${s.substring(0, len - 3)}...`;
};

export const compare = (a, b) => {
  if (b === null && a === null) return 0;
  if (b === null) return 1;
  if (a === null) return -1;
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const keyInvestorSort = (a, b) => {
  if (a.tier === b.tier) {
    return a.name > b.name ? 1 : -1;
  }
  if (a.tier === null) {
    return 1;
  }
  if (b.tier === null) {
    return -1;
  }
  return a.tier - b.tier;
};

export function getExternalURL(url) {
  return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
}

export function convertToCSV(data) {
  const replacer = (_key, value) => (value === null ? '' : value);
  const header = Object.keys(data[0]);
  const csv = [
    header.join(','),
    ...data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
        .replace(/\\"/g, '""'),
    ),
  ].join('\r\n');
  return csv;
}

export function downloadCSV(csvData, fileName) {
  const blob = new Blob([csvData], { type: 'text/csv' });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
}

export function removeInvestorDuplicates(array) {
  const seenNames = new Set();

  return array.filter((item) => {
    if (seenNames.has(item.investor_name)) {
      return false; // Skip this item because it's a duplicate
    }

    seenNames.add(item.investor_name);
    return true; // Keep this item because it's not a duplicate
  });
}

export function removeDuplicatesFromString(str, splitter = ', ') {
  const uniqueValues = new Set(str.split(splitter));
  const resultArray = Array.from(uniqueValues);
  return resultArray.join(splitter);
}

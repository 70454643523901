import React from 'react';

const DropdownTriangleIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 6 4" version="1.1">
      <g id="StyleGuide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons-&amp;-Buttons" transform="translate(-937.000000, -336.000000)" fill="#FC4067">
          <g id="Group-3" transform="translate(893.000000, 321.000000)">
            <polygon id="Triangle" points="47 19 44 15 50 15" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DropdownTriangleIcon;

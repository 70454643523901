import React, { useState, useEffect, useContext, useReducer, useMemo, useCallback } from 'react';
import styled from 'styled-components';
// import { useLocation } from 'react-router-dom';
import InvestorFilters from '../InvestorFilters';
import CompanyFilters from '../company/CompanyFilters';
import AdvancedSearchTable from './AdvancedSearchTable';
import Boundary from '../Boundary';
import { InvestorSearchContext } from '../../contexts/InvestorSearchContext';
import { investorInvestorSearch, investorCompanySearch } from '../../BackendAPI';
import { Card, Loader } from '../primitives';
import captureError from '../../utils/sentry';
// import { parseParams } from '../pages/AdvancedSearch';
import '../../styles/components/InvestorSearch.css';

const INVESTOR_COLUMNS = [
  { text: 'Name', colClass: 'name', key: 'name', sortable: true },
  { text: 'Tier', colClass: 'tier', key: 'tier', sortable: true },
  { text: 'Owner', colClass: 'owner', key: 'code', sortable: true },
  { text: 'Relationship', colClass: 'relationship', key: 'relationship', sortable: true },
  { text: 'Last Interaction', colClass: 'location', key: 'last_interaction_date', sortable: true },
  { text: 'Expertise', colClass: 'vertical', key: 'vertical_expertise', sortable: true },
  { text: 'Investments (TTM)', colClass: 'location', key: 'ttm_investments', sortable: true },
  { text: 'Co-Investments', colClass: 'location', key: 'coinvestments', sortable: true },
];

const COMPANY_COLUMNS = [
  { text: 'Name', colClass: 'name', key: 'company_name', sortable: true },
  { text: 'VC Score', colClass: 'score', key: 'tv_company_investor_score', sortable: true },
  { text: 'Vertical', colClass: 'vertical', key: 'tv_vertical', sortable: true },
  { text: 'Status', colClass: 'status', key: 'major_status', sortable: true },
  { text: 'Last Round', colClass: 'stage', key: 'last_round', sortable: true },
  { text: 'Round Date', colClass: 'location', key: 'round_date', sortable: true },
  { text: 'Key Investors', colClass: 'description', key: 'key_investors', sortable: true },
];

const StyledCard = styled(Card).attrs({
  width: '100%',
  height: '100%',
  justify: 'center',
  align: 'center',
  direction: 'column',
})`
  min-height: 600px;
  margin-top: 20px;
`;

const StyledRow = styled.div`
  margin-bottom: 100px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebeaea;
  margin-bottom: 20px;
`;

const Container = styled.div`
  @media (max-width: 991px) {
    margin-top: 70px;
  }
  .table-container {
    width: 100%;
    position: relative;

    @media (max-width: 1600px) {
      .column {
        &.description {
          display: none;
        }
      }
    }
    .column {
      &.name {
        width: 10%;
        min-width: 160px;
      }
    }
  }
`;

const FILTERS = [
  { label: 'Companies', num: 0, active: true, loading: false },
  { label: 'Investors', num: 0, active: false, loading: false },
];

const reducer = (state, action) => {
  const newState = [...state];
  switch (action.type) {
    case 'TOGGLE':
      return newState.map((f) => {
        f.active = f.label === action.value;
        return f;
      });
    case 'UPDATE_INVESTOR_COUNT':
      newState[1].num = action.value;
      newState[1].loading = false;
      return newState;
    case 'UPDATE_COMPANY_COUNT':
      newState[0].num = action.value;
      newState[0].loading = false;
      return newState;
    case 'SET_LOADING':
      if (action.payload === 'Companies') {
        newState[0].loading = true;
      } else {
        newState[1].loading = true;
      }
      return newState;
    default:
      return newState;
  }
};

const isEmpty = (searchFilters) => {
  const { investor, company } = searchFilters;
  const investorKeys = Object.keys(investor);
  for (let i = 0; i < investorKeys.length; i += 1) {
    if (investor[investorKeys[i]].length > 0) {
      return false;
    }
  }
  const companyKeys = Object.keys(company);
  for (let i = 0; i < companyKeys.length; i += 1) {
    if (companyKeys[i] === 'location') {
      if (Object.keys(company[companyKeys[i]]).length > 0) {
        return false;
      }
    }

    if (company[companyKeys[i]].length > 0) {
      return false;
    }
  }
  return true;
};

const hasFilterChanged = (prevSearchFilters, newSearchFilters) => {
  if (!prevSearchFilters) return true;

  const { investor: newInvestor, company: newCompany } = newSearchFilters;
  const { investor: prevInvestor, company: prevCompany } = prevSearchFilters;

  const investorKeys = Object.keys(newInvestor);

  for (let i = 0; i < investorKeys.length; i += 1) {
    if (newInvestor[investorKeys[i]].length !== prevInvestor[investorKeys[i]].length) {
      return true;
    }
  }

  const companyKeys = Object.keys(newCompany);

  for (let i = 0; i < companyKeys.length; i += 1) {
    if (companyKeys[i] === 'location') {
      if (newCompany.location?.region !== prevCompany.location.region) {
        return true;
      }
      if (newCompany.location?.country !== prevCompany.location.country) {
        return true;
      }
      if (newCompany.location?.city !== prevCompany.location.city) {
        return true;
      }
    }

    if (newCompany[companyKeys[i]]?.length !== prevCompany[companyKeys[i]]?.length) {
      return true;
    }
  }

  return false;
};

function AdvancedSearchContentFallback() {
  return (
    <div className="container col-md-9">
      <StyledCard direction="column">
        <p>There was an issue. Please reset the filter and try again.</p>
        <p>If it continues reach out to the IT team.</p>
      </StyledCard>
    </div>
  );
}

function AdvancedSearchContentLoading() {
  return (
    <div className="container col-md-9">
      <StyledCard direction="column">
        <p>...loading</p>
      </StyledCard>
    </div>
  );
}

function AdvancedSearchContentContent({ setActiveFeed, subverticals }) {
  const [investors, setInvestors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [filters, dispatch] = useReducer(reducer, FILTERS);
  const [state, dispatchInvestorSearch] = useContext(InvestorSearchContext);
  const [prevSearchFilters, setPrevSearchFilters] = useState(null);
  const [prevActiveFeed, setPrevActiveFeed] = useState(FILTERS[0]);
  const [hasError, setError] = useState(false);
  const [resetFilters, setResetFilters] = useState(false);

  // useEffect(() => {
  //   setPrevSearchFilters(JSON.parse(JSON.stringify(state.searchFilters)));
  // }, [state.searchFilters]);

  const activeFeed = useMemo(() => {
    const active = filters.find((f) => f.active);
    setActiveFeed(active?.label?.toLowerCase() ?? 'companies');
    return active;
  }, [filters, setActiveFeed]);

  const resetSearchFilters = useCallback(() => {
    // setResetFilters(true);
    setCompanies([]);
    setInvestors([]);
    dispatch({ type: 'UPDATE_INVESTOR_COUNT', value: 0 });
    dispatch({ type: 'UPDATE_COMPANY_COUNT', value: 0 });
    // dispatchInvestorSearch({ type: 'RESET' });
    // setTimeout(() => setResetFilters(false), 100);
  }, []);

  useEffect(() => {
    const { searchFilters } = state;

    const hasSearchFilterChanged = hasFilterChanged(
      prevSearchFilters,
      searchFilters,
      activeFeed.label,
    );

    const isEmptySearch = isEmpty(state.searchFilters);

    if (isEmptySearch) {
      // resetSearchFilters();
      // setResetFilters(false);
    } else if (hasSearchFilterChanged || prevActiveFeed.label !== activeFeed.label) {
      if (activeFeed.label === 'Investors') {
        dispatch({ type: 'SET_LOADING', payload: activeFeed.label });
        investorInvestorSearch(searchFilters)
          .then((res) => {
            setInvestors(res);
            setPrevActiveFeed(activeFeed);
            dispatch({ type: 'UPDATE_INVESTOR_COUNT', value: res.length });
            if (res.length > 0) {
              setPrevSearchFilters(JSON.parse(JSON.stringify(searchFilters)));
            }
          })
          .catch((err) => {
            resetSearchFilters();
            setError(true);
            captureError(err);
          });
      }

      if (activeFeed.label === 'Companies') {
        dispatch({ type: 'SET_LOADING', payload: activeFeed.label });
        investorCompanySearch(searchFilters)
          .then((res) => {
            setCompanies(res);
            setPrevActiveFeed(activeFeed);
            dispatch({ type: 'UPDATE_COMPANY_COUNT', value: res.length });
            if (res.length > 0) {
              setPrevSearchFilters(JSON.parse(JSON.stringify(searchFilters)));
            }
          })
          .catch((err) => {
            resetSearchFilters();
            setError(true);
            captureError(err);
          });
      }
    }
  }, [state, activeFeed, prevSearchFilters, prevActiveFeed, resetSearchFilters]);

  const isLoading = useMemo(() => {
    return filters.find((f) => f.loading);
  }, [filters]);

  const searchFeed = useMemo(() => {
    if (activeFeed.label === 'Investors' && investors?.length > 0) {
      return <AdvancedSearchTable data={investors} columns={INVESTOR_COLUMNS} type="investors" />;
    }

    if (companies?.length > 0) {
      return <AdvancedSearchTable data={companies} columns={COMPANY_COLUMNS} type="company" />;
    }

    return null;
  }, [investors, companies, activeFeed]);

  const showNux = useMemo(() => {
    if (activeFeed.label === 'Companies' && companies?.length === 0) return true;
    if (activeFeed.label === 'Investors' && investors?.length === 0) return true;
    return false;
  }, [activeFeed.label, companies, investors]);

  const nuxCopy = useMemo(() => {
    const isEmptySearch = isEmpty(state.searchFilters);
    if (hasError) return 'There was an issue with this search. Please refresh and try again.';
    if (isLoading) return `...searching ${isLoading.label.toLowerCase()}`;
    if (!isEmptySearch) {
      if (
        (activeFeed.label === 'Companies' && companies?.length === 0) ||
        (activeFeed.label === 'Investors' && investors?.length === 0)
      ) {
        return 'No results found. Expand your search filters.';
      }
    }
    return 'Select filters to begin your search';
  }, [isLoading, state.searchFilters, activeFeed, companies, investors, hasError]);

  return (
    <Container className="container page-container company-profile investor-search">
      <StyledRow className="row">
        <div className="col-md-3">
          <div className="search-filters tv-card" direction="column">
            <InvestorFilters />
            <Divider />
            <CompanyFilters resetFilters={resetFilters} />
            {/* <Button onClick={resetSearchFilters} fill>
              Reset Filters
            </Button> */}
          </div>
        </div>

        <div className="col-md-9">
          <div
            className="toggle-view-btns btn-group flagged-btns tv-card"
            role="group"
            aria-label="..."
          >
            {filters.map((f) => (
              <button
                key={`status-${f.label}`}
                type="button"
                className={`btn btn-default ${f.active ? 'active' : ''}`}
                value={f.label}
                onClick={() => dispatch({ type: 'TOGGLE', value: f.label })}
              >
                {f.label} ({f.loading ? '...' : f.num})
              </button>
            ))}
          </div>

          {isLoading || showNux || hasError ? (
            <StyledCard>
              <h3>{nuxCopy}</h3>
              {isLoading && <Loader />}
            </StyledCard>
          ) : (
            <Boundary
              loadingFallback={<AdvancedSearchContentLoading />}
              fallback={<AdvancedSearchContentFallback />}
            >
              {searchFeed}
            </Boundary>
          )}
        </div>
      </StyledRow>
    </Container>
  );
}

export default function AdvancedSearchContent(props) {
  return (
    <Boundary
      loadingFallback={<AdvancedSearchContentLoading />}
      fallback={<AdvancedSearchContentFallback />}
    >
      <AdvancedSearchContentContent {...props} />
    </Boundary>
  );
}

import React, { memo } from 'react';
import Sidebar from './Sidebar';
import Boundary from './Boundary';
import Card from './primitives/Card';
import Flex from './primitives/Flex';

function AppContent({ children }) {
  return <div>{children}</div>;
}

function AppFallback() {
  return (
    <Flex width="100%" height="100%" justify="center" align="center">
      <Card width="100%" height="100%" justify="center" align="center" direction="column">
        <p>There was an issue. Please refresh and try again.</p>
        <p>If it continues reach out to the IT team.</p>
      </Card>
    </Flex>
  );
}

function AppLayout({ logout, children }) {
  return (
    <div>
      <div className="app-wrapper">
        <Sidebar logout={logout} />
        <div className="content-wrapper">
          <Boundary fallback={AppFallback}>
            <AppContent>{children}</AppContent>
          </Boundary>
        </div>
      </div>
    </div>
  );
}

export default memo(AppLayout);

import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import useIsMobile from '../hooks/useIsMobile';
import { search, getUserWatchlistCompanies } from '../BackendAPI';
import captureError from '../utils/sentry';
import { getUser } from '../utils/Auth2';

import AddCompany from '../components/AddCompany';
import PageHeader from '../components/PageHeader';
import Boundary from '../components/Boundary';
import { Flex, Card, Loader } from '../components/primitives';
import { SearchTable, SearchTableContainer } from '../components/SearchTable';
import { VERTICAL_FILTERS, ENTITY_TYPE_FILTERS } from './Engaged';

const Container = styled.div`
  .table-container {
    width: 100%;
    position: relative;

    .column {
      &.col0 {
        width: 10%;
      }

      &.col1 {
        width: 20%;
      }

      &.col4 {
        width: 14%;
      }

      &.col7 {
        width: 13%;
      }
    }

    @media (max-width: 768px) {
      .column {
        width: 16.6% !important;

        &.col1,
        &.col4 {
          display: none;
        }
      }
    }
  }

  @media (max-width: 991px) {
    margin-top: 60px;
  }

  .vertical-filters.btn-group {
    padding: ${({ isMobile }) => (isMobile ? '12px' : '12px 40px')};
  }
`;

const SearchErrorFallback = () => {
  return (
    <SearchTableContainer>
      <Flex
        direction="column"
        fill
        align="center"
        justify="center"
        marginTop={100}
        marginBottom={100}
      >
        <p>There was an issue. Please refresh and try again.</p>
        <p>If the issue persists reach out to the IT team.</p>
      </Flex>
    </SearchTableContainer>
  );
};

const getActive = (filters) => {
  return filters.find((a) => a.active);
};

const SearchContent = (props) => {
  const { verticalFilters, typeFilters } = props;
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [error, setError] = useState(false);
  const [watchlistData, setWatchlistData] = useState(false);
  const user = getUser();

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const queryParams = params.get('q');

    if (!queryParams) {
      setLoading(false);
      return;
    }

    setQuery(queryParams);
    props.setQuery(queryParams);

    const triggerSearch = async () => {
      try {
        let companyResults = await search(queryParams);
        let identicalMatch = null;

        companyResults = companyResults.filter((company) => {
          if (
            !identicalMatch &&
            (company?.company_name?.toLowerCase() === queryParams.toLowerCase() ||
              company?.domain?.toLowerCase() === queryParams.toLowerCase())
          ) {
            identicalMatch = company;
            return false;
          }
          return true;
        });

        if (identicalMatch) {
          companyResults.unshift(identicalMatch);
        }

        setCompanies(companyResults);
        setSearched(true);
      } catch (err) {
        setError(true);
        captureError(`Error searching for ${queryParams}`, err);
      } finally {
        setLoading(false);
      }
    };

    const getWatchlist = async () => {
      try {
        const watchlist = await getUserWatchlistCompanies(user.user_id);
        setWatchlistData(watchlist);
      } catch (err) {
        captureError(err);
      }
    };

    getWatchlist();
    triggerSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let companiesFiltered = companies;
    const activeType = getActive(typeFilters);
    const activeVertical = getActive(verticalFilters);

    if (activeType.name !== 'All') {
      companiesFiltered = companiesFiltered.filter((company) => {
        return company.org_type.toLowerCase() === activeType.name.toLowerCase();
      });
    }

    if (activeVertical.name !== 'All') {
      companiesFiltered = companiesFiltered.filter((company) => {
        if (!company.tv_vertical) return false;
        return company.tv_vertical.toLowerCase().includes(activeVertical.name.toLowerCase());
      });
    }

    setFilteredCompanies(companiesFiltered);
  }, [companies, verticalFilters, typeFilters]);

  const nuxCopy = useMemo(() => {
    if (error) return 'There was an issue. Please refresh and try again.';
    if (!query) return 'Input a search to begin';
    if (searched && filteredCompanies?.length === 0) {
      return `No results found. Please try a new search.`;
    }
    return 'Input a search to begin';
  }, [searched, filteredCompanies, query, error]);

  if (loading || error || !filteredCompanies || filteredCompanies.length === 0) {
    return (
      <SearchTableContainer>
        <Flex fill align="center" justify="center" marginTop={200} marginBottom={200}>
          {loading ? <Loader /> : <h4>{nuxCopy}</h4>}
        </Flex>
      </SearchTableContainer>
    );
  }

  return <SearchTable companies={filteredCompanies} watchlistData={watchlistData} />;
};

const Search = (props) => {
  const [query, setQuery] = useState('');
  const [verticalFilters, setVerticalFilters] = useState(VERTICAL_FILTERS);
  const [typeFilters, setTypeFilters] = useState(ENTITY_TYPE_FILTERS);
  const isMobile = useIsMobile();

  const changeVertical = (e) => {
    const newVertical = e.target.value;
    verticalFilters.map((v) => {
      v.active = newVertical === v.name;
      return v;
    });
    setVerticalFilters([...verticalFilters]);
  };

  const changeType = (e) => {
    const newType = e.target.value;
    typeFilters.map((v) => {
      v.active = newType === v.name;
      return v;
    });
    setTypeFilters([...typeFilters]);
  };

  return (
    <div>
      <Helmet>
        <title>Search - TV CRM</title>
      </Helmet>

      <PageHeader title={query ? `Search Results for "${query}"` : 'Search'} />

      <Container className="container page-container search-container" isMobile={isMobile}>
        <Card
          className="vertical-filters btn-group"
          justify="space-between"
          align="center"
          marginBottom={20}
          height={isMobile ? '100px' : '60px'}
          padding="0"
        >
          <Flex direction={isMobile ? 'column' : 'row'} fill={isMobile}>
            <Flex align="center">
              {!isMobile && <h6>Vertical:</h6>}
              {verticalFilters.map((v) => (
                <button
                  key={`vert-filter-${v.label}`}
                  className={`filter-btn${v.active ? ' active' : ''}`}
                  type="button"
                  onClick={changeVertical}
                  value={v.name}
                >
                  {v.label}
                </button>
              ))}
            </Flex>

            <Flex marginTop={isMobile ? 10 : 0} justify="space-between" align="center" fill>
              <Flex align="center" marginLeft={isMobile ? 0 : 40}>
                {!isMobile && <h6>Type:</h6>}
                {typeFilters.map((v) => (
                  <button
                    key={`vert-filter-${v.label}`}
                    className={`filter-btn${v.active ? ' active' : ''}`}
                    type="button"
                    onClick={changeType}
                    value={v.name}
                  >
                    {v.label}
                  </button>
                ))}
              </Flex>
            </Flex>
          </Flex>

          {!isMobile && <AddCompany />}
        </Card>

        <Boundary fallback={<SearchErrorFallback />} errorFallback={<SearchErrorFallback />}>
          <SearchContent
            {...props}
            setQuery={setQuery}
            typeFilters={typeFilters}
            verticalFilters={verticalFilters}
          />
        </Boundary>
      </Container>
    </div>
  );
};

export default Search;

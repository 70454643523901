import React from 'react';

const LightningBoltIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 30 30" version="1.1">
      <title>lightning-bolt1-icon</title>
      <g
        id="lightning-bolt1-icon"
        stroke="none"
        strokeWidth="1"
        fill={props && props.fill ? props.fill : 'none'}
        fillRule="evenodd"
      >
        <g
          id="003-flash"
          transform="translate(6.000000, 0.000000)"
          fill={props && props.fill ? props.fill : '#ED3B64'}
          fillRule="nonzero"
        >
          <path
            d="M18.9113672,14.4503906 C18.7740234,14.035957 18.4266211,13.7261719 17.9991211,13.6366406 L12.1152539,12.4051758 L13.890293,1.42224609 C13.9781836,0.877851562 13.6920703,0.341601562 13.1905664,0.111914063 C12.6886523,-0.118183594 12.0960352,0.0146484375 11.74125,0.437226563 L0.297304688,14.0473828 C0.0369726563,14.357168 -0.0537890625,14.7761133 0.0549609375,15.1660547 C0.163652344,15.5559961 0.458378906,15.8674219 0.841757813,15.9973828 L6.54861328,17.9342578 L4.24716797,28.5130078 C4.12863281,29.0586328 4.39347656,29.615332 4.89169922,29.8674609 C5.06783203,29.956582 5.2575,29.9994727 5.44511719,29.9994727 C5.78841797,29.9994727 6.12603516,29.8552148 6.36474609,29.5846289 L18.6670312,15.6482813 C18.9558984,15.320918 19.0491211,14.8648242 18.9113672,14.4503906 Z M7.64560547,24.4275 L9.17789063,17.3853516 C9.31072266,16.7755664 8.96455078,16.1637305 8.37392578,15.963457 L3.32917969,14.2513477 L10.7616211,5.41289062 L9.50806641,13.1690625 C9.40341797,13.8156445 9.82605469,14.4303516 10.4673047,14.5648242 L15.4344141,15.6041602 L7.64560547,24.4275 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};

export default LightningBoltIcon;

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { addTVCustomCompany, addCompanyVertical } from '../BackendAPI';
import Button from './primitives/Button';
import Flex from './primitives/Flex';

const AddCompany = () => {
  const history = useHistory();
  const [companyName, setCompanyName] = useState('');
  const [homepageUrl, setHomepageUrl] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [location, setLocation] = useState('');
  const [foundersName, setFoundersName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [verticals, setVerticals] = useState([
    { vertical: 'AI/ML', id: 2, checked: false },
    { vertical: 'Cloud Infrastructure', id: 3, checked: false },
    { vertical: 'Cybersecurity', id: 4, checked: false },
    { vertical: 'Financial Technology', id: 5, checked: false },
    { vertical: 'Marketing Technology', id: 6, checked: false },
    { vertical: 'Real Estate', id: 7, checked: false },
    { vertical: 'Insurance Technology', id: 8, checked: false },
  ]);
  const [stages] = useState([
    'N/A',
    'Seed',
    'Series A',
    'Series B',
    'Series C',
    'Series D',
    'Series E',
  ]);
  const [lastInvestmentType, setLastInvestmentType] = useState('N/A');
  const [error, setError] = useState('');

  const close = () => {
    setVerticals(verticals.map((v) => ({ ...v, checked: false })));
    setShowModal(false);
    setCompanyName('');
    setShortDescription('');
    setHomepageUrl('');
    setLocation('');
    setFoundersName('');
    setLastInvestmentType('N/A');
    setError('');
  };

  const open = () => setShowModal(true);

  const flipBox = (i) => {
    const updatedVerticals = verticals.map((v, index) =>
      index === i ? { ...v, checked: !v.checked } : v,
    );
    setVerticals(updatedVerticals);
  };

  const getVerticalString = () => {
    return verticals
      .filter((v) => v.checked)
      .map((v) => v.vertical)
      .join(',');
  };

  const submitNewCompany = () => {
    const tvVertical = getVerticalString();
    if (!companyName || !homepageUrl || !shortDescription || !location || !foundersName) {
      setError('Please complete all fields with *');
      return;
    }

    addTVCustomCompany(
      companyName,
      homepageUrl,
      shortDescription,
      lastInvestmentType,
      location,
      foundersName,
      tvVertical,
    )
      .then((data) => {
        if (data.status !== 'success') {
          setError(data?.message ?? 'Error creating new company. Please contact admin.');
        } else {
          // Assuming addCompanyVertical is an external function
          verticals.forEach((v) => {
            if (v.checked) {
              addCompanyVertical(data.permalink, v);
            }
          });
          close();
          history.push(`/company/${data.permalink}`);
        }
      })
      .catch((err) => {
        setError(err?.message ?? 'Error creating new company. Please contact admin.');
      });
  };

  return (
    <div>
      <div className="add-company-button">
        <Button onClick={open}>
          <p>Add New Company</p>
        </Button>
      </div>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={close}
        className="tv-modal tv-company-modal"
      >
        <Modal.Header>
          <Flex fill justify="space-between">
            <Modal.Title>Add Company to CRM</Modal.Title>
            <button className="modal-close" type="button" onClick={close}>
              <i className="fa fa-times" />
            </button>
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled list-spaced">
            <li>
              <label>Company Name*</label>
              <input
                type="text"
                className="form-control required"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </li>
            <li>
              <label>Website*</label>
              <input
                type="text"
                className="form-control"
                value={homepageUrl}
                onChange={(e) => setHomepageUrl(e.target.value)}
              />
            </li>
            <li>
              <label>Description*</label>
              <textarea
                className="form-control"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </li>
            <li>
              <label>Stage</label>
              <select
                className="tv-select form-control"
                value={lastInvestmentType}
                onChange={(e) => setLastInvestmentType(e.target.value)}
              >
                {stages.map((s) => (
                  <option key={s}>{s}</option>
                ))}
              </select>
            </li>
            <li>
              <label>HQ City*</label>
              <input
                type="text"
                className="form-control"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </li>
            <li>
              <label>Founders*</label>
              <input
                type="text"
                className="form-control"
                value={foundersName}
                onChange={(e) => setFoundersName(e.target.value)}
              />
            </li>
            <li className="form-section-header">
              <label>Vertical(s)</label>
            </li>

            {verticals.map((v, i) => (
              <li key={v.id} className="form-checkbox-row">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={v.checked}
                      value={v.id}
                      onClick={() => flipBox(i)}
                      onChange={() => null}
                    />
                    {v.vertical}
                    <i className="fa fa-circle-thin unchecked" aria-hidden="true" />
                    <i className="fa fa-check-circle checked" aria-hidden="true" />
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <p className="error">{error}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={submitNewCompany}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCompany;

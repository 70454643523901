import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  line-height: 16px;
  min-height: 28px;
  display: inline-block;
  padding: 6px 15px;
  font-size: 12px;
  transition: 300ms all;
  min-width: 70px;
  border-radius: 4px;
  font-weight: 700;
  ${({ fill }) => fill && 'width: 100%;'}
  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  ${({ disabled }) =>
    disabled
      ? `
    border: 1px solid #ddd;
    background-color: #f2f2f2;
    color: #bbbbbb;
    `
      : `
    border: 1px solid #ddd;
    background-color: #f2f2f2;
    color: #323232;
    box-shadow: 0 1px 1px 0 #ddd;
  `}
  ${({ active }) =>
    active &&
    `
    color: #fff;
    background-color: #2579a9;
    background-image: none;
    border-color: #2473a0;
  `}
    ${({ rounded }) =>
    rounded &&
    `
      border-radius: 99px;
      min-width: 50px;
  `}
      ${({ variant }) =>
    variant === 'secondary' &&
    `
      background-color: #fcfcfc;
  `}
`;

const Button = ({
  children,
  onClick,
  type = 'button',
  disabled = false,
  fill,
  active = false,
  variant = 'standard',
  rounded,
  className = '',
}) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      fill={fill}
      active={active}
      rounded={rounded}
      variant={variant}
      className={className}
    >
      {children}
    </StyledButton>
  );
};

export default Button;

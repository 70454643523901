import React, { memo } from 'react';
import classnames from 'classnames';

import getIcon from './getIcon';
import './styles.css';

const Icon = (props) => (
  <div
    onClick={() => props.onClick && props.onClick()}
    className={classnames('icon-wrapper', props.className)}
  >
    {getIcon(props)}
  </div>
);

export default memo(Icon);

import React, { useEffect, useState, memo, useCallback } from 'react';
import styled from 'styled-components';
import WatchlistPanel from '../WatchlistPanel';
import { getUserWatchlistCompanies } from '../../BackendAPI';
import { Flex, Card, Loader, Button } from '../primitives';
import Boundary from '../Boundary';
import { getUser } from '../../utils/Auth2';

const DISPLAY_INCREMENTS = 3;

const WatchlistWidgetErrorFallback = () => {
  return (
    <Card justify="center" fill>
      <p>There was an issue loading your watchlist</p>
    </Card>
  );
};

const LoadingContainer = styled(Card)`
  border-top: none;
`;
const WatchlistWidgetLoadingFallback = () => {
  return (
    <LoadingContainer
      padding="0"
      direction="column"
      borderRadius={false}
      boxShadow={false}
      fill
      justify="center"
      height="265px"
      align="center"
    >
      <Loader />
    </LoadingContainer>
  );
};

const OuterContainer = styled(Flex)`
  padding-bottom: 20px;
  border-top: 1px solid #ebeaea;
`;

function WatchlistWidgetContent() {
  const [initialFetched, setInitialFetched] = useState(false);
  // const [fetched, setFetched] = useState(0);
  const [numDisplay, setNumDisplay] = useState(DISPLAY_INCREMENTS);
  const [watchlistCompanies, setWatchlistCompanies] = useState([]);
  const user = getUser();

  const increment = () => setNumDisplay((i) => i + DISPLAY_INCREMENTS);
  const decrement = () => setNumDisplay((i) => i - DISPLAY_INCREMENTS);

  const moreWatchlistCompanies = numDisplay < watchlistCompanies?.length;

  // const getCompanyFeedUpdates = useCallback(async (permalink) => {
  //   const companyFeed = await getCompanyFeed(permalink);
  //   const updates = companyFeed.reduce((count, item) => count + isRecent(item.feed_time), 0);

  //   setFetched((a) => a + 1);

  //   return updates;
  // }, []);

  const getWatchlistCompanies = useCallback(async () => {
    const companies = await getUserWatchlistCompanies(user.user_id);

    // const fetchedWatchlistCompanies = await Promise.all(
    //   companies.map(async (c) => {
    //     const companyFeedUpdates = await getCompanyFeedUpdates(c.permalink);
    //     c.updates = companyFeedUpdates;
    //     return c;
    //   }),
    // );

    setWatchlistCompanies(companies);
  }, [user?.user_id]);

  useEffect(() => {
    if (user && !initialFetched) {
      setInitialFetched(true);
      getWatchlistCompanies();
    }
  }, [user, initialFetched, getWatchlistCompanies]);

  return (
    <OuterContainer direction="column" fill>
      {/* {fetched === watchlistCompanies.length && */}
      {watchlistCompanies?.length > 0 ? (
        <Card
          padding="0"
          direction="column"
          borderRadius={false}
          boxShadow={false}
          border={false}
          fill
        >
          {watchlistCompanies
            .sort((a, b) => b.updates - a.updates)
            .slice(0, numDisplay)
            .map((c) => (
              <WatchlistPanel company={c} key={c.permalink} />
            ))}
        </Card>
      ) : (
        <WatchlistWidgetLoadingFallback />
      )}

      {watchlistCompanies?.length > DISPLAY_INCREMENTS && (
        <Flex fill justify="center">
          <Button rounded onClick={moreWatchlistCompanies ? increment : decrement}>
            {moreWatchlistCompanies ? 'See More' : 'See Less'}
          </Button>
        </Flex>
      )}
    </OuterContainer>
  );
}

const WatchlistWidget = () => {
  return (
    <Boundary
      fallback={<WatchlistWidgetErrorFallback />}
      loadingFallback={<WatchlistWidgetLoadingFallback />}
    >
      <Flex direction="column" fill>
        <h6 className="section-header widget">My Watchlist Companies</h6>
        <WatchlistWidgetContent />
      </Flex>
    </Boundary>
  );
};

export default memo(WatchlistWidget);

import React, { useEffect, memo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { getHighlights } from '../../BackendAPI';
import { Flex, Button, Loader } from '../primitives';

const P = styled.p`
  font-size: 12.5px;
`;

const H6 = styled.h6`
  font-size: 14px;
  font-weight: 700 !important;
`;

const DISPLAY_INCREMENTS = 3;

const CompanyHighlights = ({ crunchbaseUrl }) => {
  const [highlightsData, setHighlightsData] = useState([]);
  const [noHighlights, setNoHighlights] = useState(false);
  const [numDisplay, setNumDisplay] = useState(DISPLAY_INCREMENTS);

  const increment = () => setNumDisplay((i) => i + DISPLAY_INCREMENTS);
  const decrement = () => setNumDisplay((i) => i - DISPLAY_INCREMENTS);

  const stillHighlightsToSee = numDisplay < highlightsData?.length;

  const fetchHighlights = useCallback(async () => {
    try {
      const data = await getHighlights(crunchbaseUrl);
      if (Array.isArray(data) && data?.length > 0) {
        setHighlightsData(data);
      } else {
        setNoHighlights(true);
      }
    } catch (error) {
      setNoHighlights(true);
    }
  }, [crunchbaseUrl]);

  useEffect(() => {
    if (crunchbaseUrl) {
      fetchHighlights();
    }
  }, [crunchbaseUrl, fetchHighlights]);

  if (noHighlights || !highlightsData) {
    return (
      <Flex className="content-container card" fill>
        <div className="card-block">
          <h3>Harmonic Highlights</h3>
          <Flex direction="column">
            <label>No Data</label>
          </Flex>
        </div>
      </Flex>
    );
  }

  if (highlightsData?.length === 0) {
    return (
      <Flex align="center" justify="center" fill height="300px">
        <Loader />
      </Flex>
    );
  }

  return (
    <div className="content-container card">
      <div className="card-block">
        <h3>Harmonic Highlights</h3>

        <Flex direction="column">
          {highlightsData.slice(0, numDisplay).map((highlight, index) => (
            <Flex key={index} direction="column" marginTop={index > 0 ? '20' : '0'}>
              <H6>{highlight.category}</H6>
              <P>{highlight.text}</P>
            </Flex>
          ))}
        </Flex>

        {highlightsData?.length > DISPLAY_INCREMENTS && (
          <Flex fill justify="center" marginTop="18">
            <Button onClick={stillHighlightsToSee ? increment : decrement} rounded>
              {stillHighlightsToSee ? 'See More' : 'See Less'}
            </Button>
          </Flex>
        )}
      </div>
    </div>
  );
};

export default memo(CompanyHighlights);

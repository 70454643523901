import React from 'react';

const TrashIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 30 30" version="1.1">
      <title>trash-icon</title>
      <g id="trash-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="002-trash"
          transform="translate(3.000000, 0.000000)"
          fill="#ED3B64"
          fillRule="nonzero"
        >
          <path
            d="M22.03125,3.75 L16.875,3.75 L16.875,2.8125 C16.875,1.26169922 15.6133008,0 14.0625,0 L10.3125,0 C8.76169922,0 7.5,1.26169922 7.5,2.8125 L7.5,3.75 L2.34375,3.75 C1.05140625,3.75 0,4.80140625 0,6.09375 L0,9.375 C0,9.89273437 0.419765625,10.3125 0.9375,10.3125 L1.44984375,10.3125 L2.25978516,27.3212695 C2.33132813,28.8233789 3.5653125,30 5.0690625,30 L19.3059375,30 C20.8097461,30 22.0437305,28.8233789 22.1152148,27.3212695 L22.9251562,10.3125 L23.4375,10.3125 C23.9552344,10.3125 24.375,9.89273437 24.375,9.375 L24.375,6.09375 C24.375,4.80140625 23.3235938,3.75 22.03125,3.75 Z M9.375,2.8125 C9.375,2.29558594 9.79558594,1.875 10.3125,1.875 L14.0625,1.875 C14.5794141,1.875 15,2.29558594 15,2.8125 L15,3.75 L9.375,3.75 L9.375,2.8125 Z M1.875,6.09375 C1.875,5.83529297 2.08529297,5.625 2.34375,5.625 L22.03125,5.625 C22.289707,5.625 22.5,5.83529297 22.5,6.09375 L22.5,8.4375 C22.2110742,8.4375 3.07224609,8.4375 1.875,8.4375 L1.875,6.09375 Z M20.2423242,27.2320898 C20.2184766,27.7327734 19.8071484,28.125 19.3059375,28.125 L5.0690625,28.125 C4.56779297,28.125 4.15646484,27.7327734 4.13267578,27.2320898 L3.32695313,10.3125 L21.0480469,10.3125 L20.2423242,27.2320898 Z"
            id="Shape"
          />
          <path
            d="M12.1875,26.25 C12.7052344,26.25 13.125,25.8302344 13.125,25.3125 L13.125,13.125 C13.125,12.6072656 12.7052344,12.1875 12.1875,12.1875 C11.6697656,12.1875 11.25,12.6072656 11.25,13.125 L11.25,25.3125 C11.25,25.8302344 11.669707,26.25 12.1875,26.25 Z"
            id="Path"
          />
          <path
            d="M16.875,26.25 C17.3927344,26.25 17.8125,25.8302344 17.8125,25.3125 L17.8125,13.125 C17.8125,12.6072656 17.3927344,12.1875 16.875,12.1875 C16.3572656,12.1875 15.9375,12.6072656 15.9375,13.125 L15.9375,25.3125 C15.9375,25.8302344 16.357207,26.25 16.875,26.25 Z"
            id="Path"
          />
          <path
            d="M7.5,26.25 C8.01773437,26.25 8.4375,25.8302344 8.4375,25.3125 L8.4375,13.125 C8.4375,12.6072656 8.01773437,12.1875 7.5,12.1875 C6.98226562,12.1875 6.5625,12.6072656 6.5625,13.125 L6.5625,25.3125 C6.5625,25.8302344 6.98220703,26.25 7.5,26.25 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
};

export default TrashIcon;

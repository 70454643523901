import React, { memo } from 'react';
import slugify from 'slugify';
import { VERT_MAP } from '../../constants';
import '../../styles/components/CompanyFeedItem.css';
import { Flex } from './index';

const VerticalIcons = ({ tvVertical }) => {
  const tv_verticals = tvVertical
    ? tvVertical.split(',').map((vert) => VERT_MAP[vert.trim()])
    : null;

  if (tv_verticals?.length > 0) {
    return (
      <Flex marginLeft="10">
        {tv_verticals?.map((a) => (
          <span className={`watchlistPanel__vertical ${slugify(a).toLowerCase()}`} key={a}>
            {a}
          </span>
        ))}
      </Flex>
    );
  }

  return null;
};

export default memo(VerticalIcons);

import React, { createContext, useReducer } from 'react';
import { getUserEmail } from '../../utils/Auth';

export const AdvancedSearchV2Context = createContext();

const INITIAL_STATE = {
  verticals: [
    { label: 'FinTech', value: 'FinTech', checked: true, onClass: 'ft' },
    { label: 'Real Estate', value: 'Real Estate', checked: false, onClass: 're' },
    { label: 'Cloud', value: 'Cloud', checked: false, onClass: 'ci' },
    { label: 'Cybersecurity', value: 'Security', checked: false, onClass: 'cs' },
    { label: 'AI/ML', value: 'AI/ML', checked: false, onClass: 'ai' },
    { label: 'MarTech', value: 'MarTech', checked: false, onClass: 'mt' },
    { label: 'Insurtech', value: 'Insurtech', checked: false, onClass: 'it' },
  ],
  vc_score: [
    { label: '1+', value: '1', checked: true, onClass: 'cs' },
    { label: '3+', value: '3', checked: false, onClass: 'cs' },
    { label: '5+', value: '5', checked: false, onClass: 'cs' },
  ],
  stage: [
    { label: 'Early', value: 'Early', checked: true, onClass: 'cs' },
    { label: 'Mid', value: 'Mid', checked: true, onClass: 'cs' },
    { label: 'Late', value: 'Late', checked: true, onClass: 'cs' },
  ],
  vc_expertise: [
    { label: 'FT', value: 'FinTech', checked: false, onClass: 'ft' },
    { label: 'RE', value: 'Real Estate', checked: false, onClass: 're' },
    { label: 'CI', value: 'Cloud', checked: false, onClass: 'ci' },
    { label: 'CS', value: 'Security', checked: false, onClass: 'cs' },
    { label: 'AI', value: 'AI/ML', checked: false, onClass: 'ai' },
    { label: 'MT', value: 'MarTech', checked: false, onClass: 'mt' },
    { label: 'IT', value: 'Insurtech', checked: false, onClass: 'it' },
  ],
  round_type: [{ label: 'Show All Rounds', value: true, checked: false, onClass: 'cs' }],
};

const getVerticalPerUser = (userEmail) => {
  switch (userEmail) {
    case 'don@thomvest.com':
      return 'FinTech';
    case 'dave@thomvest.com':
      return 'FinTech';
    case 'ashish @thomvest.com':
      return 'Cloud';
    case 'umesh@thomvest.com':
      return 'Cloud';
    case 'nima@thomvest.com':
      return 'Real Estate';
    case 'alex@thomvest.com':
      return 'Real Estate';
    case 'colin@thomvest.com':
      return 'Real Estate';
    default:
      return 'FinTech';
  }
};

const getInitialState = (params, userEmail) => {
  const initialState = JSON.parse(JSON.stringify(INITIAL_STATE));
  const vertical = getVerticalPerUser(userEmail);

  if (Object.keys(params).length === 0) {
    initialState.verticals.map((v) => {
      v.checked = v.label === vertical || v.value === vertical;
      return v;
    });
    return initialState;
  }

  for (let [filterList, queryParamValues] of Object.entries(params)) {
    if (filterList === 'verticals') {
      queryParamValues = [vertical];
    }
    if (filterList && initialState[filterList]) {
      initialState[filterList].map((filter) => {
        const hasValue = queryParamValues.find((v) => v === filter.value || v === filter.label);
        filter.checked = !!hasValue;
        return filter;
      });
    }
  }

  return initialState;
};

const reducer = (state, action) => {
  if (!state) return null;

  if (action === 'reset') {
    return { ...JSON.parse(JSON.stringify(INITIAL_STATE)) };
  }

  const { filterList, value } = action;

  const isMultiSelect = filterList === 'vc_expertise' || filterList === 'stage';

  if (isMultiSelect) {
    state[filterList].map((filter) => {
      if (filter.value === value) {
        filter.checked = !filter.checked;
      }
      return filter;
    });
  } else {
    state[filterList].map((filter) => {
      filter.checked = filter.value === value;
      return filter;
    });
  }

  const newState = { ...state };
  return newState;
};

export const AdvancedSearchV2Provider = ({ params, children }) => {
  const userEmail = getUserEmail();
  const initialState = getInitialState(params, userEmail);
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AdvancedSearchV2Context.Provider value={[state, dispatch]}>
      {children}
    </AdvancedSearchV2Context.Provider>
  );
};

import React, { useState, useContext, useEffect, useCallback, memo } from 'react';
import styled from 'styled-components';
import { differenceInDays } from 'date-fns';
import { isEqual } from 'lodash';
import Boundary from '../Boundary';
import { AdvancedSearchV2Context } from '../advancedSearch/AdvancedSearchV2Context';
import AdvancedSearchV2DealStats from '../advancedSearch/AdvancedSearchV2DealStats';
import { displayMoney, displayInvestmentType } from '../../util';
import { formatQueryParams, FILTERED_ROUND_TYPES } from '../advancedSearch/AdvancedSearchContentV2';
import { getDealFeedCompanies } from '../../BackendAPI';
import captureError from '../../utils/sentry';
import useIsMobile from '../../hooks/useIsMobile';
import { Flex, VerticalIcons, Card, Loader, Button } from '../primitives';
import HoverLink from '../HoverLink';
import { ScrollContainer } from '../ListPanel';

const Container = styled(Flex)`
  ${({ isMobile }) =>
    isMobile &&
    `
      width: 100%;
      padding-bottom: 50px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-bottom:120px;
  `}

  .company-profile {
    padding: 0;
  }

  .subverticals-container {
    flex-wrap: wrap;
    margin-top: 8px;
    .vertical-item {
      flex: 0 0 auto;
    }
  }

  border-radius: 0 0 4px 4px;
`;

const DealContainer = styled(Flex)`
  p {
    font-size: 12px;
    line-height: 14px;
  }

  .deal {
    border-radius: unset;
    margin-bottom: 0;
    border-bottom: none;
    &:first-child {
      border-top: none;
    }
    .amount-round {
      margin-bottom: 8px;
    }
  }
`;

const Fallback = ({ isError, noResults, onClick }) => {
  const isMobile = useIsMobile();

  return (
    <Container direction="column" fill isMobile={isMobile}>
      <h6 className="section-header widget">
        Deal Feed
        <VerticalIcons tvVertical={'FinTech'} />
      </h6>
      {!isError && !noResults && (
        <Flex className="company-profile" fill>
          <AdvancedSearchV2DealStats companies={[]} isLoading interval="7" isWidget />
        </Flex>
      )}
      <Card align="center" justify="center" fill borderRadius={false} height="calc(100vh - 200px)">
        {isError || noResults ? (
          <Flex direction="column" align="center" justify="center">
            {isError ? (
              <p style={{ textAlign: 'center' }}>
                There was an issue. If the issue persists contact IT.
              </p>
            ) : (
              <p style={{ textAlign: 'center' }}>There are no deals in the past 7 days</p>
            )}
            {onClick && <Button onClick={onClick}>Try Again</Button>}
          </Flex>
        ) : (
          <Loader />
        )}
      </Card>
    </Container>
  );
};

const Deal = ({ company }) => {
  const { permalink, company_name, key_investors, raised_amount_usd, last_round, subvertical } =
    company;

  return (
    <Card direction="column" className="deal" padding="20px" fill>
      <HoverLink orgType="company" permalink={permalink} companyName={company_name} />
      <Flex className="amount-round">
        <p>
          {raised_amount_usd && `${displayMoney(raised_amount_usd)} - `}{' '}
          {displayInvestmentType(last_round)}
        </p>
      </Flex>
      <Flex direction="column" className="key-investors">
        <label>key investors</label>
        <ScrollContainer>
          <p>{key_investors.join(', ')}</p>
        </ScrollContainer>
      </Flex>
      <Flex className="subverticals-container">
        {subvertical?.map((a) => (
          <span className="vertical-item" key={a}>
            {a}
          </span>
        ))}
      </Flex>
    </Card>
  );
};

const DealFeedSidebarContent = () => {
  const [companies, setCompanies] = useState([]);
  const [state] = useContext(AdvancedSearchV2Context);
  const [noResults, setNoResults] = useState(false);
  const [hasError, setError] = useState(false);
  const [prevSearchFilters, setPrevSearchFilters] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();
  const verticals = state?.verticals
    ?.filter((v) => v.checked)
    ?.map((v) => v.label)
    .join(', ');

  const fetchDealFeedCompanies = useCallback(async () => {
    setError(false);
    setIsLoading(true);
    try {
      const queryParams = formatQueryParams(state);
      queryParams.interval = '7';
      const results = await getDealFeedCompanies(queryParams);

      const fundedCompanies = results
        .filter((co) => {
          if (queryParams.round_type) return true;
          return co.last_round && !FILTERED_ROUND_TYPES.includes(co.last_round);
        })
        .map((co) => {
          if (co.round_date) {
            if (differenceInDays(new Date(), new Date(co.round_date)) <= 7) {
              return { ...co, is_recent: true };
            }
          }
          return { ...co, is_recent: false };
        });
      if (fundedCompanies.length > 0) {
        setCompanies(fundedCompanies);
      } else {
        setNoResults(true);
      }
    } catch (error) {
      setError(error);
      captureError(error);
    } finally {
      setIsLoading(false);
    }
  }, [state]);

  useEffect(() => {
    if (!isEqual(state, prevSearchFilters)) {
      fetchDealFeedCompanies();
      setPrevSearchFilters(JSON.parse(JSON.stringify(state)));
    }
  }, [state, companies, prevSearchFilters, fetchDealFeedCompanies]);

  if (hasError) {
    return <Fallback isError onClick={fetchDealFeedCompanies} />;
  }

  if (isLoading) {
    return <Fallback />;
  }

  if (noResults) {
    return <Fallback noResults />;
  }

  return (
    <Container direction="column" fill isMobile={isMobile}>
      <Flex direction="column" fill>
        <Flex fill justify="space-between">
          <h6 className="section-header widget">
            Deal Feed
            <VerticalIcons tvVertical={verticals} />
          </h6>
        </Flex>
        <Flex fill direction="column" className="company-profile">
          <AdvancedSearchV2DealStats
            companies={companies}
            isLoading={isLoading}
            interval="7"
            isWidget
          />

          <DealContainer fill direction="column">
            {companies.map((co) => (
              <Deal key={co.permalink} company={co} />
            ))}
          </DealContainer>
        </Flex>
      </Flex>
    </Container>
  );
};

function DealFeedSidebar() {
  return (
    <Boundary fallback={<Fallback isError />} loadingFallback={<Fallback />}>
      <DealFeedSidebarContent />
    </Boundary>
  );
}

export default memo(DealFeedSidebar);
//

import React, { useContext, useMemo, memo } from 'react';
import { AdvancedSearchV2Context } from './AdvancedSearchV2Context';

const InlineLabel = ({ filter, toggle }) => {
  const labelText = filter.label.toLowerCase();
  const isVertical = ['ft', 're', 'mt', 'ci', 'cs'].includes(labelText);

  const labelClasses = useMemo(() => {
    let labelClass = 'cs';
    if (isVertical) {
      labelClass = filter.checked ? labelText : 'empty';
    } else {
      labelClass = filter.checked ? 'ft' : 'empty';
    }
    return labelClass;
  }, [filter?.checked, isVertical, labelText]);

  const handleClick = () => {
    if (filter.label === 'Show All Rounds') {
      toggle(!filter.checked);
    } else {
      toggle(filter.value);
    }
  };

  return (
    <span
      className={`vertical-item ${labelClasses}`}
      key={`tier${filter.label}`}
      onClick={handleClick}
    >
      {filter.label}
    </span>
  );
};

const AdvancedSearchV2Filter = ({ filterList, filterLabel }) => {
  const [state, dispatch] = useContext(AdvancedSearchV2Context);
  const toggle = (value) => dispatch({ filterList, value });

  const filters = state[filterList];

  return (
    <div className="company-score vertical-expertise">
      <div>
        <label>{filterLabel}</label>
        <span className="score">
          {filters.map((filter, index) => (
            <InlineLabel key={index} filter={filter} toggle={toggle} />
          ))}
        </span>
      </div>
    </div>
  );
};

export default memo(AdvancedSearchV2Filter);

import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';

const CloseButton = ({ onClick, type = 'button', className = '' }) => {
  return (
    <BootstrapButton type={type} onClick={onClick} className={className}>
      <i className="fa fa-times" />
    </BootstrapButton>
  );
};

export default CloseButton;

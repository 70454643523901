import React, { useContext } from 'react';

import { ProspectContext } from '../../contexts/ProspectContext';
import { Button } from '../primitives';

export default function ProspectButton() {
  const [state, dispatch] = useContext(ProspectContext);

  const status = state.status.options.find((s) => s.active === true)?.value;
  const isActive = !!state.prospectId;
  const showProspectButton = !isActive || status === 'Dormant' || status === 'Passed';

  if (showProspectButton) {
    return (
      <div>
        <Button
          onClick={() => dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: false } })}
        >
          + Prospect
        </Button>
      </div>
    );
  }

  return null;
}

import React, { useContext, useEffect } from 'react';
import slugify from 'slugify';
import { CompanyVerticalContext } from '../contexts/CompanyVerticalContext';
import VerticalModal from './VerticalModal';
import { getVerticals, getCompanyVerticals, getHarmonicVerticals } from '../BackendAPI';
import { Flex, Loader } from './primitives';

export default function VerticalSection({ permalink }) {
  const [state, dispatch] = useContext(CompanyVerticalContext);
  const { verticals } = state;

  useEffect(() => {
    const getAndMapVerticals = async () => {
      const verts = await getVerticals();

      const companyVerticals = await getCompanyVerticals(permalink);
      const companyVerts = companyVerticals
        .filter((v) => v.is_vertical)
        .map((v) => v.short_vertical);

      const harmonicVerticals = await getHarmonicVerticals(permalink);
      const harmonicVerts = harmonicVerticals.map((v) => v.tv_vertical);

      verts.map((v) => {
        const hasCompanyVertical = companyVerts.includes(v.short_vertical);
        const hasHarmonicVertical = harmonicVerts.includes(v.vertical);
        v.checked = hasCompanyVertical || hasHarmonicVertical;
        return v;
      });

      dispatch({ type: 'UPDATE_VERTICALS', value: verts });
    };

    getAndMapVerticals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (verticals.length === 0) {
    return (
      <Flex align="center" justify="center" fill height="120px">
        <Loader />
      </Flex>
    );
  }

  const checkedVerticals = verticals
    .filter((v) => v.checked)
    .map((v) => v.short_vertical)
    .sort();

  return (
    <div className="card-block">
      <h3>Verticals</h3>
      {checkedVerticals.map((a) => (
        <span className={`vertical-item ${slugify(a).toLowerCase()}`} key={a}>
          {a}
        </span>
      ))}
      <div>
        <button
          type="button"
          className="tv-button"
          onClick={() => dispatch({ type: 'TOGGLE_VERTICAL_MODAL', value: true })}
        >
          Edit
        </button>
      </div>
      <VerticalModal verticals={verticals} permalink={permalink} />
    </div>
  );
}

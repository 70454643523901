import React, { useState, memo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import '../styles/components/Header.css';
import { getLocalValue, AUTH_TOKEN_KEY } from '../utils/Auth';
import SearchBox from './SearchBox.tsx';

const SUPPORT_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSc5KQsESiJc-w_h8d-XfHqojtjd1DzR0BcVEcsHtyALh51SSQ/viewform';
const AFFINITY_URL = 'https://thomvest.affinity.co/';
const FINANCIAL_TRACKING_URL =
  'https://docs.google.com/spreadsheets/d/13o_KTqMn6jScfgufByBf2RVF1YBOufQzHaprr5XWZDY/edit#gid=401701657';
const BOX_URL = 'https://thomvest.account.box.com/';
const HARMONIC_URL = 'https://console.harmonic.ai/';
const GOALS_URL = 'https://airtable.com/appwLx1CP06Kn5MeJ/pagqpGvlchjgQ5fyJ';

const COLINS_USER_ID = 37;
const NIMAS_USER_ID = 2;
const MITCHELLS_USER_ID = 35;
const ADMIN_USERS = [COLINS_USER_ID, NIMAS_USER_ID, MITCHELLS_USER_ID];

const Sidebar = ({ logout }) => {
  const [nav, toggleNav] = useState(false);
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    logout(() => history.push('/login'));
  };

  function LogoutButton() {
    return (
      <li>
        <a className="logout-button" onClick={(e) => handleLogout(e)}>
          Logout
        </a>
      </li>
    );
  }

  return (
    <div className={`navigation-container${nav ? ' toggled' : ''}`} role="navigation">
      <div className="navbar-header">
        <a to="/" className="navbar-brand tv-nav">
          TV<i>CRM</i>
        </a>
      </div>

      <ul className="nav navbar-nav hidden-lg-down">
        <PageLinks />
        <li className="nav-search">
          <SearchBox />
        </li>
        <QuickLinks />
        <LogoutButton />
      </ul>

      <ul className={'nav navbar-nav mobile-nav hidden-xl-up'}>
        <PageLinks />
        <li className="nav-search">
          <SearchBox />
        </li>
        <QuickLinks />
        <LogoutButton />
      </ul>

      <button className="menu-toggle hidden-xl-up" onClick={(e) => toggleNav(!nav)}>
        <div className="menu-icon">
          <span />
          <span />
          <span />
        </div>
      </button>
    </div>
  );
};

function PageLinks() {
  const localUser = JSON.parse(getLocalValue(AUTH_TOKEN_KEY));

  return (
    <>
      <li>
        <a href="/" className="tv-nav">
          Dashboard
        </a>
      </li>
      <li>
        <a href="/watchlist" className="tv-nav">
          Watchlist
        </a>
      </li>
      <li>
        <a href="/engaged?status=Prospect" className="tv-nav">
          Engaged
        </a>
      </li>
      <li>
        <a href="/deal-feed" className="tv-nav">
          Deal Feed
        </a>
      </li>
      {ADMIN_USERS.includes(localUser?.user_id) && (
        <li>
          <Link to="/admin" className="tv-nav">
            Admin
          </Link>
        </li>
      )}
      <li>
        <a href={SUPPORT_URL} target="_blank" className="tv-nav" rel="noreferrer">
          Support
        </a>
      </li>
    </>
  );
}

function QuickLinks() {
  return (
    <>
      <li className="header__section-header">Quick Links</li>
      <li>
        <a href={AFFINITY_URL} target="_blank" className="header__quick-link" rel="noreferrer">
          🔗 Affinity
        </a>
      </li>
      <li>
        <a href={BOX_URL} target="_blank" className="header__quick-link" rel="noreferrer">
          🔗 Box Folder
        </a>
      </li>
      <li>
        <a href={HARMONIC_URL} target="_blank" className="header__quick-link" rel="noreferrer">
          🔗 Harmonic
        </a>
      </li>
      <li>
        <a href={GOALS_URL} target="_blank" className="header__quick-link" rel="noreferrer">
          🔗 2023 Goals
        </a>
      </li>
      <li>
        <a
          href={FINANCIAL_TRACKING_URL}
          target="_blank"
          className="header__quick-link"
          rel="noreferrer"
        >
          🔗 Financial Tracking
        </a>
      </li>
      <li>
        <a
          href="https://lookerstudio.google.com/u/2/reporting/61012163-03f5-4c75-bc87-58b3dcc41cf8/page/dvoKD"
          target="_blank"
          className="header__quick-link"
          rel="noreferrer"
        >
          🔗 Fund III Report
        </a>
      </li>
    </>
  );
}

export default memo(Sidebar);

import { useState } from 'react';
import { getUsers } from '../BackendAPI';

const AUTH_TOKEN_KEY = 'crmAuth';

export const setLocalValue = (key, value) => localStorage.setItem(key, value);

export const getLocalValue = (key) => localStorage.getItem(key);

export const getUserId = async () => {
  const localUser = JSON.parse(getLocalValue(AUTH_TOKEN_KEY));
  const { user_id, email } = localUser;
  if (user_id) return user_id;
  const user = await getUsers().then((users) => users.find((u) => u.email === email));
  return user.user_id;
};

export const getUser = () => {
  return JSON.parse(getLocalValue(AUTH_TOKEN_KEY));
};

export function useHash() {
  const getHash = () => {
    const tokenString = localStorage.getItem(AUTH_TOKEN_KEY);
    if (!tokenString || tokenString === undefined || tokenString === 'undefined') return '';

    const userToken = JSON.parse(tokenString);
    return userToken?.hash;
  };

  const [hash, setHash] = useState(getHash());

  const saveHash = (userHash, callback) => {
    if (!userHash) return null;

    localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(userHash));

    setHash(userHash?.hash ?? '');
    if (callback) callback();
  };

  const deleteHash = (callback) => {
    delete localStorage[AUTH_TOKEN_KEY];
    setHash('');
    if (callback) callback();
  };

  return {
    setHash: saveHash,
    logout: deleteHash,
    hash,
  };
}

import React, { Component } from 'react';
import InvestorTier from './InvestorTier';
import InvestorRelationships from './InvestorRelationships';
import InvestorVerticalExpertise from './InvestorVerticalExpertise';
import InvestorPercentile from './InvestorPercentile';

class InvestorSignals extends Component {
  render() {
    const { investor, summary, percentileA, percentileB } = this.props;
    const { tier, relationship_score } = summary;

    return (
      <div className="content-container card">
        <div className="card-block">
          <h3>Signals</h3>
          <InvestorTier investor={investor} tier={tier} />
          <InvestorVerticalExpertise investor={investor} />
          <InvestorRelationships investor={investor} score={relationship_score} />
          <InvestorPercentile percentileA={percentileA} percentileB={percentileB} />
        </div>
      </div>
    );
  }
}

export default InvestorSignals;

import React, { useMemo, memo } from 'react';
import styled from 'styled-components';
import { Loader } from '../primitives';
import { displayMoney } from '../../util';

const Container = styled.div`
  ${({ isWidget }) =>
    isWidget
      ? `
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    border-radius: unset;
    border: 1px solid #ebeaea;
  `
      : `
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1px;
  margin: 0 0 20px 0;
  padding: 0 10px;
  overflow-x: scroll;
  height: 78px;
  `}
`;

const DealStatStyles = styled.div`
  padding: 15px 10px;
  h4 {
    margin-bottom: 0;
  }
  label {
    font-size: 9px;
    white-space: nowrap;
    margin-bottom: 0;
  }
`;

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
`;

const DealStat = ({ value, label, isLoading }) => {
  return (
    <DealStatStyles>
      {isLoading ? (
        <StyledLoader>
          <Loader />
        </StyledLoader>
      ) : (
        <h4>{value}</h4>
      )}
      <label>{label}</label>
    </DealStatStyles>
  );
};

const AdvancedSearchV2DealStats = ({ companies, isLoading, isWidget, interval = '90' }) => {
  const amount = companies.length ?? '-';

  const stage = useMemo(() => {
    if (companies.length) {
      const earlyMidStageCompanies = companies.filter(
        (a) => a.stage === 'Early' || a.stage === 'Mid',
      );

      if (earlyMidStageCompanies.length === 0) return '0%';

      return `${((earlyMidStageCompanies.length / companies.length) * 100).toFixed(0)}%`;
    }
    return '-';
  }, [companies]);

  const coverage = useMemo(() => {
    if (companies.length) {
      const coveredCompanies = companies.filter((a) => a.major_status);

      if (coveredCompanies.length === 0) return '0%';

      return `${((coveredCompanies.length / companies.length) * 100).toFixed(0)}%`;
    }
    return '-';
  }, [companies]);

  const totalRaised = useMemo(() => {
    if (companies.length) {
      const companiesWithRaisedAmount = companies.filter((a) => a.raised_amount_usd);

      if (companiesWithRaisedAmount.length === 0) return '-';

      const totalRaisedAmount = companiesWithRaisedAmount.reduce((acc, company) => {
        return acc + parseInt(company.raised_amount_usd, 10);
      }, 0);

      return displayMoney(totalRaisedAmount);
    }
    return '-';
  }, [companies]);

  const avgVCScore = useMemo(() => {
    if (companies.length) {
      const companieswithVCScore = companies.filter((a) => a.tv_company_investor_score);

      if (companieswithVCScore.length === 0) return '-';

      const total = companieswithVCScore.reduce((acc, company) => {
        return acc + parseInt(company.tv_company_investor_score, 10);
      }, 0);

      return (total / companieswithVCScore.length).toFixed(1);
    }
    return '-';
  }, [companies]);

  // const subvertical = useMemo(() => {
  //   if (companies.length) {
  //     const companieswithVCScore = companies.filter((a) => a.tv_company_investor_score);

  //     if (companieswithVCScore.length === 0) return '-';

  //     const total = companieswithVCScore.reduce((acc, company) => {
  //       return acc + parseInt(company.tv_company_investor_score, 10);
  //     }, 0);

  //     return (total / companieswithVCScore.length).toFixed(1);
  //   }
  //   return '-';
  // }, [companies]);

  return (
    <Container className="card" isWidget={isWidget}>
      <DealStat value={amount} label={`Deals Last ${interval} Days`} isLoading={isLoading} />
      {!isWidget && <DealStat value={stage} label="Early & Mid Stage" isLoading={isLoading} />}
      <DealStat value={coverage} label="Coverage Rate" isLoading={isLoading} />
      {!isWidget && (
        <>
          <DealStat value={totalRaised} label="Total Raise" isLoading={isLoading} />
          <DealStat value={avgVCScore} label="Avg VC Score" isLoading={isLoading} />
        </>
      )}
      {/* <DealStat value={subvertical} label="Top Subvertical" isLoading={isLoading} /> */}
    </Container>
  );
};

export default memo(AdvancedSearchV2DealStats);

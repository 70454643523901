import React from 'react';

const SearchIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#282828"
        fillRule="evenodd"
        strokeWidth={0.2}
        d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SearchIcon;

import React, { useContext, useState } from 'react';
import slugify from 'slugify';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { CompanyVerticalContext } from '../contexts/CompanyVerticalContext';
import {
  createSubvertical,
  addSubverticalToCompany,
  removeSubverticalFromCompany,
  patchCompany,
} from '../BackendAPI';
import Flex from './primitives/Flex';

const StyledFlex = styled(Flex)`
  margin-bottom: 12px;
  button {
    margin-top: 0 !important;
    margin-left: 12px;
  }
`;

const StyledUl = styled.ul`
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

function VerticalList(props) {
  const { id, short_vertical } = props.vertical;
  const [state, dispatch] = useContext(CompanyVerticalContext);
  const [label, setLabel] = useState('');
  const { subverticals } = state;
  const specificSubverticals = subverticals.filter((v) => v.vertical_id === id);
  const addSubvertical = (verticalId, label) => {
    createSubvertical(verticalId, label).then((data) => {
      const subverticalsForSummary = props.subverticals ?? [];
      patchCompany(props.permalink, { subverticals: [...subverticalsForSummary, label] });

      addSubverticalToCompany(props.permalink, data.id).then((sv) => {
        data.checked = true;
        subverticals.push(data);
        dispatch({ type: 'UPDATE_SUBVERTICALS', value: subverticals });
        setLabel('');
      });
    });
  };

  const addCompanySubvertical = (subverticalId, label) => {
    for (let i = 0; i < subverticals.length; i += 1) {
      if (subverticals[i].id === subverticalId) {
        subverticals[i].checked = !subverticals[i].checked;
        if (subverticals[i].checked) {
          const subverticalsForSummary = props.subverticals ?? [];
          patchCompany(props.permalink, { subverticals: [...subverticalsForSummary, label] });
          addSubverticalToCompany(props.permalink, subverticalId);
        } else {
          const subverticalsForSummary = props.subverticals.filter(
            (subvertical) => subvertical !== label,
          );
          patchCompany(props.permalink, {
            subverticals: subverticalsForSummary?.length > 0 ? subverticalsForSummary : null,
          });
          removeSubverticalFromCompany(props.permalink, subverticalId);
        }
      }
    }
    dispatch({ type: 'UPDATE_SUBVERTICALS', value: subverticals });
  };

  return (
    <Flex direction="column" width="100%" marginTop={props.isFirst ? 0 : 20}>
      <span className={`vertical-item ${slugify(short_vertical).toLowerCase()}`} key={id}>
        {short_vertical}
      </span>

      <StyledUl className="list-unstyled list-spaced">
        {specificSubverticals
          .sort((a, b) => a.label?.localeCompare(b.label))
          .map(
            (v) =>
              v?.label && (
                <li key={v.id}>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => addCompanySubvertical(v.id, v.label)}
                        checked={v.checked}
                        value={v.id}
                      />
                      {v.label}
                    </label>
                  </div>
                </li>
              ),
          )}
      </StyledUl>

      <StyledFlex className="subvertical-item-add" align="center" width="100%">
        <input
          type="text"
          value={label}
          className="form-control"
          placeholder="Enter new sub-vertical"
          onChange={(event) => setLabel(event.target.value)}
        />
        <Button onClick={() => addSubvertical(id, label)}>Add</Button>
      </StyledFlex>
    </Flex>
  );
}

export default function SubVerticalModal({ permalink, subverticals }) {
  const [state, dispatch] = useContext(CompanyVerticalContext);
  const { verticals, subVerticalModal } = state;
  const checkedVerticals = verticals.filter((v) => v.checked);

  return (
    <div>
      <Modal backdrop="static" show={subVerticalModal} className="tv-modal tv-company-modal">
        <Modal.Header>
          <Modal.Title>Sub-Verticals</Modal.Title>
          <Button
            className="modal-close"
            onClick={() => dispatch({ type: 'TOGGLE_SUBVERTICAL_MODAL', value: false })}
          >
            <i className="fa fa-times" />
          </Button>
        </Modal.Header>

        <Modal.Body>
          {checkedVerticals.map((v, index) => (
            <VerticalList
              subverticals={subverticals}
              permalink={permalink}
              vertical={v}
              key={v.id}
              isFirst={index === 0}
            />
          ))}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => dispatch({ type: 'TOGGLE_SUBVERTICAL_MODAL', value: false })}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import React from 'react';
import styled from 'styled-components';

const Span = styled.div`
  margin-left: 2px;
  margin-top: 0px;
  position: absolute;
`;

const UnderlineIcon = () => {
  return (
    <Span>
      <svg
        fill="#000000"
        height="20"
        width="20"
        version="1.1"
        id="Icons"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 30 30"
        xmlSpace="preserve"
      >
        <g>
          <path d="M27,26H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h22c0.6,0,1-0.4,1-1S27.6,26,27,26z" />
          <path
            d="M16,24c5,0,9-4,9-9V5c0-0.6-0.4-1-1-1s-1,0.4-1,1v10c0,3.9-3.1,7-7,7s-7-3.1-7-7V5c0-0.6-0.4-1-1-1S7,4.4,7,5v10
		C7,20,11,24,16,24z"
          />
        </g>
      </svg>
    </Span>
  );
};

export default UnderlineIcon;

import React from 'react';

function InvestorPercentile(props) {
  const { percentileA, percentileB } = props;
  return (
    <>
      <div className="company-score">
        <button type="button">
          <label>A→B Rate (Percentile)</label>
          <span className="none">
            {(percentileA && percentileA[0] && percentileA[0].percentile) || '-'}
          </span>
        </button>
      </div>
      <div className="company-score">
        <button type="button">
          <label>B→C Rate (Percentile)</label>
          <span className="none">
            {(percentileB && percentileB[0] && percentileB[0].percentile) || '-'}
          </span>
        </button>
      </div>
    </>
  );
}

export default InvestorPercentile;

import React from 'react';

const DropdownArrowIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 13 7" version="1.1">
      <g id="StyleGuide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Icons-&amp;-Buttons"
          transform="translate(-298.000000, -439.000000)"
          fill="#FC4066"
          fillRule="nonzero"
        >
          <path
            d="M309.612976,439.227806 C309.930277,438.924065 310.444723,438.924065 310.762024,439.227806 C311.079325,439.531547 311.079325,440.024009 310.762024,440.32775 L305.074524,445.772194 C304.757223,446.075935 304.242777,446.075935 303.925476,445.772194 L298.237976,440.32775 C297.920675,440.024009 297.920675,439.531547 298.237976,439.227806 C298.555277,438.924065 299.069723,438.924065 299.387024,439.227806 L304.5,444.122278 L309.612976,439.227806 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};

export default DropdownArrowIcon;

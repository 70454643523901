import React from 'react';
import styled from 'styled-components';
import useIsMobile from '../hooks/useIsMobile';
import Flex from './primitives/Flex';
import SearchBox from './SearchBox';

type PageHeaderProps = {
  title: string;
  button?: React.ReactNode;
};

const H2 = styled.h2`
  white-space: nowrap;
`;

export default function PageHeader({ title, button }: PageHeaderProps) {
  const isMobile = useIsMobile();
  return (
    <>
      {/* @ts-expect-error */}
      <Flex className="container-page-header" justify="space-between" align="center">
        <Flex
          // @ts-expect-error
          align="center"
          fill={isMobile && Boolean(button)}
          justify={isMobile ? 'space-between' : 'center'}
        >
          <H2>{title}</H2>
          {button}
        </Flex>

        {!(isMobile && button) && (
          // @ts-expect-error
          <Flex height="25px" align="center">
            <SearchBox />
          </Flex>
        )}
      </Flex>
    </>
  );
}

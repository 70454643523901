import React from 'react';

const ChevronDownIcon = (props) => {
  return (
    <svg
      {...props}
      height={props.height || '16'}
      width={props.width || '16'}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    </svg>
  );
};

export default ChevronDownIcon;

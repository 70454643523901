import React, { useCallback, useState, memo } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import '../styles/react-datetime.css';
import { format, add } from 'date-fns';
import captureError from '../utils/sentry';
import { getUser } from '../utils/Auth2';
import { addTask } from '../BackendAPI';
import { Flex, Button, CloseButton } from './primitives';

const StyledForm = styled.form`
  margin-left: 20px;
  .form-checkbox-row {
    &:last-child {
      margin-bottom: 0;
    }
    .form-check {
      margin: 0;
      .form-check-label i {
        top: 4px;
        p {
          margin-bottom: 0;
        }
      }
      .form-check-label > i.checked {
        display: block;
      }
    }
  }
`;

const Container = styled(Flex)`
  height: 170px;
`;

const getFormattedDate = (reminderLength) => {
  const today = new Date();
  const year = today.getUTCFullYear();
  const month = today.getUTCMonth();
  const day = today.getUTCDate();

  const reminderDate = add(new Date(year, month, day), { months: reminderLength });
  return format(reminderDate, 'yyyy-MM-dd');
};

const AddTask = ({ company }) => {
  const user = getUser();
  const [showModal, toggleModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [reminderLength, setReminderLength] = useState(3);

  const createNewTask = useCallback(async () => {
    const dueDate = getFormattedDate(reminderLength);
    try {
      await addTask(
        user.email,
        company.permalink,
        null,
        dueDate,
        user.email,
        null,
        company.company_name,
      );
      setSuccess(true);
    } catch (err) {
      captureError(err);
      setError(true);
    }
  }, [user, reminderLength, company]);

  const getRadioClassnames = useCallback(
    (num) => {
      return parseFloat(reminderLength) === num
        ? 'fa fa-check-circle checked'
        : 'fa fa-circle-thin unchecked';
    },
    [reminderLength],
  );

  const closeModal = useCallback(() => {
    toggleModal(false);
    setTimeout(() => setSuccess(false), 500);
  }, []);

  return (
    <div>
      <Button onClick={() => toggleModal(true)}>📆</Button>

      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => toggleModal(false)}
        className="tv-modal"
      >
        <Modal.Header>
          <Modal.Title>New Follow-Up</Modal.Title>
          <CloseButton className="modal-close" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          {success ? (
            <Container align="center" justify="center" fill>
              <p>Follow-up reminder successfully created</p>
            </Container>
          ) : (
            <>
              <Container>
                <StyledForm>
                  <ul className="list-unstyled list-spaced">
                    <li className="form-checkbox-row">
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={reminderLength === 3}
                            value={3}
                            onChange={(e) => setReminderLength(e.target.value)}
                          />
                          <p>3 Months</p>
                          <i className={getRadioClassnames(3)} aria-hidden="true" />
                        </label>
                      </div>
                    </li>
                    <li className="form-checkbox-row">
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={reminderLength === 6}
                            value={6}
                            onChange={(e) => setReminderLength(e.target.value)}
                          />
                          <p>6 Months</p>
                          <i className={getRadioClassnames(6)} aria-hidden="true" />
                        </label>
                      </div>
                    </li>
                    <li className="form-checkbox-row">
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={reminderLength === 9}
                            value={9}
                            onChange={(e) => setReminderLength(e.target.value)}
                          />
                          <p>9 Months</p>
                          <i className={getRadioClassnames(9)} aria-hidden="true" />
                        </label>
                      </div>
                    </li>
                    <li className="form-checkbox-row">
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="twelve"
                            checked={reminderLength === 12}
                            value={12}
                            onChange={() => setReminderLength(12)}
                          />
                          <p>12 Months</p>
                          <i className={getRadioClassnames(12)} aria-hidden="true" />
                        </label>
                      </div>
                    </li>
                  </ul>
                </StyledForm>
              </Container>

              {error && (
                <Flex direction="column">
                  <br />
                  <p className="red-outstanding">
                    There was an issue creating your Follow-up, please refresh and try again. If the
                    issues persits reach out to IT.
                  </p>
                </Flex>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Flex align="center" justify="center" fill>
            {success ? (
              <Button onClick={closeModal}>Done</Button>
            ) : (
              <Button onClick={createNewTask}>Submit</Button>
            )}
          </Flex>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default memo(AddTask);

import React from 'react';
import VerticalSection from '../VerticalSection';
import SubVerticalSection from '../SubVerticalSection';

export default function CompanyVerticals(props) {
  return (
    <>
      <div className="content-container card">
        <VerticalSection permalink={props.permalink} />
      </div>
      <div className="content-container card">
        <SubVerticalSection permalink={props.permalink} />
      </div>
    </>
  );
}

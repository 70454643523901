import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import Search from './pages/Search';
import Dashboard from './pages/Dashboard';
import ListProfile from './pages/ListProfile';
import Company from './pages/Company';
import Watchlist from './pages/Watchlist';
import Engaged from './pages/Engaged';
import Investor from './pages/Investor';
import AdvancedSearch from './pages/AdvancedSearch';
import ProspectStats from './pages/ProspectStats';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import AdvancedSearchV2 from './pages/AdvancedSearchV2';
import Admin from './pages/Admin';

import ProtectedRoute from './components/ProtectedRoute';
import AppLayout from './components/AppLayout';

import { useHash } from './utils/Auth2';
// import useToken from './useToken';

import './styles/bootstrap.css';
import './styles/toolkit.css';
import './styles/application.css';
import './styles/App.css';

function App() {
  const { hash, setHash, logout } = useHash();

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact render={(props) => <Login setHash={setHash} {...props} />} />
        <Route path="/forgot_password" component={ForgotPassword} />
        <Route path="/change_password" component={ChangePassword} />

        <AppLayout logout={logout}>
          <div>
            <ProtectedRoute hash={hash} path="/" exact component={Dashboard} />
            <ProtectedRoute hash={hash} path="/search" component={Search} />
            <ProtectedRoute hash={hash} path="/list/:listId" component={ListProfile} />
            <ProtectedRoute hash={hash} path="/watchlist" component={Watchlist} />
            <ProtectedRoute hash={hash} path="/engaged" component={Engaged} />
            <ProtectedRoute hash={hash} path="/company/:permalink" component={Company} />
            <ProtectedRoute hash={hash} path="/investor/:permalink" component={Investor} />
            <ProtectedRoute hash={hash} path="/deal-feed" component={AdvancedSearchV2} />
            <ProtectedRoute hash={hash} path="/prospect_stats" component={ProspectStats} />
            <ProtectedRoute hash={hash} path="/admin" component={Admin} />
          </div>
        </AppLayout>

        {/* Fallback / 404 route */}
        <Route component={Login} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

import React, { memo } from 'react';
import { ThreeDots } from 'react-loader-spinner';

const Loader = ({ height = '20', width = '60', radius = '6' }) => {
  return (
    <ThreeDots
      height={height}
      width={width}
      radius={radius}
      color="#505050"
      ariaLabel="three-dots-loading"
      visible
    />
  );
};

export default memo(Loader);

import React, { useCallback, memo, Suspense } from 'react';
import captureError from '../../utils/sentry';

import NetworkErrorBoundary from './NetworkErrorBoundary';
import ErrorBoundary from './ErrorBoundary';

// interface Props {
//   onCatch?: (error: any, info?: any) => any;
//   onNetworkCatch?: (error: any, info?: any) => any;
//   fallback?: any;
//   boundaryName: string;
//   skipErrorLogging?: boolean;
//   children: React.ReactNode;
//   loadingFallback?: React.ReactNode;
// }

const Boundary = ({
  loadingFallback = null,
  onNetworkCatch,
  fallback = null,
  onCatch,
  children,
}) => {
  const onCatchFallback = useCallback((err) => {
    console.log(err);
    captureError(err);
  }, []);

  return (
    <Suspense fallback={loadingFallback}>
      <ErrorBoundary onCatch={onCatch ?? onCatchFallback}>
        <NetworkErrorBoundary fallback={fallback} onCatch={onNetworkCatch ?? onCatchFallback}>
          {children}
        </NetworkErrorBoundary>
      </ErrorBoundary>
    </Suspense>
  );
};

export default memo(Boundary);

import React, { useMemo, memo } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import useIsMobile from '../../hooks/useIsMobile';
import { displayDate, capitalize } from '../../util';
import NoteDescription from '../NoteDescription';
import TaskDescription from '../TaskDescription';
import FundingDescription from '../FundingDescription';
import HoverLink from '../HoverLink';
import '../../styles/components/CompanyFeedItem.css';
import { Flex, Pill, VerticalIcons } from '../primitives';
import Boundary from '../Boundary';

const NOTE = 'note';
const TASK = 'task';
const FUNDING = 'funding';
const STATUS = 'status';
const NEWS = 'news';
const typesWithDescriptions = [NOTE, TASK, FUNDING, NEWS];

const FeedHeader = styled(Flex)`
  p {
    margin: 0;
    white-space: nowrap;
  }
  .company-feed-item-date {
    margin-left: 6px;
  }
  .company-feed-item-type.news {
    padding-right: 0;
  }
`;

const CompanyFeedItemContent = ({
  item,
  refreshFeed,
  withCompanyName = false,
  watchlistCompanies,
  isDashboard,
}) => {
  const isMobile = useIsMobile();
  const {
    type,
    feed_time,
    header,
    isRecent,
    description,
    permalink,
    company_name,
    org_type,
    custom,
    url,
    company_status,
    key,
    tv_feed_id,
    vertical_priority,
    tv_vertical,
  } = item;

  const feedItemHeader = useMemo(() => {
    let headerText = '';
    if (type === NOTE) {
      headerText = `by ${header.split(' ')[0]}`;
    } else if (type === FUNDING) {
      headerText = header;
    } else if (type === STATUS) {
      if (custom === null) {
        // handle 9 month financing update
        headerText = header;
      } else {
        const { status } = custom;
        headerText = (
          <Flex align="center">
            <p style={{ margin: 0 }}>updated to</p>
            <Pill value={status} marginLeft="5" />
          </Flex>
        );
      }
    }

    return (
      <FeedHeader align={type === STATUS ? 'center' : 'flex-start'}>
        <p className={`company-feed-item-type ${type.toLowerCase()}`}>{capitalize(type)}</p>
        <div className="company-feed-item-header">{headerText}</div>
        <p className="company-feed-item-date"> on {displayDate(feed_time)}</p>
      </FeedHeader>
    );
  }, [type, feed_time, header, custom]);

  const feedItemBody = useMemo(() => {
    if (type === NOTE) {
      return <NoteDescription feedId={key} item={item} onSuccess={refreshFeed} />;
    }

    if (type === TASK) {
      return <TaskDescription feedId={tv_feed_id} text={description} custom={custom} />;
    }

    if (type === FUNDING) {
      if (custom != null) {
        return (
          <div className="funding-description">
            <FundingDescription investors={custom} />
          </div>
        );
      }
      return '';
    }

    if (type === NEWS) {
      return (
        <span className="news-headline">
          <a href={url} target="_blank" rel="noreferrer">
            <span className="news-header">{header}</span>
          </a>
        </span>
      );
    }

    return <div> {description} </div>;
  }, [description, type, header, url, item, refreshFeed, tv_feed_id, custom, key]);

  const companyStatus = useMemo(() => {
    if (company_status) {
      if (company_status === 'Active') return 'Prospect';
      return capitalize(company_status);
    }

    if (vertical_priority) return 'Vertical Priority';

    if (watchlistCompanies?.length > 0) {
      const isOnWatchlist = watchlistCompanies.find((a) => a.permalink === permalink);
      if (isOnWatchlist) {
        return 'Watchlist';
      }
    }

    return null;
  }, [company_status, vertical_priority, watchlistCompanies, permalink]);

  return (
    <div
      className={classnames(
        'company-feed-item-container',
        { 'has-update': isRecent },
        { 'is-mobile': isMobile },
      )}
    >
      <Flex
        align={type === STATUS ? 'center' : 'flex-start'}
        direction={isMobile ? 'column' : 'row'}
      >
        <Flex
          direction="column"
          justify={isMobile ? 'space-between' : 'flex-start'}
          fill
          className={classnames('company-feed-item-name', { 'is-mobile': isMobile })}
        >
          <Flex fill justify="space-between" className="company-title">
            {withCompanyName && (
              <HoverLink orgType={org_type} permalink={permalink} companyName={company_name} />
            )}
            <Flex>
              {companyStatus && type !== STATUS && <Pill value={companyStatus} />}
              <VerticalIcons tvVertical={tv_vertical} />
            </Flex>
          </Flex>
          <Flex direction="column" width="100%" marginTop={isDashboard ? '16' : '0'}>
            <div className="company-feed-item-header">{feedItemHeader}</div>
            <div
              className={classnames('company-feed-item-body', {
                hidden: !(typesWithDescriptions.includes(type) && feedItemBody !== ''),
              })}
            >
              {feedItemBody}
            </div>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

const CompanyFeedItem = (props) => {
  return (
    <Boundary fallback={null} errorFallback={null}>
      <CompanyFeedItemContent {...props} />
    </Boundary>
  );
};

export default memo(CompanyFeedItem);

import React, { useCallback, useState, memo } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Card, Button, LayoutContainer, LayoutHeader, Flex } from '../components/primitives';
import {
  updateVerticals,
  addHarmonicVerticals,
  updateHarmonicVerticals,
  updateInvestorsFromGoogleSheet,
} from '../BackendAPI';
import captureError from '../utils/sentry';

const Message = styled.div`
  position: absolute;
  bottom: 0px;
  right: 24px;
  color: ${({ type }) => (type === 'success' ? '#6eb41e' : 'red')};
  white-space: nowrap;
`;

const H5 = styled.h5`
  margin: 0;
`;

const H3 = styled.h3`
  margin-top: 32px;
`;

const UpdateCompanyVerticals = () => {
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState('success');
  const [message, setMessage] = useState(null);

  const triggerVerticalsUpdate = useCallback(async () => {
    setLoading(true);
    try {
      const res = await updateVerticals();
      if (!res) {
        setMessageType('error');
        setMessage(`There was an issue`);
      } else {
        setMessageType('success');
        setMessage('Process successfully completed');
      }
    } catch (err) {
      captureError(err);
      setMessageType('error');
      setMessage(`There was an issue: ${err?.message}`);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setMessage, setMessageType]);

  return (
    <Card width="100%" justify="space-between" align="center">
      <H5>Update DB from Google Sheet:</H5>
      <Flex direction="column">
        <Button onClick={triggerVerticalsUpdate} disabled={loading}>
          {loading ? '..Processing' : 'Start'}
        </Button>
        {message && <Message type={messageType}>{message}</Message>}
      </Flex>
    </Card>
  );
};

const AddHarmonicVerticals = () => {
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState('success');
  const [message, setMessage] = useState(null);

  const triggerVerticalsUpdate = useCallback(async () => {
    setLoading(true);
    try {
      const res = await addHarmonicVerticals();
      if (!res) {
        setMessageType('error');
        setMessage(`There was an issue`);
      } else {
        setMessageType('success');
        setMessage('Process successfully completed');
      }
    } catch (err) {
      captureError(err);
      setMessageType('error');
      setMessage(`There was an issue: ${err?.message}`);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setMessage, setMessageType]);

  return (
    <Card width="100%" justify="space-between" align="center">
      <H5>Add to Google Sheet from DB:</H5>
      <Flex direction="column">
        <Button onClick={triggerVerticalsUpdate} disabled={loading}>
          {loading ? '..Processing' : 'Start'}
        </Button>
        {message && <Message type={messageType}>{message}</Message>}
      </Flex>
    </Card>
  );
};

const UpdateHarmonicVerticals = () => {
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState('success');
  const [message, setMessage] = useState(null);

  const triggerVerticalsUpdate = useCallback(async () => {
    setLoading(true);
    try {
      const res = await updateHarmonicVerticals();
      if (!res) {
        setMessageType('error');
        setMessage(`There was an issue`);
      } else {
        setMessageType('success');
        setMessage('Process successfully completed');
      }
    } catch (err) {
      captureError(err);
      setMessageType('error');
      setMessage(`There was an issue: ${err?.message}`);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setMessage, setMessageType]);

  return (
    <Card width="100%" justify="space-between" align="center">
      <H5>Update DB from Google Sheet:</H5>
      <Flex direction="column">
        <Button onClick={triggerVerticalsUpdate} disabled={loading}>
          {loading ? '..Processing' : 'Start'}
        </Button>
        {message && <Message type={messageType}>{message}</Message>}
      </Flex>
    </Card>
  );
};

const UpdateInvestors = () => {
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState('success');
  const [message, setMessage] = useState(null);

  const triggerInvestorsUpdate = useCallback(async () => {
    setLoading(true);
    try {
      const res = await updateInvestorsFromGoogleSheet();
      console.log(res);
      if (!res) {
        setMessageType('error');
        setMessage(`There was an issue`);
      } else {
        setMessageType('success');
        setMessage('Process successfully completed');
      }
    } catch (err) {
      captureError(err);
      setMessageType('error');
      setMessage(`There was an issue: ${err?.message}`);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setMessage, setMessageType]);

  return (
    <Card width="100%" justify="space-between" align="center">
      <H5>Update Investors from Google Sheet:</H5>
      <Flex direction="column">
        <Button onClick={triggerInvestorsUpdate} disabled={loading}>
          {loading ? '..Processing' : 'Start'}
        </Button>
        {message && <Message type={messageType}>{message}</Message>}
      </Flex>
    </Card>
  );
};
const Admin = () => {
  return (
    <div>
      <Helmet>
        <title>Admin - TV CRM</title>
      </Helmet>

      <LayoutHeader>
        <h2>Admin</h2>
      </LayoutHeader>

      <LayoutContainer>
        <h3>Crunchbase Verticals</h3>
        <UpdateCompanyVerticals />
        <H3>Harmonic Verticals</H3>
        <AddHarmonicVerticals />
        <UpdateHarmonicVerticals />
        <H3>Investors</H3>
        <UpdateInvestors />
      </LayoutContainer>
    </div>
  );
};

export default memo(Admin);

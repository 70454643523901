import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AdvancedSearchV2Context } from './AdvancedSearchV2Context';
import PageHeader from '../PageHeader';
import { Button, Flex } from '../primitives';
import useIsMobile from '../../hooks/useIsMobile';

const Success = styled.p`
  margin: ${({ right }) => (right ? '0 18px 0 0' : '0 0 0 18px')};
  font-size: 10px;
`;

export const buildRouteQueryParams = (state) => {
  const params = [];

  for (const [key, filters] of Object.entries(state)) {
    let paramKey = `${key}=`;
    const paramFilters = [];
    let paramFilter = '';

    filters.forEach((filter) => {
      if (filter.checked) {
        const valStr = String(filter.value).replace(' ', '%20');
        if (key === 'vc_score' || key === 'round_type') {
          paramFilter = filter.value;
        } else {
          paramFilters.push(valStr);
        }
      }
    });

    if ((paramFilter.length && key === 'vc_score') || key === 'round_type') {
      paramKey = `${paramKey}${paramFilter}`;
      params.push(paramKey);
    } else if (paramFilters.length) {
      paramKey = `${paramKey}${paramFilters.join(',')}`;
      params.push(paramKey);
    }
  }

  return params.join('&');
};

export default function AdvancedSearchV2Header() {
  const isMobile = useIsMobile();
  const [state] = useContext(AdvancedSearchV2Context);
  const params = buildRouteQueryParams(state);
  window.history.replaceState({}, '', `deal-feed?${params}`);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copied]);

  return (
    <PageHeader
      title="Deal Feed"
      button={
        <CopyToClipboard
          text={`${window.location.origin}/deal-feed?${params}`}
          onCopy={() => setCopied(true)}
        >
          {isMobile ? (
            <Flex align="center" justify="center">
              {copied ? (
                <Success right style={{ color: 'green' }}>
                  Copied Link
                </Success>
              ) : null}
              <Button className="prospect-stats-btn" onClick={() => {}}>
                Share Search Link
              </Button>
            </Flex>
          ) : (
            <Flex align="center" justify="center">
              <Button className="prospect-stats-btn" onClick={() => {}}>
                Share Search Link
              </Button>
              {copied ? <Success style={{ color: 'green' }}>Copied Link</Success> : null}
            </Flex>
          )}
        </CopyToClipboard>
      }
    />
  );
}

import React, { Component } from 'react';
// import ListFeedItem from './ListFeedItem'
import CompanyFeedItem from './company/CompanyFeedItem';

const DISPLAY_INCREMENTS = 25;

class ListFeedView extends Component {
  state = {
    feedItemTypes: [
      { className: 'note', active: false, name: 'Notes' },
      { className: 'task', active: false, name: 'Tasks' },
      { className: 'news', active: false, name: 'News' },
      { className: 'funding', active: false, name: 'Funding' },
      { className: 'status', active: false, name: 'Status' },
      { className: 'list', active: false, name: 'List' },
    ],
    numDisplay: DISPLAY_INCREMENTS,
    viewAll: true,
  };

  handleClick = (e) => {
    e.preventDefault();
    const type = e.target.innerText;

    const { feedItemTypes } = this.state;
    for (let i = 0; i < feedItemTypes.length; i++) {
      if (feedItemTypes[i].name !== type) {
        continue;
      }
      feedItemTypes[i].active = !feedItemTypes[i].active;
      break;
    }
    this.setState({ feedItemTypes, viewAll: false });
  };

  toggleAll = (e) => {
    e.preventDefault();
    const { viewAll, feedItemTypes } = this.state;
    if (viewAll) return;
    for (let i = 0; i < feedItemTypes.length; i++) {
      feedItemTypes[i].active = false;
    }

    this.setState({ viewAll: true, feedItemTypes });
  };

  increment = () => this.setState({ numDisplay: this.state.numDisplay + DISPLAY_INCREMENTS });

  render() {
    let { feed, refreshFeed } = this.props;
    const { numDisplay, viewAll, feedItemTypes } = this.state;

    feed = feed.sort((a, b) => new Date(b.feed_time).getTime() - new Date(a.feed_time).getTime());
    feed = feed.filter((f) => f.type !== 'list'); // omit list feed items

    if (!viewAll) {
      const activeTypes = feedItemTypes.filter((f) => f.active).map((f) => f.className);
      feed = feed.filter((f) => activeTypes.includes(f.type));
    }

    return (
      <div>
        <div className="company-panel-list company-panel-feed">
          <div className="list-table">
            <div className="list-header">
              <span className="list-type-col list-col-header">Type</span>
              <span className="list-co-col list-col-header">Company</span>
              <span className="list-update-col list-col-header">Update</span>
              <span className="list-date-col list-col-header">Date</span>
            </div>
            {feed.slice(0, numDisplay).map((f) => (
              <CompanyFeedItem key={f.key} item={f} isCompany={false} refreshFeed={refreshFeed} />
            ))}
          </div>
          <button onClick={this.increment}>See {DISPLAY_INCREMENTS} More</button>
        </div>

        <div className="list-panel-legend">
          <label>Filter by Activity Type</label>
          <ul>
            <li className={viewAll ? 'active ' : ''} key={'all'}>
              <a href="#" onClick={(event) => this.toggleAll(event)}>
                All
              </a>
            </li>
            {feedItemTypes.map((f) => {
              return (
                <li className={f.active ? 'active ' : ''} key={f.className}>
                  <a href="#" onClick={(event) => this.handleClick(event)}>
                    {f.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default ListFeedView;

import React, { useState, useCallback, useMemo } from 'react';
import { TableHeader, Table, TableBody } from './primitives/Table';
import { compare } from '../util';
import ListPanel from './ListPanel';
import { getUser } from '../utils/Auth2';

const columns = [
  { colClass: 'name', text: 'Company', key: 'company_name', sortable: true },
  { colClass: 'description', text: 'Description', key: 'short_description', sortable: false },
  { colClass: 'vertical', text: 'Vertical', key: 'tv_vertical', sortable: false },
  { colClass: 'orgType', text: 'Type', key: 'org_type', sortable: true },
  { colClass: 'domain', text: 'Website', key: 'domain', sortable: false },
  { colClass: 'status', text: 'Status', key: 'major_status', sortable: true },
  { colClass: 'investors', text: 'Key Investors', key: 'key_investors', sortable: false },
  { colClass: 'lastRound', text: 'Last Round', key: 'last_investment_type', sortable: false },
  {
    colClass: 'lastRoundDate',
    text: 'Last Round Date',
    key: 'last_investment_date',
    sortable: true,
  },
  { colClass: 'location', text: 'Location', key: 'location', sortable: true },
];

export const SearchTableContainer = ({ children, doSort, activeSortKey, isAsc }) => {
  return (
    <Table maxHeightDelta="230px">
      <TableHeader headerColumns={columns} sort={doSort} sortKey={activeSortKey} isAsc={isAsc} />
      <TableBody>{children}</TableBody>
    </Table>
  );
};

export const SearchTable = ({ companies, watchlistData }) => {
  const [activeSortKey, setActiveSortKey] = useState('last_investment_date');
  const [isAsc, setIsAsc] = useState(false);
  const user = getUser();

  const doSort = useCallback(
    (key) => {
      if (activeSortKey === key) {
        setIsAsc(!isAsc);
      } else {
        setIsAsc(false);
        setActiveSortKey(key);
      }
    },
    [activeSortKey, isAsc, setActiveSortKey, setIsAsc],
  );

  const sortedCompanies = useMemo(() => {
    const sorted = companies.sort((a, b) => {
      if (activeSortKey === 'last_investment_date') {
        const aDate = new Date(a[activeSortKey]);
        const bDate = new Date(b[activeSortKey]);
        return compare(aDate.getTime(), bDate.getTime());
      }
      return compare(a[activeSortKey], b[activeSortKey]);
    });

    if (!isAsc) {
      sorted.reverse();
    }

    return sorted;
  }, [isAsc, companies, activeSortKey]);

  return (
    <SearchTableContainer doSort={doSort} activeSortKey={activeSortKey} isAsc={isAsc}>
      {sortedCompanies.map((c) => (
        <ListPanel
          company={c}
          key={c.permalink}
          columns={columns}
          userId={user.user_id}
          watchlistData={watchlistData}
        />
      ))}
    </SearchTableContainer>
  );
};

import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { changePassword } from '../utils/Auth';
import captureError from '../utils/sentry';

function ChangePassword() {
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const path = window.location.pathname.split('/');
    setToken(path.pop() || path.pop());
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const res = await changePassword(token, newPass);

        if (res.status === 'success') {
          setSuccess(true);
          setNewPass('');
          setConfirmPass('');
        } else {
          setError(true);
          captureError(`Change password err: ${JSON.stringify(res)}`);
        }
      } catch (err) {
        setError(true);
        captureError(err);
      }
    },
    [newPass, token],
  );

  return (
    <>
      <Helmet>
        <title>Thomvest</title>
      </Helmet>

      <div className="container-fluid container-fill-height">
        <div className="container-content-middle">
          <div role="form" className="mx-auto text-center app-login-form">
            <div className="navbar-brand tv-nav login">
              TV<i>CRM</i>
            </div>
            <div>
              <div className="form-group">
                <input
                  className="form-control mb-3"
                  type="password"
                  name="newPass"
                  value={newPass}
                  placeholder="Enter New Password"
                  onChange={(e) => setNewPass(e.target.value)}
                />
                <input
                  className="form-control"
                  type="password"
                  name="confirmPass"
                  value={confirmPass}
                  placeholder="Confirm New Password"
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <button
                  disabled={!newPass || newPass.length < 8 || newPass !== confirmPass}
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
              {success && (
                <div>
                  <p>You have successfully changed your password!</p>
                </div>
              )}
              {error && (
                <div>
                  <p className="error">There was an issue. Please try again or contact support.</p>
                </div>
              )}
            </div>
            <div className="mb-5">
              <Link to="/login">Back to Login</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;

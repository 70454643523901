import React, { memo } from 'react';
import styled from 'styled-components';
import { Card, Flex } from './primitives';
import useIsMobile from '../hooks/useIsMobile';

const Container = styled(Card)`
  background: #fff;
  margin: ${({ margin }) => `${margin}`};
  padding: ${({ padding }) => (padding ? `${padding}` : `10px 30px`)};

  h6 {
    margin: 0 12px 6px 0;
    font-size: 9px;
    color: #898989;
    font-size: 10px;
    text-transform: uppercase;
    display: block;
    white-space: nowrap;
  }

  @media (max-width: 1408px) {
    div {
      margin-left: 0;
    }
  }

  .filter-container-row {
    flex-wrap: wrap;

    .filter-btn {
      min-width: 36px;
    }

    ${({ isMobile }) =>
      isMobile &&
      `
      .modal-row {
        flex: 1 1 48%;
        &:first-child {
          margin-right: 2%;
        }
        &:last-child {
          margin-left: 2%;
        }
      }
    `}
  }
`;

const FilterContainer = ({
  children,
  margin = '20px 0 0 0',
  fill = false,
  justify = 'flex-start',
  align = 'center',
  padding,
  isDashboard,
  borderRadius = true,
  boxShadow = true,
}) => {
  const isMobile = useIsMobile();

  return (
    <Container
      isMobile={isMobile}
      margin={isDashboard ? '0' : margin}
      padding={padding}
      isDashboard={isDashboard}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      className="filter-container"
      fill
    >
      <Flex
        className="filter-container-row"
        direction="row"
        align={align}
        justify={justify}
        fill={fill}
      >
        {children}
      </Flex>
    </Container>
  );
};

export default memo(FilterContainer);

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './styles/index.css';
import App from './App';
// import { BrowserRouter } from 'react-router-dom'
import registerServiceWorker from './registerServiceWorker';

const SENTRY_REACT_DSN =
  'https://6e394107623e468c9fdd8cd3aa31009f@o4505202333646848.ingest.sentry.io/6701526';

Sentry.init({
  dsn: SENTRY_REACT_DSN,
  integrations: [
    // Add browser profiling integration to the list of integrations
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'https://api.thomvest.com'],

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();

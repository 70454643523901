import React from 'react';

const CheckMarkIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 30 30" version="1.1">
      <title>checkmark-icon</title>
      <g id="checkmark-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="005-check-mark" fill={props.color ?? '#ED3B64'} fillRule="nonzero">
          <path
            d="M21.6307068,10.2404022 C22.0884704,10.6981659 22.0884704,11.4402008 21.6307068,11.8977356 L13.7690735,19.7595978 C13.3113098,20.2171325 12.5695038,20.2171325 12.1117401,19.7595978 L8.36929324,16.016922 C7.91152957,15.5593872 7.91152957,14.8173523 8.36929324,14.3598175 C8.82682799,13.9020538 9.56886293,13.9020538 10.0263977,14.3598175 L12.9402923,17.2737122 L19.9733734,10.2404022 C20.4311371,9.78286746 21.173172,9.78286746 21.6307068,10.2404022 L21.6307068,10.2404022 Z M30,15 C30,23.2912445 23.2901001,30 15,30 C6.70875551,30 0,23.2901001 0,15 C0,6.70875551 6.7098999,0 15,0 C23.2912445,0 30,6.7098999 30,15 Z M27.65625,15 C27.65625,8.0042267 21.9948578,2.34375 15,2.34375 C8.0042267,2.34375 2.34375,8.00514223 2.34375,15 C2.34375,21.9957733 8.00514223,27.65625 15,27.65625 C21.9957733,27.65625 27.65625,21.9948578 27.65625,15 Z"
            fill={props.color ?? '#ED3B64'}
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};

export default CheckMarkIcon;

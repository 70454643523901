import React, { useContext, memo } from 'react';
import { Button } from '../primitives';
import { AdvancedSearchV2Context } from './AdvancedSearchV2Context';

const AdvancedSearchV2Reset = ({ useButton }) => {
  const [, dispatch] = useContext(AdvancedSearchV2Context);
  const reset = () => dispatch('reset');

  return (
    <div>
      {useButton ? (
        <Button onClick={reset}>Reset</Button>
      ) : (
        <button type="button" className="tv-button" onClick={reset}>
          Reset
        </button>
      )}
    </div>
  );
};

export default memo(AdvancedSearchV2Reset);

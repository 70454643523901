import React from 'react';

const DashboardIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" version="1.1">
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill={props.color || '#1e4b9e'}
        fillRule="evenodd"
        d="M2 5a2 2 0 012-2h16a2 2 0 012 2v11a2 2 0 01-2 2h-7v2h3a1 1 0 110 2H8a1 1 0 110-2h3v-2H4a2 2 0 01-2-2V5zm18 11V5H4v11h16z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default DashboardIcon;

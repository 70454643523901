import React, { useMemo, memo } from 'react';
import slugify from 'slugify';
import HoverLink from './HoverLink';
import { displayInvestmentType } from '../util';
import '../styles/components/WatchlistPanel.css';
import { VERT_MAP } from '../constants';
import { Flex } from './primitives';

function WatchlistPanel({ company }) {
  const {
    permalink,
    company_name,
    tv_vertical,
    last_investment_date,
    last_investment_type,
    updates,
  } = company;
  const arr = tv_vertical ? tv_vertical.split(',').map((vert) => VERT_MAP[vert.trim()]) : null;

  const lastInvestmentDays = useMemo(() => {
    const calcDays = Math.round((Date.now() - Date.parse(last_investment_date)) / 86400000);
    if (Number.isNaN(calcDays)) return null;
    return calcDays;
  }, [last_investment_date]);

  const lastInvestmentType = useMemo(() => {
    const lastType = displayInvestmentType(last_investment_type);
    if (lastType === 'N/A') return null;
    return lastType;
  }, [last_investment_type]);

  return (
    <div className="widget-row-container">
      <Flex justify="space-between">
        <span className="watchlistPanel__name">
          <HoverLink orgType="company" permalink={permalink} companyName={company_name} />
        </span>
        <Flex justify="flex-end">
          {arr?.map((a) => (
            <span className={`watchlistPanel__vertical ${slugify(a).toLowerCase()}`} key={a}>
              {a}
            </span>
          ))}
        </Flex>
      </Flex>

      {updates && (
        <div className="widget-row-body">
          <span className="widget-row-body-bold">{updates}</span>{' '}
          {updates === 1 ? 'update' : 'updates'} in last week
        </div>
      )}
      {lastInvestmentType && lastInvestmentType && (
        <div className="widget-row-body">
          <span className="widget-row-body-bold">{lastInvestmentDays} days</span> since last
          financing ({lastInvestmentType})
        </div>
      )}
    </div>
  );
}

export default memo(WatchlistPanel);

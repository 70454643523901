import React from 'react';

const RightArrowIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 22 18" version="1.1">
      <g id="StyleGuide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Icons-&amp;-Buttons"
          transform="translate(-212.000000, -641.000000)"
          stroke="#FC4066"
          strokeWidth="2"
        >
          <g id="button" transform="translate(193.000000, 621.000000)">
            <g id="Group" transform="translate(19.000000, 22.000000)">
              <path d="M0,6.82902299 L19.6754564,6.82902299" id="Shape" />
              <polyline
                id="Shape"
                strokeLinecap="square"
                points="12.7890467 0 19.6754564 6.82902299 12.7890467 13.658046"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RightArrowIcon;

import React from 'react';
import styled from 'styled-components';
import AdvancedSearchV2Filter from './AdvancedSearchV2Filter';
import AdvancedSearchV2VerticalFilter from './AdvancedSearchV2VerticalFilter';
import AdvancedSearchV2Reset from './AdvancedSearchV2Reset';

const Container = styled.div`
  .card-block {
    h5,
    h3 {
      margin-bottom: 0;
    }
    label {
      margin-top: 0;
    }
  }
`;

export default function AdvancedSearchV2FiltersBar({ isModal }) {
  return (
    <Container>
      <div className="card-block">
        {isModal ? <h5>Vertical</h5> : <h3>Vertical</h3>}
        <AdvancedSearchV2VerticalFilter />
      </div>
      <div className="card-block">
        {isModal ? <h5>Filters</h5> : <h3>Filters</h3>}
        <AdvancedSearchV2Filter filterList="stage" filterLabel="Company Stage" />
        <AdvancedSearchV2Filter filterList="vc_score" filterLabel="VC Score" />
        {/* <AdvancedSearchV2Filter filterList="vc_expertise" filterLabel="VC Expertise" /> */}
        <AdvancedSearchV2Filter filterList="round_type" filterLabel="Round Type" />
        {!isModal && <AdvancedSearchV2Reset />}
      </div>
    </Container>
  );
}

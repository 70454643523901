import React, { createContext, useReducer } from 'react';

export const CompanyVerticalContext = createContext();

const reducer = (state, action) => {
  const newState = { ...state };

  switch (action.type) {
    case 'UPDATE_VERTICALS':
      newState.verticals = action.value;
      return newState;
    case 'UPDATE_SUBVERTICALS':
      newState.subverticals = action.value;
      return newState;
    case 'TOGGLE_VERTICAL_MODAL':
      newState.verticalModal = action.value;
      return newState;
    case 'TOGGLE_SUBVERTICAL_MODAL':
      newState.subVerticalModal = action.value;
      return newState;
    default:
      return newState;
  }
};

export const CompanyVerticalContextProvider = (props) => {
  const { verticalModal = false, subVerticalModal = false } = props;
  const initialState = {
    verticals: [],
    subverticals: [],
    verticalModal,
    subVerticalModal,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CompanyVerticalContext.Provider value={[state, dispatch]}>
      {props.children}
    </CompanyVerticalContext.Provider>
  );
};

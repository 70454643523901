import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  min-height: 75px;
  max-height: 100px;
  background: #fff;
  border-bottom: 1px solid #ebeaea;
  margin-bottom: 0px;
  padding: 10px 20px;
  @media (max-width: 768px) {
    padding: 10px 10px;
  }
  position: relative;
  font-weight: 400;
  width: 100%;
  align-items: center;
  display: flex;

  ${({ isRecent }) =>
    isRecent &&
    `
      &:after {
        width: 3px;
        height: calc(100% - 16px);
        position: absolute;
        background: #b8e986;
        left: 8px;
        top: 8px;
        content: '';
    }
  `}
  p,
  div {
    position: relative;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
    font-size: 12px;
    line-height: 15px;

    &.column {
      width: 10%;
      min-width: 100px;
    }

    &.name {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 12%;
    }

    &.vertical {
      display: inline-flex;
      flex-wrap: wrap;

      .vertical-item {
        flex-grow: unset;
      }
    }

    &.subvertical {
      .vertical-item {
        line-height: 14px;
        height: auto;
      }
    }

    &.description,
    &.investors {
      width: 20%;
      min-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

const TableRow = ({ children, isRecent = false, onMouseEnter, onMouseLeave }) => {
  return (
    <Row
      className="table-row"
      isRecent={isRecent}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Row>
  );
};

export default TableRow;

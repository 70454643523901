import React, { memo } from 'react';

const Input = ({ label, value, onChange, onBlur, placeholder = '', marginTop = '0' }) => {
  return (
    <div className="modal-row" style={{ marginTop }}>
      {label && <label>{label}</label>}
      <input
        type="text"
        className="form-control"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={() => onBlur && onBlur()}
      />
    </div>
  );
};

export default memo(Input);

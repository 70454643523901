import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, Link } from 'react-router-dom';
import { login } from '../utils/Auth';
import captureError from '../utils/sentry';

export default function Login({ setHash }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await login(username.toLowerCase(), password);
      setHash(res?.data, () => history.push('/'));
    } catch (err) {
      setError(err?.message ?? 'Failed to login');
      captureError(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login - TV CRM</title>
      </Helmet>

      <div className="container-fluid container-fill-height">
        <div className="container-content-middle">
          <div role="form" className="mx-auto text-center app-login-form">
            <div className="navbar-brand tv-nav login">
              TV<i>CRM</i>
            </div>
            <div>
              <div className="form-group">
                <input
                  className="form-control"
                  name="email"
                  value={username}
                  placeholder="Email"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && (
                <div>
                  <p>There was an issue: {error}</p>
                </div>
              )}
              <div className="mb-5">
                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                  Login
                </button>
              </div>
            </div>
            <div className="mb-5">
              <Link to="/forgot_password">Forgot password?</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

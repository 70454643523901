import { useContext } from 'react';

import breakpoints from '../../utils/breakpoints';
import DefaultIsMobileContext from './DefaultIsMobileContext';
import useIsMatchingMediaQuery from './useIsMatchingMediaQuery';

const useIsMobile = () => {
  const initialValue = useContext(DefaultIsMobileContext);
  const query = `(max-width: ${breakpoints.tablet}px)`;

  return useIsMatchingMediaQuery({
    query,
    initialValue,
  });
};

export default useIsMobile;

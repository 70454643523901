import React, { useState, memo, useMemo, useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { EditorState, convertToRaw, convertFromHTML, convertFromRaw, ContentState } from 'draft-js';
import classNames from 'classnames';
import { CloseButton, Button, Flex, Icon } from './primitives';
import { getUserEmail } from '../utils/Auth';

import { updateNoteFromFeed, getNoteOwnerEmail, deleteNote } from '../BackendAPI';
import RichTextEditor from './TextEditor';
import captureError from '../utils/sentry';

const maxNoteCharacters = 70;

const Container = styled(Flex)`
  min-height: 24px;
  p {
    margin-bottom: 0;
  }
`;

const SuccessContainer = styled(Flex)`
  min-height: 400px;
`;

const NoteDescription = ({ feedId, item, onSuccess }) => {
  const [collapse, setCollapse] = useState(true);
  const [initialRichText, setInitialRichText] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [stringifiedRichText, setStringifiedRichText] = useState(null);

  const handleClose = async () => {
    if (stringifiedRichText) {
      try {
        setSaving(true);

        const note = await updateNoteFromFeed(feedId, stringifiedRichText);

        if (note) {
          setSuccess('updated');
          if (onSuccess) {
            onSuccess();
          }
        } else {
          captureError('Update Note undefined');
          setError(true);
        }
      } catch (err) {
        captureError(err);
        setError(true);
      } finally {
        setSaving(false);
      }
    }

    if (onSuccess) onSuccess();
    setShowModal(false);
    setSuccess(null);
    setError(false);
  };

  useEffect(() => {
    const { description, rich_text, custom } = item;
    let initializeRichText = rich_text;

    if (description && !rich_text) {
      const blocksFromHTML = convertFromHTML(description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      initializeRichText = JSON.stringify(convertToRaw(state));
    }

    setInitialRichText(initializeRichText);

    const getNoteOwner = async () => {
      const email = getUserEmail();
      const noteOwnerEmail = await getNoteOwnerEmail(custom.note_id);

      if (email === noteOwnerEmail || email === 'colin@thomvest.com') {
        setCanEdit(true);
      }
    };

    if (!canEdit) getNoteOwner();
  }, [item, canEdit]);

  const debouncedSaveRichText = debounce(setStringifiedRichText, 750);

  const handleDeleteNote = useCallback(async () => {
    const noteId = item.custom.note_id;
    try {
      await deleteNote({ feedId, noteId });
      setSuccess('deleted');
    } catch (err) {
      captureError(err);
      setError(true);
    }
  }, [feedId, item]);

  const toggleNotes = () => {
    setCollapse(!collapse);
  };

  const shouldExpand = useMemo(() => {
    if (initialRichText) {
      const editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(initialRichText)),
      );
      const contentState = editorState.getCurrentContent();
      const blocksArray = contentState.getBlocksAsArray();
      const plainText = contentState.getPlainText();

      return plainText.length > maxNoteCharacters || blocksArray?.length > 2;
    }
    return false;
  }, [initialRichText]);

  useEffect(() => {
    if (success === 'updated') {
      setTimeout(() => setSuccess(false), 2000);
    }
  }, [success]);

  return (
    <div>
      <div className="notes-container card">
        <div className={classNames('notes-text-editor-container', { 'is-collapsed': collapse })}>
          {initialRichText && <RichTextEditor readOnly richText={initialRichText} />}
        </div>
        {(canEdit || shouldExpand) && (
          <Flex className="notes-actions" align="center">
            {canEdit && <Icon name="edit" onClick={() => setShowModal(true)} />}
            {shouldExpand && (
              <Icon name={collapse ? 'expand' : 'expand-close'} onClick={toggleNotes} />
            )}
          </Flex>
        )}
      </div>

      <div>
        <Modal
          backdrop="static"
          show={showModal}
          onHide={handleClose}
          className="tv-modal tv-text-editor-modal"
        >
          <Modal.Header>
            <Modal.Title>
              {item.company_name ? `Edit Note for ${item.company_name}` : 'Edit Note'}
            </Modal.Title>
            <CloseButton className="modal-close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            {success === 'deleted' ? (
              <SuccessContainer align="center" justify="center">
                <p>Note successfully deleted</p>
              </SuccessContainer>
            ) : (
              <RichTextEditor externalOnChange={debouncedSaveRichText} richText={initialRichText} />
            )}

            <Container>
              {saving && <p className="green-score">..saving</p>}
              {success === 'updated' && !saving && <p className="green-score">note updated</p>}
              {error && (
                <Flex direction="column">
                  <p className="red-outstanding">
                    There was an issue updating this note, please refresh and try again.
                    <br />
                    If the issues persits please reach out to IT.
                  </p>
                </Flex>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Flex align="center" justify="space-between" fill>
              <button type="button" className="btn btn-edit-note" onClick={handleDeleteNote}>
                Delete
              </button>
              <Button onClick={handleClose} disabled={saving}>
                Save
              </Button>
            </Flex>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default memo(NoteDescription);

export const prospectScores = [
  {
    stage_type_id: 1,
    score_id: 9,
    min_score: 0,
    max_score: 2,
    sort_order: 0,
    score_text: 'Team',
  },
  {
    stage_type_id: 1,
    score_id: 7,
    min_score: 0,
    max_score: 2,
    sort_order: 1,
    score_text: 'Market Size',
  },
  {
    stage_type_id: 1,
    score_id: 8,
    min_score: 0,
    max_score: 2,
    sort_order: 2,
    score_text: 'Product or Technology Differentiation',
  },
  {
    stage_type_id: 1,
    score_id: 2,
    min_score: 0,
    max_score: 1,
    sort_order: 3,
    score_text: 'Geography (SF or Toronto)',
  },
  {
    stage_type_id: 1,
    score_id: 3,
    min_score: 0,
    max_score: 1,
    sort_order: 4,
    score_text: 'Industry Fit',
  },
  {
    stage_type_id: 1,
    score_id: 4,
    min_score: 0,
    max_score: 1,
    sort_order: 5,
    score_text: 'Competition',
  },
  {
    stage_type_id: 1,
    score_id: 10,
    min_score: 0,
    max_score: 1,
    sort_order: 6,
    score_text: 'Bill Dodds Wow Factor',
  },
  {
    stage_type_id: 2,
    score_id: 9,
    min_score: 0,
    max_score: 1,
    sort_order: 0,
    score_text: 'Team',
  },
  {
    stage_type_id: 2,
    score_id: 7,
    min_score: 0,
    max_score: 1,
    sort_order: 1,
    score_text: 'Market Size',
  },
  {
    stage_type_id: 2,
    score_id: 8,
    min_score: 0,
    max_score: 1,
    sort_order: 2,
    score_text: 'Product or Technology Differentiation',
  },
  {
    stage_type_id: 2,
    score_id: 2,
    min_score: 0,
    max_score: 1,
    sort_order: 3,
    score_text: 'Geography (SF or Toronto)',
  },
  {
    stage_type_id: 2,
    score_id: 3,
    min_score: 0,
    max_score: 1,
    sort_order: 4,
    score_text: 'Industry Fit',
  },
  {
    stage_type_id: 2,
    score_id: 4,
    min_score: 0,
    max_score: 1,
    sort_order: 5,
    score_text: 'Competition',
  },
  {
    stage_type_id: 2,
    score_id: 6,
    min_score: 0,
    max_score: 1,
    sort_order: 6,
    score_text: 'Positive Momentum',
  },
  {
    stage_type_id: 2,
    score_id: 5,
    min_score: 0,
    max_score: 1,
    sort_order: 7,
    score_text: 'Business Model',
  },
  {
    stage_type_id: 2,
    score_id: 1,
    min_score: 0,
    max_score: 1,
    sort_order: 8,
    score_text: 'Investor Syndicate',
  },
  {
    stage_type_id: 2,
    score_id: 10,
    min_score: 0,
    max_score: 1,
    sort_order: 9,
    score_text: 'Bill Dodds Wow Factor',
  },
];

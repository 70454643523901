import React, { useState, useEffect, memo } from 'react';
import { getSheetsData } from '../../BackendAPI';
import captureError from '../../utils/sentry';

export const SPREADSHEET_ID = '1AxSWw0fy6Xv9OCQL07XlDTTmP8ScC67C9uWKt89Q7Ks';
const RANGES = ['crmPermalink', 'crmCost', 'crmNav', 'crmOwnership'];

const cleanColumn = (column) =>
  column
    .map((row) => (row.length ? row : ''))
    .flat()
    .map((s) => s.trim());
export const parseNum = (num) => parseInt(num.replaceAll(',', ''), 10);
export const formatMonetaryValue = (value) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 3,
  }).format(value);

function parseOwnershipData(permalink, rawData) {
  const permalinkData = cleanColumn(rawData.crmPermalink);
  const costData = cleanColumn(rawData.crmCost);
  const navData = cleanColumn(rawData.crmNav);
  const ownershipData = cleanColumn(rawData.crmOwnership);

  for (let i = 0; i < permalinkData.length; i += 1) {
    if (permalinkData[i] === permalink) {
      return {
        cost: parseNum(costData[i]),
        nav: parseNum(navData[i]),
        ownership: ownershipData[i],
      };
    }
  }

  return null;
}

function CompanyOwnership(props) {
  const [data, setData] = useState(false);
  const permalink = props.company;

  useEffect(() => {
    const fetchSheetsData = async () => {
      try {
        const sheetData = await getSheetsData(SPREADSHEET_ID, RANGES);
        setData(parseOwnershipData(permalink, sheetData));
      } catch (error) {
        setData(null);
        captureError(`Error getting ownership data for ${permalink}`);
      }
    };

    fetchSheetsData();
  }, [permalink]);

  if (!data) return null;

  return (
    <>
      <li>
        <label>Ownership %</label>
        <p>{data.ownership ? data.ownership : '-'}</p>
      </li>
      <li>
        <label>Invested</label>
        <p>{data.cost ? formatMonetaryValue(data.cost) : '-'}</p>
      </li>
      <li>
        <label>NAV</label>
        <p>{data.nav ? formatMonetaryValue(data.nav) : '-'}</p>
      </li>
    </>
  );
}

export default memo(CompanyOwnership);

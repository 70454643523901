import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '../primitives';
import { addUserWatchlist, removeUserWatchlist } from '../../BackendAPI';
import { getUser } from '../../utils/Auth2';

export default function CompanyUserWatchlist({ company, onWatchlist }) {
  const [watchlistStatus, toggleWatchlistStatus] = useState(false);
  const user = getUser();

  useEffect(() => {
    toggleWatchlistStatus(onWatchlist);
  }, [onWatchlist]);

  const toggle = useCallback(() => {
    if (watchlistStatus) {
      toggleWatchlistStatus(false);
      removeUserWatchlist(user?.user_id, company);
    } else {
      toggleWatchlistStatus(true);
      addUserWatchlist(user?.user_id, company);
    }
  }, [watchlistStatus, user?.user_id, company]);

  return (
    <Button active={watchlistStatus} onClick={toggle}>
      + My Watchlist
    </Button>
  );
}

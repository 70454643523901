import React, { useContext } from 'react';

import { ProspectContext } from '../../contexts/ProspectContext';
import ProspectModal from './ProspectModal';
import ProspectScoresModal from './ProspectScoresModal';
import ProspectScoresV2Modal from './ProspectScoresV2Modal';

const ProspectModals = ({ refreshFeed, withVerticals = true }) => {
  const [state] = useContext(ProspectContext);

  return (
    <>
      {state.modal === 1 && <ProspectModal refreshFeed={refreshFeed} />}
      {state.modal === 2 && (
        <ProspectScoresModal refreshFeed={refreshFeed} withVerticals={withVerticals} />
      )}
      {state.modal === 3 && (
        <ProspectScoresV2Modal refreshFeed={refreshFeed} withVerticals={withVerticals} />
      )}
    </>
  );
};

export default ProspectModals;

/*
 * Return matchMedia instance if matchMedia is supported.
 *
 * matchMedia is not supported server-side (no window object),
 * and window.matchMedia(query) can occasionally return null
 * in hidden iframes. Jest also seems to have a partial support
 * for it that seems not to allow adding event listeners.
 */
// @ts-expect-error
function getMatchMedia(query) {
  const canAttemptUsingMatchMedia = Boolean(typeof window !== 'undefined' && window.matchMedia);
  const matchMediaInstance = canAttemptUsingMatchMedia ? window.matchMedia(query) : undefined;
  const isSupported = Boolean(
    // @ts-expect-error
    matchMediaInstance && matchMediaInstance.addListener && matchMediaInstance.removeListener,
  );
  return isSupported ? matchMediaInstance : undefined;
}

export default getMatchMedia;

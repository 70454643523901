import React, { createContext, useReducer } from 'react';

export const InvestorSearchContext = createContext();

const investorFilters = {
  tiers: [
    { label: 'T1', value: '1', checked: false, onClass: 'cs' },
    { label: 'T2', value: '2', checked: false, onClass: 'cs' },
    { label: 'T3', value: '3', checked: false, onClass: 'cs' },
  ],
  expertise: [
    { label: 'FT', value: 'Financial Technology', checked: false, onClass: 'ft' },
    { label: 'MT', value: 'Marketing Technology', checked: false, onClass: 'mt' },
    { label: 'RE', value: 'Real Estate', checked: false, onClass: 're' },
    { label: 'CI', value: 'Cloud Infrastructure', checked: false, onClass: 'ci' },
    { label: 'CS', value: 'Cybersecurity', checked: false, onClass: 'cs' },
  ],
  relationships: [
    { label: 'H', value: 'High', checked: false, onClass: 'cs' },
    { label: 'M', value: 'Medium', checked: false, onClass: 'cs' },
    { label: 'L', value: 'Low', checked: false, onClass: 'cs' },
  ],
};

const companyFilters = {
  verticals: [
    { label: 'FT', value: 'Financial Technology', checked: false, onClass: 'ft' },
    { label: 'MT', value: 'Marketing Technology', checked: false, onClass: 'mt' },
    { label: 'RE', value: 'Real Estate', checked: false, onClass: 're' },
    { label: 'CI', value: 'Cloud Infrastructure', checked: false, onClass: 'ci' },
    { label: 'CS', value: 'Cybersecurity', checked: false, onClass: 'cs' },
  ],
  status: [
    { label: 'Portfolio', value: 'Prospect-Portfolio', checked: false, onClass: 'cs' },
    { label: 'Prospect', value: 'Prospect-Active', checked: false, onClass: 'cs' },
    { label: 'Passed', value: 'Prospect-Passed', checked: false, onClass: 'cs' },
    { label: 'Dormant', value: 'Prospect-Dormant', checked: false, onClass: 'cs' },
  ],
  location: [
    { label: 'United States', value: 'United States', checked: true, onClass: 'cs' },
    {
      label: 'Canada',
      value: 'Canada',
      checked: false,
      onClass: 'cs',
    },
    { label: 'Mexico', value: 'Mexico', checked: false, onClass: 'cs' },
    { label: 'Israel', value: 'Israel', checked: false, onClass: 'cs' },
    { label: 'Other', value: 'Other', checked: false, onClass: 'cs' },
    { label: 'Global (All)', value: 'Global (All)', checked: false, onClass: 'cs' },
  ],
  vc_score: [
    { label: '1+', value: '1', checked: false, onClass: 'cs' },
    { label: '3+', value: '3', checked: false, onClass: 'cs' },
    { label: '5+', value: '5', checked: false, onClass: 'cs' },
  ],
  stage: [
    { label: 'Early', value: 'Early', checked: false, onClass: 'cs' },
    { label: 'Mid', value: 'Mid', checked: false, onClass: 'cs' },
    { label: 'Late', value: 'Late', checked: false, onClass: 'cs' },
  ],
  // last_raise: [
  // {label: '3+ years', value: '>=3', checked: false, onClass: "cs"},
  // {label: '<3 years', value: '<3', checked: false, onClass: "cs"},
  // ],
  subverticals: [],
};

const INVESTOR_SEARCH_INITIAL_STATE = {
  searchFilters: {
    investor: {
      tiers: [],
      expertise: [],
      relationships: [],
    },
    company: {
      verticals: [],
      stage: [],
      vc_score: [],
      status: [],
      location: {},
      // last_raise: [],
      // vertical_priority: false
      subverticals: [],
    },
  },
  siteFilters: {
    investor: investorFilters,
    company: companyFilters,
  },
};

const getActiveFilters = (filterList) => filterList.filter((f) => f.checked).map((f) => f.value);

const getInitialState = (params, allSubverticals, reset = false) => {
  const initialState = INVESTOR_SEARCH_INITIAL_STATE;
  const { siteFilters, searchFilters } = initialState;

  const { company } = siteFilters;
  const { subverticals } = company;

  if (allSubverticals && subverticals.length === 0) {
    for (let i = 0; i < allSubverticals.length; i++) {
      const { label, id } = allSubverticals[i];
      const option = { label, value: label, checked: false, onClass: 'cs' };
      subverticals.push(option);
    }
  }

  for (const [filterList, values] of Object.entries(params)) {
    let filterType = filterList in siteFilters.company ? 'company' : 'investor';
    if (filterList === 'country') filterType = 'company';

    const filters = siteFilters[filterType][filterList === 'country' ? 'location' : filterList];

    if (!filters) continue;

    for (let i = 0; i < filters.length; i += 1) {
      if (reset) {
        filters[i].checked = false;
      } else if (values.includes(filters[i].value)) {
        filters[i].checked = true;
      }
    }

    searchFilters[filterType][filterList] = getActiveFilters(filters);
  }

  const { city, region, country } = params;

  if (city || region || country) {
    searchFilters.company.location = {
      ...(city?.[0] && { city: city[0] }),
      ...(region?.[0] && { region: region[0] }),
      ...(country?.[0] && { country: country[0] }),
    };
  }

  return initialState;
};

const reducer = (state, action) => {
  if (!state) return null;

  if (action.type === 'RESET') {
    window.history.replaceState({}, '', `advanced_search`);
    return window.initialSearchContextState;
  }

  const { siteFilters, searchFilters } = state;
  const { filterType, filterList, value } = action;

  if (filterList === 'location' && filterType === 'company') {
    const newState = {
      ...state,
      searchFilters: {
        ...searchFilters,
        company: {
          ...searchFilters.company,
          location: value,
        },
      },
    };

    return newState;
  }

  const filters = siteFilters[filterType][filterList];
  for (let i = 0; i < filters.length; i += 1) {
    if (filters[i].value === value || filters[i].label === value) {
      filters[i].checked = !filters[i].checked;
    }
  }

  searchFilters[filterType][filterList] = getActiveFilters(filters);
  const newState = { ...state };
  return newState;
};

export const InvestorSearchContextProvider = (props) => {
  const { params, subverticals } = props;
  const initialState = getInitialState(params, subverticals);
  window.initialSearchContextState = initialState;
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <InvestorSearchContext.Provider value={[state, dispatch]}>
      {props.children}
    </InvestorSearchContext.Provider>
  );
};
